import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import style from './UserProfile.module.css'

function UserProfile() {
    const [user, setUser] = useState({
        name: '',
        email: '',
        phone: '',
        image: '/dummy.jpg', // Placeholder image
    });
    const [quotas, setQuotas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();
    const commonUrl = process.env.REACT_APP_COMMON_URL;

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = localStorage.getItem('access_token');
                const response = await axios.get(`${commonUrl}/api/getUserDetail`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const data = response.data;

                if (data.length > 0) {
                    // Extract user info from the first item
                    const firstItem = data[0];
                    setUser({
                        name: firstItem.user_name,
                        email: firstItem.email,
                        phone: firstItem.phone_number,
                        image: firstItem.image_url || '/dummy.jpg',
                    });

                    const nameMapping = {
                        create_drafts: 'Drafts',
                        chatbot_queries: 'Chatbot Queries',
                        case_summaries: 'Case Summaries',
                    };

                    const quotasData = data.map((item) => ({
                        name: nameMapping[item.quota_type_name] || item.quota_type_name,
                        total: item.quota_total_limit,
                        used: item.quota_used,
                    }));

                    // Remove duplicate quotas if necessary
                    const uniqueQuotas = quotasData.filter(
                        (quota, index, self) =>
                            index === self.findIndex((q) => q.name === quota.name && q.total === quota.total)
                    );

                    setQuotas(uniqueQuotas);
                }
            } catch (error) {
                console.error('Error fetching User Detail:', error);
                if (error.response && error.response.status === 401) {
                    navigate('/', { state: { message: 'Please log in to continue.' } });
                }
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [commonUrl, navigate]);

    // Handle file selection
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    // Handle file upload
    const handleUpload = async () => {
        if (!selectedFile) {
            alert('Please select a file first.');
            return;
        }

        const token = localStorage.getItem('access_token');
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await axios.post(`${commonUrl}/api/uploadProfileImage`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });

            // Update the user's image
            setUser((prevUser) => ({
                ...prevUser,
                image: response.data.image_url,
            }));
            alert(response.data.message);
        } catch (error) {
            console.error('Error uploading profile image:', error);
            alert('Failed to upload image. Please try again.');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    // Check if the image is 'dummy.jpg'
    const imageSrc =
        user.image === '/dummy.jpg'
          ? process.env.PUBLIC_URL + '/dummy.jpg' // Load from public folder
          : `${user.image.startsWith('/profile_images/') ? commonUrl : ''}${user.image}`; // Load from server
	console.log(imageSrc)

    return (
        <div className={`${style.container} mt-5`}>
            {/* Profile Section */}
            <div className={`${style.profile_card}`}>
                <div className="row">
                    {/* Profile Image */}
                    <div className="col-md-4 text-center">
                        <div className={`${style.image_container}`}>
                            <img src={imageSrc} alt="User" />
                        </div>
                    </div>
                    {/* Change Profile Image */}
                    <div className="col-md-8">
                        <h5>Change Profile Image</h5>
                        <input type="file" className="form-control mb-3" onChange={handleFileChange} accept="image/*" />
                        <button className={`btn btn-sm ${style.btn_primary}`} onClick={handleUpload}>
                            Upload Image
                        </button>
                    </div>
                </div>
            </div>
    
            {/* User Details Section */}
            <div className={`${style.profile_card}`}>
                <h5>User Details</h5>
                <div className="row">
                    <div className="col-md-4">
                        <label>Name</label>
                        <input type="text" value={user.name} readOnly />
                    </div>
                    <div className="col-md-4">
                        <label>Email</label>
                        <input type="email" value={user.email} readOnly />
                    </div>
                    <div className="col-md-4">
                        <label>Phone Number</label>
                        <input type="text" value={user.phone} readOnly />
                    </div>
                </div>
            </div>
    
            {/* Quota Details Section */}
            <div className={`${style.profile_card}`}>
                <h5>Quota Details</h5>
                <table className={`${style.table}`}>
                    <thead>
                        <tr>
                            <th>Quota Name</th>
                            <th>Total</th>
                            <th>Used</th>
                        </tr>
                    </thead>
                    <tbody>
                        {quotas.map((quota, index) => (
                            <tr key={index}>
                                <td>{quota.name}</td>
                                <td>{quota.total}</td>
                                <td>{quota.used}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
    
}

export default UserProfile;
