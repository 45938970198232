import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const FeedbackForm = () => {
    // State variables for form fields
    const [feedbackType, setFeedbackType] = useState('issues');
    const [feedbackText, setFeedbackText] = useState('');
    const [captchaValue, setCaptchaValue] = useState(null);
    const [submitMessage, setSubmitMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const commonUrl = process.env.REACT_APP_COMMON_URL;

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Basic validation
        if (!feedbackText.trim()) {
            setErrorMessage('Please enter your feedback.');
            return;
        }

        if (!captchaValue) {
            setErrorMessage('Please complete the CAPTCHA.');
            return;
        }

        try {
            setErrorMessage('');
            setSubmitMessage('Submitting your feedback...');

            // Retrieve JWT token from localStorage or context
            const token = localStorage.getItem('access_token');

            // Prepare data to send
            const feedbackData = {
                type: feedbackType,
                text: feedbackText,
                captcha: captchaValue,
            };

            // Send POST request to the backend
            const response = await axios.post(`${commonUrl}/api/submit-feedback/`, feedbackData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`, // Include JWT token for authentication
                },
            });

            if (response.status === 200) {
                setSubmitMessage('Thank you for your feedback!');
                setFeedbackText('');
                setCaptchaValue(null);
            }
        } catch (error) {
            console.error(error);
            setSubmitMessage('');
            if (error.response && error.response.data && error.response.data.detail) {
                setErrorMessage(error.response.data.detail);
            } else {
                setErrorMessage('An error occurred while submitting your feedback.');
            }
        }
    };

    // Handle CAPTCHA changes
    const handleRecaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    return (
        <main className="d-flex w-100">
            <div className="container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-md-10 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">
                            <div className="text-center mt-4">
                                <h1 className="h2">Your Voice Matters</h1>
                                <p className="lead">
                                    We’re committed to growth and excellence. Share your thoughts to help us improve and serve you better.
                                </p>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="m-sm-4">
                                        {/* Display success message */}
                                        {submitMessage && (
                                            <div className="alert alert-success">
                                                {submitMessage}
                                            </div>
                                        )}

                                        {/* Display error message */}
                                        {errorMessage && (
                                            <div className="alert alert-danger">
                                                {errorMessage}
                                            </div>
                                        )}

                                        <form onSubmit={handleSubmit}>
                                            {/* Feedback Type Radio Buttons */}
                                            <div className="mb-3">
                                                <label className="form-label">Type of Feedback</label>
                                                <div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="feedbackType"
                                                            id="issues"
                                                            value="issues"
                                                            checked={feedbackType === 'issues'}
                                                            onChange={(e) => setFeedbackType(e.target.value)}
                                                        />
                                                        <label className="form-check-label" htmlFor="issues">
                                                            Issues
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="feedbackType"
                                                            id="suggestions"
                                                            value="suggestions"
                                                            checked={feedbackType === 'suggestions'}
                                                            onChange={(e) => setFeedbackType(e.target.value)}
                                                        />
                                                        <label className="form-check-label" htmlFor="suggestions">
                                                            Suggestions
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Feedback Text Area */}
                                            <div className="mb-3">
                                                <label className="form-label">Your Feedback</label>
                                                <textarea
                                                    className="form-control"
                                                    rows="6"
                                                    value={feedbackText}
                                                    onChange={(e) => setFeedbackText(e.target.value)}
                                                    placeholder="Enter your feedback here..."
                                                ></textarea>
                                            </div>

                                            {/* Google reCAPTCHA */}
                                            <div className="mb-3">
                                                <ReCAPTCHA
                                                    sitekey="6Ld7oHQqAAAAAHJUXxjieXjEl1QCnEYkmSHbAFW6"
                                                    onChange={handleRecaptchaChange}
                                                />
                                            </div>

                                            {/* Submit Button */}
                                            <div className="mt-3">
                                                <button
                                                    type="submit"
                                                    className="btn"
                                                    style={{
                                                        backgroundColor: '#166432',
                                                        color: '#C59D55',
                                                        border: 'none',
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        e.target.style.backgroundColor = '#092415';
                                                        e.target.style.color = '#fff';
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.target.style.backgroundColor = '#166432';
                                                        e.target.style.color = '#C59D55';
                                                    }}
                                                >
                                                    Submit Feedback
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            {/* Optional: Link back to main page or other actions */}
                            {/* <div className="text-center mt-3">
                                <a
                                    href="/"
                                    style={{ color: '#166432', textDecoration: 'underline' }}
                                    onMouseEnter={(e) => (e.target.style.color = '#092415')}
                                    onMouseLeave={(e) => (e.target.style.color = '#166432')}
                                >
                                    Back to Home
                                </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default FeedbackForm;
