import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import FormPage from './components/form/FormPage';
import PreviewPage from './components/form/PreviewPage';
import LegalDrafts from './pages/LegalDrafts';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import DashboardLayout from './pages/DashboardLayout';
import PrivateRoute from './components/auth/PrivateRoute';
import ChatArea from './components/Chatbot/ChatArea';
import LandingPage from './components/userProfile/LandingPage';
import FeedbackForm from './components/form/FeedbackForm';
import UserProfile from './components/userProfile/UserProfile';
import LegalDraftListing from './pages/LegalDraftListing';

function App() {
  const [draftMasterId, setDraftMasterId] = useState(null);
  const [bailApplicationData, setBailApplicationData] = useState({
    courtName: '',
    city: '',
    petitionType: '',
    petitioner: '',
    respondents: '',
    firNumber: '',
    firDate: '',
    offenseSection: '',
    policeStation: '',
    firDescription: '',
    petitionerArguments: '',
    advocateName: '',
    licenseNumber: ''
  });
  const [partnershipDeedData, setPartnershipDeedData] = useState({
    partners: [
      { fullName: "", idNumber: "", address: "", role: "" },
      { fullName: "", idNumber: "", address: "", role: "" }
    ],
    businessInfo: {
      businessName: "",
      businessNature: "",
      businessAddress: "",
    },
    financials: {
      capitalContributions: {},
      profitSharingRatios: {},
    },
    bankingInfo: {
      jointSignatory: false,
      bankInstructions: "",
      fiscalYearStart: "",
      fiscalYearEnd: "",
    },
    responsibilities: {},
    legalProvisions: {
      disputeResolutionMethod: "",
      jurisdiction: "",
      transferSharesToHeirs: false,
      shareTransferRestrictions: "",
    }
  });
  const [settlementAgreementData, setSettlementAgreementData] = useState({
    party1: {
      fullName: "",
      cnic: "",
      address: "",
      contact: "",
    },
    party2: {
      fullName: "",
      cnic: "",
      address: "",
      contact: "",
    },
    agreement: {
      effectiveDate: "",
      purpose: "",
      jurisdiction: "",
    },
    financial: {
      totalAmountDue: "",
      currency: "",
      paymentSchedule: "", // Can hold string for lump sum or breakdown for installments
      bankingArrangements: "", // Added field for banking arrangements
      penaltiesForLatePayments: "", // Added penalties clause for late payments
    },
    ownershipTransfer: {
      itemsToTransfer: "",  // Items being transferred (e.g., machinery, vehicles)
      transferDate: "",     // Date when the ownership transfers
      transferConditions: "", // Conditions for transfer (e.g., after full payment)
    },
    disputeResolution: {
      method: "", // Dispute resolution method
      arbitrationClause: "", // Arbitration clause for disputes
      breachOfAgreement: "", // What constitutes breach of the agreement
      penaltiesForBreach: "", // Penalties for breach of agreement
    },
    confidentiality: {
      confidentialityClause: "", // Settlement confidentiality clause
      forceMajeure: "", // Protection against unforeseen circumstances
      governingLaw: "",  // Governing law for the settlement
    },
    signatures: {
      witness1: {
        name: "",
        address: ""
      },
      witness2: {
        name: "",
        address: ""
      }
    }
  });
  const [recoverySuitData, setRecoverySuitData] = useState({
    basicInfo: {
      courtName: "",
      // caseTitle: "", // Auto-generated
      // suitNumber: "", // Assigned by court
      filingDate: ""
    },
    plaintiffInfo: {
      name: "",
      position: "",
      address: "",
      legalRepresentative: "",
      companyNominationAttorney: ""
    },
    defendantInfo: {
      name: "",
      businessInformation: "",
      address: "",
      legalRepresentative: ""
    },
    debtDetails: {
      natureOfLoan: "",
      assetOfferedAsCollateral: "",
      outstandingAmount: "",
      defaultSince: "",
      // invoices: "",
      // chequesIssued: ""
    },
    causeOfAction: {
      contractualObligation: "",
      reasonForDefault: "",
      paymentFollowUp: "",
      // interestClause: ""
    },
    supportingDocuments: {
      contracts: "",
      invoices: "",
      chequeDetails: "",
      collateralAssetDetail: "",
      anyOtherInfo: ""
    },
    legalNotices: {
      noticeDate: "",
      defendantResponse: "",
      // rejoinderToResponse: ""
    },
    reliefSought: {
      totalPrincipalAmount: "",
      interestCalculation: "",
      panelCharges: "",
      legalCosts: "",
      penalties: ""
    },
    jurisdiction: {
      pecuniaryJurisdiction: "",
      territorialJurisdiction: ""
    },
    lawyerDetails: {
      advocateName: "",
      licenseNumber: "",
      lawFirmName: "",
      contactInformation: ""
    },
    prayer: {
      prayerForDecree: "",
      additionalRelief: ""
    }
  });
  const [nonDisclosureAgreementData, setNonDisclosureAgreementData] = useState({
    disclosingParty: {
      fullName: "",
      address: "",
      contact: "",
    },
    receivingParty: {
      fullName: "",
      address: "",
      contact: "",
    },
    NDABasicDetail: {
      title: "", // Title of the NDA
      date: "", // Date of the NDA  
    },
    NDADetail: {
      purpose: "", // Purpose of the NDA
      confidentialInfo: "", // Definition of confidential information
      returnordestructionInfo: "",
      breach: "",
      obligations: "", // Obligations of the receiving party
      duration: "", // Duration of confidentiality
      governingLaw: "", // Governing law for the NDA
    }
  });
  const [salesAgreementData, setSalesAgreementData] = useState({
    seller: {
      fullName: "",
      cnic: "",
      address: "",
      contact: "",
    },
    buyer: {
      fullName: "",
      cnic: "",
      address: "",
      contact: "",
    },
    saleSubject: {
      description: "",
      condition: "",
      ownershipConfirmation: false,
    },
    paymentTerms: {
      totalPrice: "",
      paymentMethod: "",
      paymentSchedule: "",
      deposit: "",
      depositDate: "",
    },
    transfer: {
      ownershipTransferDate: "",
      deliveryDate: "",
      deliveryMethod: "",
    },
    warranties: {
      sellerWarranties: "",
      buyerRepresentations: "",
    },
    disputeResolution: {
      governingLaw: "Pakistan",
      disputeMethod: "",
    },
    defaultPenalties: {
      consequences: "",
      latePayment: "",
    },
    miscellaneous: {
      indemnity: false,
      forceMajeure: false,
      confidentiality: false,
    },
    signatures: {
      sellerName: "",
      sellerDate: "",
      buyerName: "",
      buyerDate: "",
      witness1Name: "",
      witness1Address: "",
      witness2Name: "",
      witness2Address: "",
    },
  });
  const [serviceAgreementData, setServiceAgreementData] = useState({
    serviceType: '',
    serviceProvider: {
      fullName: '',
      cnic: '',
      address: '',
      contact: '',
    },
    client: {
      fullName: '',
      cnic: '',
      address: '',
      contact: '',
    },
    scopeOfServices: {
      description: '',
      serviceLocation: '',
      performanceStandards: '',
    },
    duration: {
      startDate: '',
      endDate: '',
      milestones: '',
    },
    paymentTerms: {
      serviceFee: '',
      paymentSchedule: '',
      paymentMethod: '',
      latePaymentPenalties: '',
    },
    termination: {
      conditions: '',
      noticePeriod: '',
      refundPayment: '',
    },
    disputeResolution: {
      governingLaw: 'Pakistan',
      disputeMethod: '',
      jurisdiction: '',
    },
    liability: {
      limitation: '',
      indemnification: '',
    },
    forceMajeure: {
      events: '',
    },
    signatures: {
      serviceProviderName: '',
      serviceProviderDate: '',
      clientName: '',
      clientDate: '',
      witness1Name: '',
      witness1Address: '',
      witness2Name: '',
      witness2Address: '',
    },
    // Add service-type-specific sections here, initialized as empty objects
    consulting: {
      deliverables: '',
      performanceReviewMilestones: '',
      ipOwnership: '',
    },
    contracting: {
      materialsResponsibility: '',
      siteInformation: '',
      insuranceLiability: '',
      compliance: '',
    },
    freelancing: {
      projectPayments: '',
      ownershipOfWork: '',
      revisions: '',
    },
    generalServices: {
      serviceLocation: '',
      suppliesMaterials: '',
    },
    itSoftware: {
      sla: '',
      maintenanceUpdates: '',
      intellectualProperty: '',
    },
    marketing: {
      campaignDetails: '',
      performanceMetrics: '',
      contentOwnership: '',
    },
  });
  const [employmentContractData, setEmploymentContractData] = useState({
    employer: {
      companyName: '',
      registeredAddress: '',
      registrationNumber: '',
      email: '',
      phone: '',
    },
    employee: {
      fullName: '',
      cnic: '',
      address: '',
      email: '',
      phone: '',
    },
    jobDetails: {
      jobTitle: '',
      jobDescription: '',
      department: '',
      placeOfWork: '',
      reportingManager: '',
    },
    employmentTerms: {
      startDate: '',
      employmentType: '',
      probationPeriod: '',
      confirmationConditions: '',
    },
    workingHours: {
      workingDays: '',
      dailyHours: '',
      weeklyHours: '',
      overtimeEligibility: '',
      overtimeRate: '',
      maxOvertimeHours: '',
    },
    remuneration: {
      basicSalary: '',
      currency: 'PKR',
      paymentFrequency: '',
      paymentMethod: '',
      allowances: '',
      bonuses: '',
    },
    leaveEntitlements: {
      annualLeaveDays: '',
      sickLeaveDays: '',
      casualLeaveDays: '',
      additionalLeaves: '',
    },
    benefits: {
      healthInsurance: '',
      retirementBenefits: '',
      otherBenefits: '',
    },
    confidentiality: {
      clause: '',
      details: '',
    },
    nonCompete: {
      clause: '',
      duration: '',
      geographicalScope: '',
    },
    nonSolicitation: {
      clause: '',
      details: '',
    },
    termination: {
      noticePeriodEmployer: '',
      noticePeriodEmployee: '',
      grounds: '',
      severancePay: '',
      retirementAge: '',
    },
    disputeResolution: {
      governingLaw: 'Pakistan',
      jurisdiction: '',
      disputeMethod: '',
    },
    otherTerms: {
      codeOfConduct: '',
      employeeHandbook: '',
      amendments: '',
      entireAgreement: '',
      severability: '',
    },
    signatures: {
      employerName: '',
      employerDesignation: '',
      employerSignature: '',
      employerDate: '',
      employeeName: '',
      employeeSignature: '',
      employeeDate: '',
      witness1Name: '',
      witness1Cnic: '',
      witness1Signature: '',
      witness1Date: '',
      witness2Name: '',
      witness2Cnic: '',
      witness2Signature: '',
      witness2Date: '',
    },
  });
  const [legalAppealData, setLegalAppealData] = useState({
    caseInformation: {
      caseTitle: '',
      caseNumber: '',
      trialCourt: '',
      trialCourtJudgmentDate: '',
      appellateCourt: '',
      jurisdiction: '',
    },
    groundsForAppeal: {
      typeOfAppeal: '',
      legalGrounds: [],
      otherLegalGrounds: '',
    },
    appealIssues: {
      issuesToBeAppealed: '',
      trialRecordReferences: '',
    },
    desiredOutcome: {
      reliefSought: [],
      otherReliefSought: '',
    },
    applicableLaw: {
      relevantStatutes: '',
      relevantCaseLaw: '',
    },
    supportingDocuments: {
      trialCourtJudgment: null,
      noticeOfAppeal: null,
      trialRecord: null,
      newEvidence: null,
      additionalDocuments: null,
    },
    proceduralInformation: {
      dateOfFilingNotice: '',
      deadlineForFilingAppeal: '',
      previousExtensions: '',
      extensionDetails: '',
    },
    representation: {
      lawyerName: '',
      lawFirmName: '',
      email: '',
      phone: '',
    },
    appellant: {
      fullName: '',
      cnic: '',
      address: '',
      email: '',
      phone: '',
      type: '',
    },
    respondent: {
      fullName: '',
      cnic: '',
      address: '',
      email: '',
      phone: '',
      type: '',
    },
    jurisdictionLaw: {
      governingLaw: [],
      jurisdiction: '',
      otherJurisdiction: '',
    },
    signatures: {
      lawyerSignature: false,
      appellantSignature: false,
    },
    additionalNotes: {
      notes: '',
    },
    confirmation: {
      confirmation: false,
    },
  });
  const [caseSummarizationData, setCaseSummarizationData] = useState({
    jugmenttextContent: '',
    jugmentpdfContent: ''
  });
  const [legalNoticeData, setLegalNoticeData] = useState({
    debtorInfo: {
      companyName: "",
      chiefExecutiveName: "",
      address: "",
    },
    creditorInfo: {
      bankName: "", // Pre-filled if common
      headOfficeAddress: "",
    },
    outstandingDetails: {
      // facilities: [], // Array of selected facilities
      totalPrincipal: "",
      totalMarkup: "",
      aggregatedAmount: "",
      penalCharges: "",
      amountPaid: ""
    },
    datesAndDeadlines: {
      noticeDate: "", // Auto-filled with the current date
      overDueDate: "",
      paymentDeadlineDate: "", // User-selected date
    },
  });
  const [stayOrderData, setStayOrderData] = useState({
    courtInfo: {
      courtName: "",
      suitNumber: "",
    },
    suitDetails: {
      typeOfSuit: "",
      cpcOrderAndRule: "", // Pre-filled with "Order 39 rules 1 & 2 of the CPC"
      additionalSuitInfo: "",
    },
    applicantInfo: {
      fullName: "",
      address: "",
    },
    respondentInfo: {
      fullName: "",
      address: "",
    },
    advocateInfo: {
      name: "",
      title: "",
      address: "",
    },
    affidavitDetails: {
      deponentName: "",
      address: "",
      affidavitDate: "",
    },
    verificationDetails: {
      verificationDate: "",
      verificationLocation: "",
    },
    additionalInfo: {
      propertyDetails: "",
      reasonsForApplication: "",
      prayerRequests: "",
    },
  });
  const [replyLegalNoticeData, setReplyLegalNoticeData] = useState({
    recipient: {
      name: "",
      contactPersonName: "",
      address: "",
    },
    sender: {
      name: "",
      contactPersonName: "",
      address: "",
    },
    legalNoticeReference: {
      referenceNumber: "",
      dateOfNotice: "",
      summary: "",
      uploadedDocument: null,
    },
    replyDetails: {
      agreeWithClaim: "No", // Default to "No"
      admittedClaims: "",
      deniedClaims: "",
      justifications: "",
      agreedAmount: "",
      disputedAmount: "",
      reasonForDispute: "",
    },
    counterClaims: "",
    legalGrounds: "",
    supportingDocuments: "",
  });

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/" element={<SignIn />} />
          <Route path="/dashboard/*" element={<PrivateRoute> <DashboardLayout /> </PrivateRoute>}>
            <Route index element={<LandingPage />} />
            <Route path="userprofile" element={<UserProfile />} />
            <Route path="LegalDrafts" element={<PrivateRoute> <LegalDrafts draftMasterId={draftMasterId} setDraftMasterId={setDraftMasterId} setRecoverySuitData={setRecoverySuitData} />  </PrivateRoute>} />
            <Route path="DraftListing" element={<PrivateRoute> <LegalDraftListing draftMasterId={draftMasterId} setDraftMasterId={setDraftMasterId} setRecoverySuitData={setRecoverySuitData} />  </PrivateRoute>} />
            <Route path="form" element={
              <PrivateRoute>
                <FormPage
                  bailApplicationData={bailApplicationData}
                  setBailApplicationData={setBailApplicationData}
                  partnershipDeedData={partnershipDeedData}
                  setPartnershipDeedData={setPartnershipDeedData}
                  settlementAgreementData={settlementAgreementData}
                  setSettlementAgreementData={setSettlementAgreementData}
                  recoverySuitData={recoverySuitData}
                  setRecoverySuitData={setRecoverySuitData}
                  nonDisclosureAgreementData={nonDisclosureAgreementData}
                  setNonDisclosureAgreementData={setNonDisclosureAgreementData}
                  salesAgreementData={salesAgreementData}
                  setSalesAgreementData={setSalesAgreementData}
                  serviceAgreementData={serviceAgreementData}
                  setServiceAgreementData={setServiceAgreementData}
                  employmentContractData={employmentContractData}
                  setEmploymentContractData={setEmploymentContractData}
                  legalAppealData={legalAppealData}
                  setLegalAppealData={setLegalAppealData}
                  caseSummarizationData={caseSummarizationData}
                  setCaseSummarizationData={setCaseSummarizationData}
                  setLegalNoticeData={setLegalNoticeData}
                  legalNoticeData={legalNoticeData}
                  setReplyLegalNoticeData={setReplyLegalNoticeData}
                  replyLegalNoticeData={replyLegalNoticeData}
                  setStayOrderData={setStayOrderData}
                  stayOrderData={stayOrderData}
                />
              </PrivateRoute>} />
            <Route path="preview" element={
              <PrivateRoute>
                <PreviewPage
                  bailApplicationData={bailApplicationData}
                  setBailApplicationData={setBailApplicationData}
                  partnershipDeedData={partnershipDeedData}
                  setPartnershipDeedData={setPartnershipDeedData}
                  settlementAgreementData={settlementAgreementData}
                  setSettlementAgreementData={setSettlementAgreementData}
                  recoverySuitData={recoverySuitData}
                  setRecoverySuitData={setRecoverySuitData}
                  nonDisclosureAgreementData={nonDisclosureAgreementData}
                  setNonDisclosureAgreementData={setNonDisclosureAgreementData}
                  salesAgreementData={salesAgreementData}
                  setSalesAgreementData={setSalesAgreementData}
                  serviceAgreementData={serviceAgreementData}
                  setServiceAgreementData={setServiceAgreementData}
                  employmentContractData={employmentContractData}
                  setEmploymentContractData={setEmploymentContractData}
                  legalAppealData={legalAppealData}
                  setLegalAppealData={setLegalAppealData}
                  caseSummarizationData={caseSummarizationData}
                  setCaseSummarizationData={setCaseSummarizationData}
                  setLegalNoticeData={setLegalNoticeData}
                  legalNoticeData={legalNoticeData}
                  setReplyLegalNoticeData={setReplyLegalNoticeData}
                  replyLegalNoticeData={replyLegalNoticeData}
                  setStayOrderData={setStayOrderData}
                  stayOrderData={stayOrderData}
                  draftMasterId={draftMasterId}
                />
              </PrivateRoute>} />
            <Route path="chatbot" element={<PrivateRoute> <ChatArea /> </PrivateRoute>} />
            <Route path="feedback" element={<PrivateRoute> <FeedbackForm /> </PrivateRoute>} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
