import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { NavLink, useNavigate } from 'react-router-dom';
import style from './Sidebar.module.css'
import { HistoryContext } from '../Context/HistoryContext';  // Import the context
import { ChatContext } from '../Context/ChatContext';  // Context to store chat messages


const Sidebar = ({ isOpen }) => {
    const { historyItems, setHistoryItems, selectedHistoryItemId, setSelectedHistoryItemId } = useContext(HistoryContext);  // Access context
    const { setMessages } = useContext(ChatContext);  // To store chat messages in the context for the chat area
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const navigate = useNavigate();
    const commonUrl = process.env.REACT_APP_COMMON_URL;
    const defaultHistoryCreatedRef = useRef(false);

    // Function to fetch history items from the backend
    const fetchHistoryItems = async (shouldSelectFirst = false) => {
        try {
            const token = localStorage.getItem('access_token');
            let response = await axios.get(`${commonUrl}/api/history_items`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            let items = response.data || [];

            // Check if the user has no history items and create a default one
            if (items.length === 0 && !defaultHistoryCreatedRef.current) {
                defaultHistoryCreatedRef.current = true; // Set the ref to true to prevent duplicate calls
                await createDefaultHistoryItem();

                // After creating default item, fetch the items again
                response = await axios.get(`${commonUrl}/api/history_items`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                items = response.data || [];
            }
            setHistoryItems(items);

            // Automatically select the first history item if available
            if (shouldSelectFirst && items.length > 0 && isInitialLoad) {
                const firstHistoryItemId = items[0].history_item_id;
                setSelectedHistoryItemId(firstHistoryItemId);
                loadHistory(firstHistoryItemId); // Load the chat history for the first item
                setIsInitialLoad(false); // Mark initial load as complete
            }
        } catch (error) {
            console.error('Error fetching history items:', error);
        }
    };

    // Function to create a default history item for new users
    const createDefaultHistoryItem = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.post(
                `${commonUrl}/api/history_items`,
                { name: 'Default History' },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            const history_item_id = response.data.history_item_id;
            setSelectedHistoryItemId(history_item_id);
            loadHistory(history_item_id); // Load the newly created default history
            // Do not call fetchHistoryItems here
        } catch (error) {
            console.error('Error creating default history item:', error);
        }
    };

    // Function to load the chat history for the selected history item
    const loadHistory = async (history_item_id) => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${commonUrl}/api/load_history/${history_item_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`, // Pass JWT token for authentication
                },
            });
            // console.log(`History loaded for history item ID: ${history_item_id}`);
            // Set the fetched messages in chat context (to display in chat area)
            // console.log(response.data)
            setMessages(response.data.chat_history || []);
        } catch (error) {
            console.error('Error loading history:', error);
        }
    };

    // Function to create a new history item
    const createNewHistoryItem = async () => {
        const name = prompt('Enter a name for the new history item:');
        if (!name) return;

        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.post(
                `${commonUrl}/api/history_items`,
                { name },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            // console.log(response.data.history_item_id)
            const history_item_id = response.data.history_item_id
            setSelectedHistoryItemId(history_item_id);
            loadHistory(history_item_id);  // Load the chat history for the first item
            // Refetch the history items after adding a new one
            fetchHistoryItems(false);
        } catch (error) {
            console.error('Error creating history item:', error);
        }
    };

    // Function to handle history item selection
    const handleHistoryItemClick = (history_item_id) => {
        setSelectedHistoryItemId(history_item_id);  // Update the selected item ID in context
        loadHistory(history_item_id);  // Load the selected history item from the backend

        // Navigate to /dashboard/chatbot
        navigate('/dashboard/chatbot');
    };

    // const handleLogout = async () => {
    //     try {
    //         const token = localStorage.getItem('access_token');
    //         await axios.post(
    //             'http://localhost:8000/logout',
    //             {},
    //             {
    //                 headers: { Authorization: `Bearer ${token}` },
    //             }
    //         );

    //         // Clear local storage and navigate to login page
    //         localStorage.removeItem('IsAuthenticated');
    //         localStorage.removeItem('access_token');
    //         navigate('/', { state: { message: 'You have been logged out successfully.' } });
    //     } catch (error) {
    //         console.error('Error during logout:', error);
    //     }
    // };

    // Fetch history items when the component mounts
    useEffect(() => {
        fetchHistoryItems(true);
    }, []);


    return (
        <nav id={`sidebar`} className={`navbar-collapse ${isOpen ? 'show' : 'collapse'} ${style.sidebar}`}>
            <div className={`${style.sidebar_content}`}>
                <ul className={style.sidebar_nav}>
                    <li className={style.sidebar_header}>
                        <NavLink to="/dashboard/form?draft_type_id=10" className={style.a_tags}>
                            Case Summarization
                        </NavLink>
                    </li>
                    <li className={style.sidebar_header}>
                        <NavLink to="/dashboard/LegalDrafts" className={style.a_tags}>
                            Legal Drafts
                        </NavLink>
                    </li>
                    <li className={style.sidebar_header}>
                        <NavLink to="/dashboard/chatbot" className={style.a_tags}>
                            Legal Research Agent
                        </NavLink>
                    </li>
                    {/* Add horizontal line after Legal Research Agent */}
                    <li className={style.sidebar_separator}></li>
                </ul>

                {/* Chatbot Sidebar Content from Project 2 */}
                <div className={style.chatbot_sidebar}>
                    <button onClick={createNewHistoryItem} className={style.new_chat_button}>
                        New Chat Topic
                    </button>
                    <label className={style.label}>Chat Topics</label>
                    <ul className={style.navbar_nav}>
                        {historyItems.length > 0 ? (
                            historyItems.map((item) => (
                                item && item.name && (
                                    <li
                                        key={item.history_item_id || Math.random()}
                                        className={`${style.nav_item} ${selectedHistoryItemId === item.history_item_id ? style.selected_item : ''}`}
                                    >
                                        <a
                                            href="#"
                                            className={style.nav_link}
                                            onClick={() => handleHistoryItemClick(item.history_item_id)}
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                )
                            ))
                        ) : (
                            <li className={style.nav_item}>
                                <span className="text-muted">No history items found</span>
                            </li>
                        )}
                    </ul>
                    {/* <label className={style.label}>Profile</label>
                    <ul className={style.navbar_nav}>
                        <li className={style.nav_item}>
                            <a href="#" className={style.nav_link}>My Account</a>
                        </li>
                        <li className={`${style.nav_item}`}>
                            <a href="#" className={style.nav_link}>Settings</a>
                        </li>
                        <li className={`${style.nav_item}`}>
                            <a
                                href="#"
                                className={style.nav_link}
                                onClick={handleLogout}
                            >
                                Logout
                            </a>
                        </li>
                    </ul> */}
                </div>
            </div>
        </nav>
    );
}

export default Sidebar;

