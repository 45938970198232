// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LandingPage_btn_color__gn-HK {
    background-color: #166432;
    color: #C59D55;
}
.LandingPage_btn_color__gn-HK:hover {
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/components/userProfile/LandingPage.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;AAClB;AACA;IACI,WAAW;AACf","sourcesContent":[".btn_color {\n    background-color: #166432;\n    color: #C59D55;\n}\n.btn_color:hover {\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn_color": `LandingPage_btn_color__gn-HK`
};
export default ___CSS_LOADER_EXPORT___;
