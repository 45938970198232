import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BailApplicationForm from './BailApplicationForm';
import PartnershipDeedForm from './PartnershipDeedForm';
import SettlementAgreementForm from './SettlementAgreementForm';
import RecoverySuitForm from './RecoverySuitForm';
import NonDisclosureAgreementForm from './NonDisclosureAgreementsForm';
import SalesAgreementForm from './SalesAgreementForm';
import ServiceAgreementForm from './ServiceAgreementForm';
import EmploymentContractForm from './EmploymentContractForm';
import LegalAppealForm from './LegalAppealForm';
import CaseSummarizationForm from './CaseSummarizationForm';
import LegalNoticeForm from './LegalNoticeForm'
import ReplyLegalNoticeForm from './ReplyLegalNoticeForm';
import StayOrderForm from './StayOrderForm';

function FormPage(
    {
        bailApplicationData,
        setBailApplicationData,
        partnershipDeedData,
        setPartnershipDeedData,
        settlementAgreementData,
        setSettlementAgreementData,
        recoverySuitData,
        setRecoverySuitData,
        setNonDisclosureAgreementData,
        nonDisclosureAgreementData,
        salesAgreementData,
        setSalesAgreementData,
        setServiceAgreementData,
        serviceAgreementData,
        employmentContractData,
        setEmploymentContractData,
        legalAppealData,
        setLegalAppealData,
        caseSummarizationData,
        setCaseSummarizationData,
        setLegalNoticeData,
        legalNoticeData,
        setReplyLegalNoticeData,
        replyLegalNoticeData,
        setStayOrderData,
        stayOrderData
    }) {

    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const draftTypeId = queryParams.get('draft_type_id');

    // Handle navigation to the preview page
    const handleNext = () => {
        navigate(`/dashboard/preview?draft_type_id=${draftTypeId}`, { state: { draftTypeId } });
    };

    // Function to render the correct form based on draft_type_id
    const renderForm = () => {
        switch (draftTypeId) {
            case '1':
                return (
                    <BailApplicationForm
                        formData={bailApplicationData}
                        setFormData={setBailApplicationData}
                        onSubmit={handleNext}
                    />
                );
            case '2':
                return (
                    <PartnershipDeedForm
                        formData={partnershipDeedData}
                        setFormData={setPartnershipDeedData}
                        onSubmit={handleNext}
                    />
                );
            case '3':
                return (
                    <SettlementAgreementForm
                        formData={settlementAgreementData}
                        setFormData={setSettlementAgreementData}
                        onSubmit={handleNext}
                    />
                );
            case '4':
                return (
                    <RecoverySuitForm
                        formData={recoverySuitData}
                        setFormData={setRecoverySuitData}
                        onSubmit={handleNext}
                    />
                );
            case '5':
                return (
                    <NonDisclosureAgreementForm
                        formData={nonDisclosureAgreementData}
                        setFormData={setNonDisclosureAgreementData}
                        onSubmit={handleNext}
                    />
                );
            case '6':
                return (
                    <SalesAgreementForm
                        formData={salesAgreementData}
                        setFormData={setSalesAgreementData}
                        onSubmit={handleNext}
                    />
                );
            case '7':
                return (
                    <ServiceAgreementForm
                        formData={serviceAgreementData}
                        setFormData={setServiceAgreementData}
                        onSubmit={handleNext}
                    />
                );
            case '8':
                return (
                    <EmploymentContractForm
                        formData={employmentContractData}
                        setFormData={setEmploymentContractData}
                        onSubmit={handleNext}
                    />
                );
            case '9':
                return (
                    <LegalAppealForm
                        formData={legalAppealData}
                        setFormData={setLegalAppealData}
                        onSubmit={handleNext}
                    />
                );
            case '10':
                return (
                    <CaseSummarizationForm
                        formData={caseSummarizationData}
                        setFormData={setCaseSummarizationData}
                        onSubmit={handleNext}
                    />
                );
            case '11':
                return (
                    <LegalNoticeForm
                        formData={legalNoticeData}
                        setFormData={setLegalNoticeData}
                        onSubmit={handleNext}
                    />
                );
            case '12':
                return (
                    <ReplyLegalNoticeForm
                        formData={replyLegalNoticeData}
                        setFormData={setReplyLegalNoticeData}
                        onSubmit={handleNext}
                    />
                );
            case '13':
                return (
                    <StayOrderForm
                        formData={stayOrderData}
                        setFormData={setStayOrderData}
                        onSubmit={handleNext}
                    />
                );
            default:
                return <div>Invalid draft type</div>;
        }
    };

    return (
        <div className="container">
            {renderForm()}
        </div>
    );
}

export default FormPage;
