import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

export default function SignUp() {
    const commonUrl = process.env.REACT_APP_COMMON_URL;
    const [firstName, setFirstName] = useState(''); // New state variable
    const [lastName, setLastName] = useState('');   // New state variable
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [agreedToTerms, setAgreedToTerms] = useState(false);

    // Handle reCAPTCHA token change
    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    // Handle form submit (signup logic)
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Simple client-side validation
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match.');
            return;
        }

        if (!recaptchaToken) {
            setErrorMessage('Please complete the reCAPTCHA verification.');
            return;
        }

        if (!agreedToTerms) {
            setErrorMessage('You must agree to the terms of use and privacy policy.');
            return;
        }

        try {
            const response = await Axios.post(`${commonUrl}/api/register`, {
                firstName,
                lastName,
                email,
                password,
                recaptchaToken
            });

            // Navigate to sign-in page with a success message
            navigate('/', { state: { message: 'Registration successful. Please sign in.' } });
        } catch (error) {
            // Handle error response
            setErrorMessage(error.response?.data?.detail || 'Registration failed. Please try again.');
        }
    };

    // Clear error message after 5 seconds
    useEffect(() => {
        let timer;
        if (errorMessage) {
            timer = setTimeout(() => {
                setErrorMessage('');
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [errorMessage]);

    return (
        <main className="d-flex w-100">
            <div className="container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">
                            <div className="text-center mt-4">
                                <h1 className="h2">Create Your Account</h1>
                                <p className="lead">
                                    Sign up to access Pak Law Assist
                                </p>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="m-sm-4">
                                        {/* Display error message */}
                                        {errorMessage && (
                                            <div className="alert alert-danger">
                                                {errorMessage}
                                            </div>
                                        )}

                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label className="form-label">First Name</label>
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="text"
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    placeholder="Enter your first name"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Last Name</label>
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="text"
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                    placeholder="Enter your last name"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Email</label>
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    placeholder="Enter your email"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Password</label>
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    placeholder="Enter your password"
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Confirm Password</label>
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="password"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    placeholder="Confirm your password"
                                                    required
                                                />
                                            </div>

                                            {/* Compulsory Checkbox */}
                                            <div className='mb-3'
                                                style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                    gap: "8px",
                                                    fontFamily: "Arial, sans-serif",
                                                    fontSize: "14px",
                                                    lineHeight: "1.6",
                                                }}
                                            >
                                                <input
                                                    className='mt-1'
                                                    type="checkbox"
                                                    id="agreeTerms"
                                                    checked={agreedToTerms}
                                                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                                                    style={{
                                                        margin: "0",
                                                        width: "16px",
                                                        height: "16px",
                                                        cursor: "pointer",
                                                    }}
                                                    required
                                                />
                                                <label
                                                    htmlFor="agreeTerms"
                                                    style={{
                                                        margin: "0",
                                                        lineHeight: "1.6",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    By registering, you acknowledge that content is AI-generated and agree to our
                                                    <a
                                                        href="https://paklawassist.com/terms-and-conditions/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                            color: "#166432",
                                                            textDecoration: "none",
                                                        }}
                                                    >
                                                        {" "}
                                                        Terms of Use
                                                    </a>{" "}
                                                    and
                                                    <a
                                                        href="https://paklawassist.com/privacy-policy/"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                            color: "#166432",
                                                            textDecoration: "none",
                                                        }}
                                                    >
                                                        {" "}
                                                        Privacy Policy
                                                    </a>
                                                    .
                                                </label>
                                            </div>



                                            {/* <div className="mb-3 form-check">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="agreeTerms"
                                                    checked={agreedToTerms}
                                                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                                                    required
                                                />
                                                <label className="form-check-label" htmlFor="agreeTerms">
                                                    By registering, you acknowledge that content is AI-generated and agree to our{' '}
                                                    <a href="https://paklawassist.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms of Use</a> and{' '}
                                                    <a href="https://paklawassist.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                                                </label>
                                            </div> */}

                                            {/* Google reCAPTCHA */}
                                            <div className="mb-3">
                                                <ReCAPTCHA
                                                    sitekey="6Ld7oHQqAAAAAHJUXxjieXjEl1QCnEYkmSHbAFW6"
                                                    onChange={handleRecaptchaChange}
                                                />
                                            </div>

                                            <div className="text-center mt-3">
                                                {/* <button type="submit" className="btn btn-lg btn-primary">
                                                    Sign Up
                                                </button> */}
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg"
                                                    style={{
                                                        backgroundColor: '#166432',
                                                        color: '#C59D55',
                                                        border: 'none'
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        e.target.style.backgroundColor = '#092415';
                                                        e.target.style.color = '#fff';
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.target.style.backgroundColor = '#166432';
                                                        e.target.style.color = '#C59D55';
                                                    }}
                                                >
                                                    Sign Up
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
