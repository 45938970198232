// import React, { useState, useEffect, useRef } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import ReactMarkdown from 'react-markdown';
// import styles from './LegalDrafts.module.css'
// import DraftCard from './DraftCard';
// import { saveAs } from "file-saver";
// import HtmlDocx from "html-docx-js/dist/html-docx";

// const LegalDrafts = ({ draftMasterId, setDraftMasterId, setRecoverySuitData }) => {
// const [drafts, setDrafts] = useState([]);
// const [loading, setLoading] = useState(true);
// const navigate = useNavigate();
// const [showModal, setShowModal] = useState(false);
// const [legalNoticeOrRecoverySuit, setLegalNoticeOrRecoverySuit] = useState('');
// const [draftType, setDraftType] = useState(null);
// const [modalTitle, setModalTitle] = useState('');
// const [activeTab, setActiveTab] = useState('civilSuits');
// const commonUrl = process.env.REACT_APP_COMMON_URL;
// const contentRef = useRef(null);
// console.log(draftMasterId)
// useEffect(() => {
//     const fetchDrafts = async () => {
//         try {
//             const token = localStorage.getItem('access_token');
//             const response = await axios.get(`${commonUrl}/api/drafts`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             console.log(response.data)
//             setDrafts(response.data);
//         } catch (error) {
//             console.error('Error fetching drafts:', error);
//             if (error.response && error.response.status === 401) {
//                 // Handle unauthorized access (e.g., redirect to login)
//                 navigate('/', { state: { message: 'Please log in to continue.' } });
//             }
//         } finally {
//             setLoading(false);
//         }
//     };

//     fetchDrafts();
// }, [navigate]);

// const handleDraftTitleClick = async (draft_master_id, draftTitle, draftTypeName) => {
//     try {
//         const token = localStorage.getItem('access_token');
//         const response = await axios.get(`${commonUrl}/api/getLegalNoticeOrRecoverySuitDetail`, {
//             params: { draft_master_id },
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//         // console.log(response.data)
//         setDraftType(draftTypeName);
//         setLegalNoticeOrRecoverySuit(response.data['generated_draft']);
//         setModalTitle(draftTitle);
//         setDraftMasterId(draft_master_id)
//         setShowModal(true);
//     } catch (error) {
//         console.error('Error fetching legal notice:', error);
//     }
// };

// const handleRecoverySuitClick = async (draft_master_id) => {
//     try {
//         const token = localStorage.getItem('access_token');
//         const response = await axios.get(`${commonUrl}/api/getLegalNoticeOrRecoverySuitDetail`, {
//             params: { draft_master_id },
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//         // console.log(response.data)
//         const legalNoticeData = response.data

//         const mappedRecoverySuitData = {
//             basicInfo: {
//                 courtName: "", // User will fill this
//                 filingDate: "", // User will fill this
//             },
//             plaintiffInfo: {
//                 name: legalNoticeData.creditor_bank_name || "",
//                 position: "", // If applicable
//                 address: legalNoticeData.creditor_office_address || "",
//                 legalRepresentative: "", // If applicable
//                 companyNominationAttorney: "", // If applicable
//             },
//             defendantInfo: {
//                 name: legalNoticeData.debtor_company_name || "",
//                 businessInformation: "", // If applicable
//                 address: legalNoticeData.debtor_address || "",
//                 legalRepresentative: "",
//             },
//             debtDetails: {
//                 natureOfLoan: "", // Map as appropriate
//                 assetOfferedAsCollateral: "", // Map as appropriate
//                 outstandingAmount: legalNoticeData.aggregated_amount || "",
//                 defaultSince: legalNoticeData.overdue_date || "",
//             },
//             causeOfAction: {
//                 contractualObligation: "", // User will fill this
//                 reasonForDefault: "", // User will fill this
//                 paymentFollowUp: "", // User will fill this
//             },
//             supportingDocuments: {
//                 contracts: "", // User will fill this
//                 invoices: "", // User will fill this
//                 chequeDetails: "", // User will fill this
//                 collateralAssetDetail: "", // User will fill this
//                 anyOtherInfo: "", // User will fill this
//             },
//             legalNotices: {
//                 noticeDate: legalNoticeData.notice_date || "",
//                 defendantResponse: "", // User will fill this
//             },
//             reliefSought: {
//                 totalPrincipalAmount: legalNoticeData.total_principal || "",
//                 interestCalculation: legalNoticeData.total_markup_due || "", // User will fill this
//                 panelCharges: legalNoticeData.penalCharges || "",
//                 legalCosts: "", // User will fill this
//                 penalties: "",
//             },
//             jurisdiction: {
//                 pecuniaryJurisdiction: "", // User will fill this
//                 territorialJurisdiction: "", // User will fill this
//             },
//             lawyerDetails: {
//                 advocateName: "", // User will fill this
//                 licenseNumber: "", // User will fill this
//                 lawFirmName: "", // User will fill this
//                 contactInformation: "", // User will fill this
//             },
//             prayer: {
//                 prayerForDecree: "", // User will fill this
//                 additionalRelief: "", // User will fill this
//             },
//         };

//         // Update the recoverySuitData state in App.js
//         setRecoverySuitData(mappedRecoverySuitData);

//         setDraftMasterId(draft_master_id);
//         navigate('/dashboard/form?draft_type_id=4');
//     } catch (error) {
//         console.error('Error fetching legal notice:', error);
//     }

// };

// // State to manage the collapse
// const [isOpen, setIsOpen] = useState(false);

// const preprocessHtmlContent = (html) => {
//     return html
//         .replace(/“/g, '&ldquo;')
//         .replace(/”/g, '&rdquo;')
//         .replace(/‘/g, '&lsquo;')
//         .replace(/’/g, '&rsquo;')
//         .replace(/-/g, '&ndash;')
//         .replace(/—/g, '&mdash;')
//         .replace(/…/g, '&hellip;');
// };

// const generateWord = () => {
//     try {
//         const contentElement = contentRef.current;

//         if (!contentElement) {
//             console.error("Content reference is null");
//             return;
//         }

//         // Get the HTML content
//         let contentHtml = contentElement.innerHTML;
//         contentHtml = preprocessHtmlContent(contentHtml);

//         // Convert the HTML to a Word document blob
//         const docxBlob = HtmlDocx.asBlob(contentHtml);

//         // Save the blob as a .docx file
//         saveAs(docxBlob, "content.docx");
//     } catch (error) {
//         console.error("Error generating document:", error);
//     }
// };

// // Function to toggle the collapse state
// const toggleCollapse = () => {
//     setIsOpen(!isOpen);
// };
// const handleCloseModal = () => {
//     setShowModal(false);
//     setLegalNoticeOrRecoverySuit('');
//     setModalTitle('');
// };
// // Separate drafts into two arrays based on draft_type_name
// const recoverySuits = drafts.filter(draft => draft.draft_type_name === 'Recovery Suit');
// const legalNotices = drafts.filter(draft => draft.draft_type_name === 'Legal Notice');

//     const contractsDrafts = [
//         { title: 'Partnership Deed', draftTypeId: 2, disabled: false },
//         { title: 'Settlement Agreement', draftTypeId: 3, disabled: false },
//         { title: 'Sale Agreement', draftTypeId: 6, disabled: false },
//         { title: 'Lease Agreement', draftTypeId: 10, disabled: true },
//         { title: 'NDAs', draftTypeId: 5, disabled: false },
//         { title: 'Employment Contracts', draftTypeId: 8, disabled: false },
//         { title: 'Service Agreement', draftTypeId: 7, disabled: false },
//         { title: 'Joint Venture Agreement', draftTypeId: 11, disabled: true },
//         { title: 'Franchise Agreement', draftTypeId: 12, disabled: true },
//     ];

//     const legalPetitionsDrafts = [
//         { title: 'Bail Application', draftTypeId: 1, disabled: false },
//         { title: 'Appeals', draftTypeId: 9, disabled: false },
//         { title: 'Writ Petition', draftTypeId: 13, disabled: true },
//         { title: 'Constitutional Petition', draftTypeId: 14, disabled: true },
//     ];

//     return (
//         <div className="container mt-4">
//             {/* Tabs Navigation */}
//             <ul className="nav nav-tabs">
//                 <li className="nav-item">
//                     <button
//                         className={`nav-link ${activeTab === 'civilSuits' ? 'active' : ''}`}
//                         onClick={() => setActiveTab('civilSuits')}
//                     >
//                         Civil Suits
//                     </button>
//                 </li>
//                 <li className="nav-item">
//                     <button
//                         className={`nav-link ${activeTab === 'contracts' ? 'active' : ''}`}
//                         onClick={() => setActiveTab('contracts')}
//                     >
//                         Contracts & Agreements
//                     </button>
//                 </li>
//                 <li className="nav-item">
//                     <button
//                         className={`nav-link ${activeTab === 'legalPetitions' ? 'active' : ''}`}
//                         onClick={() => setActiveTab('legalPetitions')}
//                     >
//                         Legal Petitions
//                     </button>
//                 </li>
//             </ul>

//             {/* Tab Content */}
//             <div className="tab-content mt-3">
//                 {/* Civil Suits Tab */}
// {activeTab === 'civilSuits' && (
//     <div className="tab-pane active mt-5">
//         {/* New Legal Notice Button */}
//         <div className="row mb-4">
//             <div className="col-md-4">
//                 <button
//                     className={`btn btn-primary ${styles.btn_color}`}
//                     onClick={() => navigate("/Dashboard/form?draft_type_id=11")}
//                 >
//                     New Legal Notice
//                 </button>
//             </div>
//         </div>

//         {/* Legal Notices Table */}
//         <h4 className="mt-2">Legal Notices</h4>
//         {loading ? (
//             <p>Loading drafts...</p>
//         ) : legalNotices.length > 0 ? (
//             <table className="table table-striped">
//                 <thead>
//                     <tr>
//                         <th>Draft Title</th>
//                         <th>Status</th>
//                         <th>Created At</th>
//                         <th>Action</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {legalNotices.map((draft) => (
//                         <tr key={draft.draft_master_id}>
//                             <td>
//                                 <a
//                                     href="#!"
//                                     onClick={(e) => {
//                                         e.preventDefault();
//                                         handleDraftTitleClick(draft.draft_master_id, draft.draft_title, draft.draft_type_name);
//                                     }}
//                                 >
//                                     {draft.draft_title}
//                                 </a>
//                             </td>
//                             <td>{draft.status}</td>
//                             <td>{new Date(draft.created_at).toLocaleString()}</td>
//                             <td>
//                                 <button
//                                     className={`btn btn-sm btn-warning ${styles.btn_color}`}
//                                     onClick={() => handleRecoverySuitClick(draft.draft_master_id)}
//                                 >
//                                     Generate Recovery Suit
//                                 </button>
//                             </td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         ) : (
//             <p>No Legal Notice drafts found.</p>
//         )}

//         {/* Recovery Suits Table */}
//         <h4 className="mt-4">Recovery Suits</h4>
//         {loading ? (
//             <p>Loading drafts...</p>
//         ) : recoverySuits.length > 0 ? (
//             <table className="table table-striped">
//                 <thead>
//                     <tr>
//                         <th>Draft Title</th>
//                         <th>Status</th>
//                         <th>Created At</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {recoverySuits.map((draft) => (
//                         <tr key={draft.draft_master_id}>
//                             <td>
//                                 <a
//                                     href="#!"
//                                     onClick={(e) => {
//                                         e.preventDefault();
//                                         handleDraftTitleClick(draft.draft_master_id, draft.draft_title, draft.draft_type_name);
//                                     }}
//                                 >
//                                     {draft.draft_title}
//                                 </a>
//                             </td>
//                             <td>{draft.status}</td>
//                             <td>{new Date(draft.created_at).toLocaleString()}</td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </table>
//         ) : (
//             <p>No Recovery Suit drafts found.</p>
//         )}

//         {/* Bootstrap Modal */}
//         {showModal && (
//             <div className="modal show fade d-block" tabIndex="-1" role="dialog">
//                 <div className="modal-dialog modal-lg" role="document">
//                     <div className="modal-content">
//                         <div className="modal-header d-flex justify-content-between align-items-center">
//                             <h5 className="modal-title">{modalTitle}</h5>
//                             <div>
//                                 {draftType !== 'Recovery Suit' && (
//                                     <button
//                                         className={`btn btn-sm btn-warning mr-2 ${styles.btn_color}`}
//                                         onClick={() => handleRecoverySuitClick(draftMasterId)}
//                                     >
//                                         Generate Recovery Suit
//                                     </button>
//                                 )}
//                                 <button
//                                     className="btn btn-sm btn-secondary mr-2"
//                                     onClick={generateWord}
//                                 >
//                                     Download as Word
//                                 </button>
//                                 <button type="button" className="close" onClick={handleCloseModal}>
//                                     <span>&times;</span>
//                                 </button>
//                             </div>
//                         </div>
//                         <div
//                             className="modal-body"
//                             style={{ height: '80vh', overflowY: 'auto' }}
//                             ref={contentRef}
//                         >
//                             <ReactMarkdown>{legalNoticeOrRecoverySuit}</ReactMarkdown>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )}

//         {/* Modal Backdrop */}
//         {showModal && <div className="modal-backdrop fade show"></div>}
//     </div>
// )}

//                 {/* Contracts & Agreements Tab */}
//                 {activeTab === 'contracts' && (
//                     <div className="tab-pane active mt-5">
//                         <div className="row">
//                             {contractsDrafts.map((draft) => (
//                                 <DraftCard
//                                     key={draft.draftTypeId}
//                                     title={draft.title}
//                                     draftTypeId={draft.draftTypeId}
//                                     disabled={draft.disabled}
//                                 />
//                             ))}
//                         </div>
//                     </div>
//                 )}

//                 {/* Legal Petitions Tab */}
//                 {activeTab === 'legalPetitions' && (
//                     <div className="tab-pane active mt-5">
//                         <div className="row">
//                             {legalPetitionsDrafts.map((draft) => (
//                                 <DraftCard
//                                     key={draft.draftTypeId}
//                                     title={draft.title}
//                                     draftTypeId={draft.draftTypeId}
//                                     disabled={draft.disabled}
//                                 />
//                             ))}
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// };

// export default LegalDrafts;


import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import DraftCard from './DraftCard';
import { saveAs } from "file-saver";
import HtmlDocx from "html-docx-js/dist/html-docx";
import styles from './LegalDrafts.module.css'

const LegalDrafts = ({ draftMasterId, setDraftMasterId, setRecoverySuitData }) => {
    const [drafts, setDrafts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [legalNoticeOrRecoverySuit, setLegalNoticeOrRecoverySuit] = useState('');
    const [draftType, setDraftType] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
    const [activeTab, setActiveTab] = useState('legalPetitions');
    const commonUrl = process.env.REACT_APP_COMMON_URL;
    const contentRef = useRef(null);

    // console.log(draftMasterId)
    // useEffect(() => {
    //     const fetchDrafts = async () => {
    //         try {
    //             const token = localStorage.getItem('access_token');
    //             const response = await axios.get(`${commonUrl}/api/drafts`, {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             });
    //             console.log(response.data)
    //             setDrafts(response.data);
    //         } catch (error) {
    //             console.error('Error fetching drafts:', error);
    //             if (error.response && error.response.status === 401) {
    //                 // Handle unauthorized access (e.g., redirect to login)
    //                 navigate('/', { state: { message: 'Please log in to continue.' } });
    //             }
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchDrafts();
    // }, [navigate]);

    // const handleDraftTitleClick = async (draft_master_id, draftTitle, draftTypeName) => {
    //     try {
    //         const token = localStorage.getItem('access_token');
    //         const response = await axios.get(`${commonUrl}/api/getLegalNoticeOrRecoverySuitDetail`, {
    //             params: { draft_master_id },
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });
    //         // console.log(response.data)
    //         setDraftType(draftTypeName);
    //         setLegalNoticeOrRecoverySuit(response.data['generated_draft']);
    //         setModalTitle(draftTitle);
    //         setDraftMasterId(draft_master_id)
    //         setShowModal(true);
    //     } catch (error) {
    //         console.error('Error fetching legal notice:', error);
    //     }
    // };

    // const handleRecoverySuitClick = async (draft_master_id) => {
    //     try {
    //         const token = localStorage.getItem('access_token');
    //         const response = await axios.get(`${commonUrl}/api/getLegalNoticeOrRecoverySuitDetail`, {
    //             params: { draft_master_id },
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //             },
    //         });
    //         // console.log(response.data)
    //         const legalNoticeData = response.data

    //         const mappedRecoverySuitData = {
    //             basicInfo: {
    //                 courtName: "", // User will fill this
    //                 filingDate: "", // User will fill this
    //             },
    //             plaintiffInfo: {
    //                 name: legalNoticeData.creditor_bank_name || "",
    //                 position: "", // If applicable
    //                 address: legalNoticeData.creditor_office_address || "",
    //                 legalRepresentative: "", // If applicable
    //                 companyNominationAttorney: "", // If applicable
    //             },
    //             defendantInfo: {
    //                 name: legalNoticeData.debtor_company_name || "",
    //                 businessInformation: "", // If applicable
    //                 address: legalNoticeData.debtor_address || "",
    //                 legalRepresentative: "",
    //             },
    //             debtDetails: {
    //                 natureOfLoan: "", // Map as appropriate
    //                 assetOfferedAsCollateral: "", // Map as appropriate
    //                 outstandingAmount: legalNoticeData.aggregated_amount || "",
    //                 defaultSince: legalNoticeData.overdue_date || "",
    //             },
    //             causeOfAction: {
    //                 contractualObligation: "", // User will fill this
    //                 reasonForDefault: "", // User will fill this
    //                 paymentFollowUp: "", // User will fill this
    //             },
    //             supportingDocuments: {
    //                 contracts: "", // User will fill this
    //                 invoices: "", // User will fill this
    //                 chequeDetails: "", // User will fill this
    //                 collateralAssetDetail: "", // User will fill this
    //                 anyOtherInfo: "", // User will fill this
    //             },
    //             legalNotices: {
    //                 noticeDate: legalNoticeData.notice_date || "",
    //                 defendantResponse: "", // User will fill this
    //             },
    //             reliefSought: {
    //                 totalPrincipalAmount: legalNoticeData.total_principal || "",
    //                 interestCalculation: legalNoticeData.total_markup_due || "", // User will fill this
    //                 panelCharges: legalNoticeData.penalCharges || "",
    //                 legalCosts: "", // User will fill this
    //                 penalties: "",
    //             },
    //             jurisdiction: {
    //                 pecuniaryJurisdiction: "", // User will fill this
    //                 territorialJurisdiction: "", // User will fill this
    //             },
    //             lawyerDetails: {
    //                 advocateName: "", // User will fill this
    //                 licenseNumber: "", // User will fill this
    //                 lawFirmName: "", // User will fill this
    //                 contactInformation: "", // User will fill this
    //             },
    //             prayer: {
    //                 prayerForDecree: "", // User will fill this
    //                 additionalRelief: "", // User will fill this
    //             },
    //         };

    //         // Update the recoverySuitData state in App.js
    //         setRecoverySuitData(mappedRecoverySuitData);

    //         setDraftMasterId(draft_master_id);
    //         navigate('/dashboard/form?draft_type_id=4');
    //     } catch (error) {
    //         console.error('Error fetching legal notice:', error);
    //     }

    // };

    // State to manage the collapse
    const [isOpen, setIsOpen] = useState(false);

    // const preprocessHtmlContent = (html) => {
    //     return html
    //         .replace(/“/g, '&ldquo;')
    //         .replace(/”/g, '&rdquo;')
    //         .replace(/‘/g, '&lsquo;')
    //         .replace(/’/g, '&rsquo;')
    //         .replace(/-/g, '&ndash;')
    //         .replace(/—/g, '&mdash;')
    //         .replace(/…/g, '&hellip;');
    // };

    // const generateWord = () => {
    //     try {
    //         const contentElement = contentRef.current;

    //         if (!contentElement) {
    //             console.error("Content reference is null");
    //             return;
    //         }

    //         // Get the HTML content
    //         let contentHtml = contentElement.innerHTML;
    //         contentHtml = preprocessHtmlContent(contentHtml);

    //         // Convert the HTML to a Word document blob
    //         const docxBlob = HtmlDocx.asBlob(contentHtml);

    //         // Save the blob as a .docx file
    //         saveAs(docxBlob, "content.docx");
    //     } catch (error) {
    //         console.error("Error generating document:", error);
    //     }
    // };   

    // Function to toggle the collapse state
    // const toggleCollapse = () => {
    //     setIsOpen(!isOpen);
    // };
    // const handleCloseModal = () => {
    //     setShowModal(false);
    //     setLegalNoticeOrRecoverySuit('');
    //     setModalTitle('');
    // };
    // Separate drafts into two arrays based on draft_type_name
    // const recoverySuits = drafts.filter(draft => draft.draft_type_name === 'Recovery Suit');
    // const legalNotices = drafts.filter(draft => draft.draft_type_name === 'Legal Notice');

    const civilSuitsDrafts = [
        {
            title: 'Legal Notice',
            description: 'Draft a legal notice to formally notify a party of a legal obligation.',
            draftTypeId: 11, // Replace with your actual draftTypeId for Legal Notice
            disabled: false,
        },
        {
            title: 'Reply to Legal Notice',
            description: "Draft a formal response to a legal notice, addressing the claims made and outlining the party's legal stance or obligations.",
            draftTypeId: 12, // Replace with your actual draftTypeId for Legal Notice
            disabled: false,
        },
        {
            title: 'Recovery Suit',
            description: 'Prepare a recovery suit to reclaim owed debts or assets.',
            draftTypeId: 4, // Replace with your actual draftTypeId for Recovery Suit
            disabled: false,
        },
        {
            title: 'Property Dispute Suit',
            description: 'Create a suit to resolve disputes over property ownership or rights.',
            draftTypeId: 15, // Assign appropriate draftTypeId
            disabled: true,
        },
        {
            title: 'Breach of Contract',
            description: 'Draft a suit addressing the breach of a contractual agreement.',
            draftTypeId: 16, // Assign appropriate draftTypeId
            disabled: true,
        },
        {
            title: 'Injunction Suit',
            description: 'Prepare a suit seeking a court order to prevent an action.',
            draftTypeId: 17, // Assign appropriate draftTypeId
            disabled: true,
        },
        {
            title: 'Specific Performance',
            description: 'Draft a suit demanding the fulfillment of contractual obligations.',
            draftTypeId: 18, // Assign appropriate draftTypeId
            disabled: true,
        },
        {
            title: 'Declaration Suit',
            description: 'Create a suit requesting a legal declaration on a matter.',
            draftTypeId: 19, // Assign appropriate draftTypeId
            disabled: true,
        },
        {
            title: 'Damages Suit',
            description: 'Prepare a suit seeking compensation for losses or injuries.',
            draftTypeId: 20, // Assign appropriate draftTypeId
            disabled: true,
        },
        {
            title: 'Partition Suit',
            description: 'Draft a suit to divide jointly owned property among owners.',
            draftTypeId: 21, // Assign appropriate draftTypeId
            disabled: true,
        },
    ];

    const contractsDrafts = [
        {
            title: 'Partnership Deed',
            description: 'Create a legal document outlining the terms of a partnership.',
            draftTypeId: 2,
            disabled: false,
        },
        {
            title: 'Settlement Agreement',
            description: 'Draft an agreement to settle a dispute between parties.',
            draftTypeId: 3,
            disabled: false,
        },
        {
            title: 'Sale Agreement',
            description: 'Prepare a contract for the sale of goods or services.',
            draftTypeId: 6,
            disabled: false,
        },
        {
            title: 'Lease Agreement',
            description: 'Generate a lease contract for renting property.',
            draftTypeId: 10,
            disabled: true,
        },
        {
            title: 'NDAs',
            description: 'Create a Non-Disclosure Agreement to protect confidential information.',
            draftTypeId: 5,
            disabled: false,
        },
        {
            title: 'Employment Contracts',
            description: 'Draft a contract outlining employment terms.',
            draftTypeId: 8,
            disabled: false,
        },
        {
            title: 'Service Agreement',
            description: 'Prepare an agreement for providing services to a client.',
            draftTypeId: 7,
            disabled: false,
        },
        {
            title: 'Joint Venture Agreement',
            description: 'Create an agreement for a business joint venture.',
            draftTypeId: 11,
            disabled: true,
        },
        {
            title: 'Franchise Agreement',
            description: 'Draft a contract for franchising a business model.',
            draftTypeId: 12,
            disabled: true,
        },
    ];

    const legalPetitionsDrafts = [
        {
            title: 'Bail Application',
            description: 'Prepare an application for bail in a legal proceeding.',
            draftTypeId: 1,
            disabled: false,
        },
        {
            title: 'Appeals',
            description: 'Draft a legal document to appeal a court decision.',
            draftTypeId: 9,
            disabled: false,
        },
        {
            title: 'Writ Petition',
            description: 'Generate a petition for writ in a higher court.',
            draftTypeId: 13,
            disabled: true,
        },
        {
            title: 'Constitutional Petition',
            description: 'Prepare a petition challenging the constitutionality of a law.',
            draftTypeId: 14,
            disabled: true,
        },
    ];

    return (
        <div className="container mt-4">
            {/* Tabs Navigation */}
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'legalPetitions' ? 'active' : ''}`}
                        onClick={() => setActiveTab('legalPetitions')}
                    >
                        Legal Petitions
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'contracts' ? 'active' : ''}`}
                        onClick={() => setActiveTab('contracts')}
                    >
                        Contracts & Agreements
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link ${activeTab === 'civilSuits' ? 'active' : ''}`}
                        onClick={() => setActiveTab('civilSuits')}
                    >
                        Civil Suits
                    </button>
                </li>
            </ul>

            {/* Tab Content */}
            <div className="tab-content mt-3">
                {/* Contracts & Agreements Tab */}
                {activeTab === 'contracts' && (
                    <div className="tab-pane active mt-5">
                        <div className="row">
                            {contractsDrafts.map((draft) => (
                                <DraftCard
                                    key={draft.draftTypeId}
                                    title={draft.title}
                                    description={draft.description}
                                    draftTypeId={draft.draftTypeId}
                                    disabled={draft.disabled}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {/* Legal Petitions Tab */}
                {activeTab === 'legalPetitions' && (
                    <div className="tab-pane active mt-5">
                        <div className="row">
                            {legalPetitionsDrafts.map((draft) => (
                                <DraftCard
                                    key={draft.draftTypeId}
                                    title={draft.title}
                                    description={draft.description}
                                    draftTypeId={draft.draftTypeId}
                                    disabled={draft.disabled}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {/* Civil Suits Tab */}
                {activeTab === 'civilSuits' && (
                    <div className="tab-pane active mt-5">
                        <div className="row">
                            {civilSuitsDrafts.map((draft) => (
                                <DraftCard
                                    key={draft.draftTypeId}
                                    title={draft.title}
                                    description={draft.description}
                                    draftTypeId={draft.draftTypeId}
                                    disabled={draft.disabled}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {/* {activeTab === 'civilSuits' && (
                    <div className="tab-pane active mt-5">
                        
                        <div className="row mb-4">
                            <div className="col-md-4">
                                <button
                                    className={`btn btn-primary ${styles.btn_color}`}
                                    onClick={() => navigate("/Dashboard/form?draft_type_id=11")}
                                >
                                    New Legal Notice
                                </button>
                            </div>
                        </div>

                        
                        <h4 className="mt-2">Legal Notices</h4>
                        {loading ? (
                            <p>Loading drafts...</p>
                        ) : legalNotices.length > 0 ? (
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Draft Title</th>
                                        <th>Status</th>
                                        <th>Created At</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {legalNotices.map((draft) => (
                                        <tr key={draft.draft_master_id}>
                                            <td>
                                                <a
                                                    href="#!"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDraftTitleClick(draft.draft_master_id, draft.draft_title, draft.draft_type_name);
                                                    }}
                                                >
                                                    {draft.draft_title}
                                                </a>
                                            </td>
                                            <td>{draft.status}</td>
                                            <td>{new Date(draft.created_at).toLocaleString()}</td>
                                            <td>
                                                <button
                                                    className={`btn btn-sm btn-warning ${styles.btn_color}`}
                                                    onClick={() => handleRecoverySuitClick(draft.draft_master_id)}
                                                >
                                                    Generate Recovery Suit
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No Legal Notice drafts found.</p>
                        )}

                        
                        <h4 className="mt-4">Recovery Suits</h4>
                        {loading ? (
                            <p>Loading drafts...</p>
                        ) : recoverySuits.length > 0 ? (
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Draft Title</th>
                                        <th>Status</th>
                                        <th>Created At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {recoverySuits.map((draft) => (
                                        <tr key={draft.draft_master_id}>
                                            <td>
                                                <a
                                                    href="#!"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleDraftTitleClick(draft.draft_master_id, draft.draft_title, draft.draft_type_name);
                                                    }}
                                                >
                                                    {draft.draft_title}
                                                </a>
                                            </td>
                                            <td>{draft.status}</td>
                                            <td>{new Date(draft.created_at).toLocaleString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>No Recovery Suit drafts found.</p>
                        )}

                        
                        {showModal && (
                            <div className="modal show fade d-block" tabIndex="-1" role="dialog">
                                <div className="modal-dialog modal-lg" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header d-flex justify-content-between align-items-center">
                                            <h5 className="modal-title">{modalTitle}</h5>
                                            <div>
                                                {draftType !== 'Recovery Suit' && (
                                                    <button
                                                        className={`btn btn-sm btn-warning mr-2 ${styles.btn_color}`}
                                                        onClick={() => handleRecoverySuitClick(draftMasterId)}
                                                    >
                                                        Generate Recovery Suit
                                                    </button>
                                                )}
                                                <button
                                                    className="btn btn-sm btn-secondary mr-2"
                                                    onClick={generateWord}
                                                >
                                                    Download as Word
                                                </button>
                                                <button type="button" className="close" onClick={handleCloseModal}>
                                                    <span>&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className="modal-body"
                                            style={{ height: '80vh', overflowY: 'auto' }}
                                            ref={contentRef}
                                        >
                                            <ReactMarkdown>{legalNoticeOrRecoverySuit}</ReactMarkdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        
                        {showModal && <div className="modal-backdrop fade show"></div>}
                    </div>
                )} */}
            </div>
        </div>
    );
};

export default LegalDrafts;
