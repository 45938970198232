import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const DraftCard = ({ title, description, draftTypeId, disabled }) => {
    const navigate = useNavigate();

    // Determine whether to show the "Generate" button
    const showGenerateButton = draftTypeId !== 4; // Assuming 4 is the draftTypeId for Recovery Suit

    return (
        <div className="col-md-4 mb-4">
            <div className={`card h-100 shadow-sm ${disabled ? 'bg-light' : ''}`}>
                <div className="card-body d-flex flex-column">
                    <h5 className="card-title" style={{color: '#166432'}}>{title}</h5>
                    <p className="card-text flex-grow-1">{description}</p>
                    <div className="mt-3">
                        {showGenerateButton && (
                            <button
                                className="btn btn-primary btn-sm mr-2"
                                onClick={() => navigate(`/dashboard/form?draft_type_id=${draftTypeId}`)}
                                style={{
                                    backgroundColor: '#166432',
                                    color: '#C59D55',
                                    border: 'none',
                                    transition: 'background-color 0.3s, color 0.3s',
                                }}
                                onMouseEnter={(e) => {
                                    e.target.style.backgroundColor = '#092415';
                                    e.target.style.color = '#fff';
                                }}
                                onMouseLeave={(e) => {
                                    e.target.style.backgroundColor = '#166432';
                                    e.target.style.color = '#C59D55';
                                }}
                                disabled={disabled}
                            >
                                Generate
                            </button>
                            
                        )}
                        <button
                            className={`btn btn-secondary btn-sm ${disabled ? 'disabled' : ''}`}
                            onClick={() => navigate(`/dashboard/DraftListing?draft_type_id=${draftTypeId}`)}
                            disabled={disabled}
                        >
                            View All
                        </button>
                    </div>
                </div>
                {disabled && (
                    <div className="card-footer">
                        <small className="text-muted">Coming Soon</small>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DraftCard;
