import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import * as pdfjsLib from 'pdfjs-dist';

// Set the workerSrc for PDF.js
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;


function ReplyLegalNoticeForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});
    const [summaryTextEntered, setSummaryTextEntered] = useState(false);
    const [summaryFileUploaded, setSummaryFileUploaded] = useState(false);

    const handleInputChange = (section, field, value) => {
        if (section === 'legalNoticeReference' && field === 'summary') {
            if (value.trim().length > 0) {
                setSummaryTextEntered(true);
                setSummaryFileUploaded(false);
                setFormData(prev => ({
                    ...prev,
                    [section]: {
                        ...prev[section],
                        [field]: value,
                        uploadedDocument: null, // Clear uploaded document if user types summary
                    },
                }));
                return;
            } else {
                setSummaryTextEntered(false);
            }
        }

        setFormData(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    };

    const handleSummaryFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type === 'application/pdf') {
                try {
                    const text = await extractTextFromPDF(file);
                    handleInputChange('legalNoticeReference', 'summary', text);
                    handleInputChange('legalNoticeReference', 'uploadedDocument', file);
                    setSummaryFileUploaded(true);
                    setSummaryTextEntered(false);
                } catch (error) {
                    console.error('Error extracting text from PDF:', error);
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        summaryOrDocument: 'Failed to extract text from PDF.',
                    }));
                }
            } else if (file.type === 'text/plain') {
                // Handle txt files
                const reader = new FileReader();
                reader.onload = function (e) {
                    const text = e.target.result;
                    handleInputChange('legalNoticeReference', 'summary', text);
                    handleInputChange('legalNoticeReference', 'uploadedDocument', file);
                    setSummaryFileUploaded(true);
                    setSummaryTextEntered(false);
                };
                reader.readAsText(file);
            } else {
                alert('Unsupported file type. Please upload a .txt or .pdf file.');
            }
        }
    };

    const extractTextFromPDF = async (file) => {
        const fileReader = new FileReader();
        return new Promise((resolve, reject) => {
            fileReader.onload = function () {
                const typedarray = new Uint8Array(this.result);
                pdfjsLib
                    .getDocument({ data: typedarray })
                    .promise.then(async pdf => {
                        let pdfText = '';
                        for (let i = 1; i <= pdf.numPages; i++) {
                            const page = await pdf.getPage(i);
                            const textContent = await page.getTextContent();
                            const pageText = textContent.items.map(item => item.str).join(' ');
                            pdfText += pageText + '\n';
                        }
                        resolve(pdfText);
                    })
                    .catch(err => {
                        console.error('Error parsing PDF:', err);
                        reject(err);
                    });
            };
            fileReader.onerror = reject;
            fileReader.readAsArrayBuffer(file);
        });
    };

    const validateForm = () => {
        let formErrors = {};

        // Validate Recipient (Notice Sender)
        if (!formData.recipient?.name) formErrors.recipientName = "Recipient Name/Company Name is required";

        // Validate Sender (Replying Party)
        if (!formData.sender?.name) formErrors.senderName = "Sender Name/Company Name is required";

        // Validate Legal Notice Reference
        if (!formData.legalNoticeReference?.dateOfNotice) formErrors.dateOfNotice = "Date of Notice is required";

        // Ensure either summary or uploaded document is provided, but not both
        // Ensure summary is provided
        if (!formData.legalNoticeReference?.summary?.trim()) {
            formErrors.summaryOrDocument = 'Please provide a summary, either by typing or uploading a .txt or .pdf file.';
        }

        // Validate Reply Details
        if (!formData.replyDetails?.agreeWithClaim) formErrors.agreeWithClaim = "Please select your agreement with the claim";

        // Validate Amount Claimed
        if (formData.replyDetails?.agreedAmount && isNaN(Number(formData.replyDetails.agreedAmount))) {
            formErrors.agreedAmount = "Agreed Amount must be a number";
        }
        if (formData.replyDetails?.disputedAmount && isNaN(Number(formData.replyDetails.disputedAmount))) {
            formErrors.disputedAmount = "Disputed Amount must be a number";
        }
        if (!formData.replyDetails?.reasonForDispute) {
            formErrors.reasonForDispute = "Reason for Dispute is required";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Form data to submit:", formData);
            onSubmit();
        }
    };

    // // Disable Summary if a document is uploaded, and disable Upload if Summary is provided
    // const isSummaryDisabled = formData.legalNoticeReference?.uploadedDocument;
    // const isUploadDisabled = formData.legalNoticeReference?.summary?.trim();

    return (
        <div className="container mb-2">
            <h2 className="text-center">Reply to Legal Notice</h2>
            <form onSubmit={handleSubmit}>
                {/* Recipient (Notice Sender) */}
                <div className="row mt-4">
                    <div className="col-md-6 mb-3">
                        <h4>1. Recipient (Notice Sender)</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Name/Company Name:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.recipientName ? 'is-invalid' : ''}`}
                                    value={formData.recipient?.name || ""}
                                    onChange={(e) => handleInputChange('recipient', 'name', e.target.value)}
                                    placeholder="Enter Recipient's Name/Company Name"
                                />
                                {errors.recipientName && <div className="invalid-feedback">{errors.recipientName}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Contact Person Name (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.recipient?.contactPersonName || ""}
                                    onChange={(e) => handleInputChange('recipient', 'contactPersonName', e.target.value)}
                                    placeholder="Enter Contact Person Name"
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Address (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.recipient?.address || ""}
                                    onChange={(e) => handleInputChange('recipient', 'address', e.target.value)}
                                    placeholder="Enter Address"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Sender (Replying Party) */}
                    <div className="col-md-6 mb-3">
                        <h4>2. Sender (Replying Party)</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Name/Company Name:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.senderName ? 'is-invalid' : ''}`}
                                    value={formData.sender?.name || ""}
                                    onChange={(e) => handleInputChange('sender', 'name', e.target.value)}
                                    placeholder="Enter Sender's Name/Company Name"
                                />
                                {errors.senderName && <div className="invalid-feedback">{errors.senderName}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Contact Person Name (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.sender?.contactPersonName || ""}
                                    onChange={(e) => handleInputChange('sender', 'contactPersonName', e.target.value)}
                                    placeholder="Enter Contact Person Name"
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Address (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.sender?.address || ""}
                                    onChange={(e) => handleInputChange('sender', 'address', e.target.value)}
                                    placeholder="Enter Address"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Legal Notice Reference */}
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <h4>3. Legal Notice Reference</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Reference Number (if any):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.legalNoticeReference?.referenceNumber || ""}
                                    onChange={(e) => handleInputChange('legalNoticeReference', 'referenceNumber', e.target.value)}
                                    placeholder="Enter Reference Number"
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Date of Notice:</label>
                                <input
                                    type="date"
                                    className={`form-control ${errors.dateOfNotice ? 'is-invalid' : ''}`}
                                    value={formData.legalNoticeReference?.dateOfNotice || ""}
                                    onChange={(e) => handleInputChange('legalNoticeReference', 'dateOfNotice', e.target.value)}
                                />
                                {errors.dateOfNotice && <div className="invalid-feedback">{errors.dateOfNotice}</div>}
                            </div>
                            {/* Upload Document */}
                            <div className="form-group mb-3">
                                <label>Upload Document (TXT or PDF):</label>
                                <input
                                    type="file"
                                    accept=".txt, .pdf"
                                    className={`form-control ${errors.summaryOrDocument ? 'is-invalid' : ''}`}
                                    onChange={handleSummaryFileUpload}
                                    disabled={summaryTextEntered}
                                />
                            </div>
                            {/* Summary */}
                            <div className="form-group mb-3">
                                <label>
                                    <strong>OR</strong> Summary:
                                </label>
                                <textarea
                                    className={`form-control ${errors.summaryOrDocument ? 'is-invalid' : ''}`}
                                    value={formData.legalNoticeReference?.summary || ''}
                                    onChange={e => handleInputChange('legalNoticeReference', 'summary', e.target.value)}
                                    placeholder="Enter Summary"
                                    rows={4}
                                    disabled={summaryFileUploaded}
                                />
                                {errors.summaryOrDocument && (
                                    <div className="invalid-feedback">{errors.summaryOrDocument}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Reply Details (Merged with Amount Claimed) */}
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <h4>4. Reply Details</h4>
                        <div className="border rounded p-3">
                            {/* Agreement with Claim */}
                            <div className="form-group mb-3">
                                <label><strong>Do you agree with the claim made in legal notice?</strong></label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="agreeWithClaim"
                                            id="agreeYes"
                                            value="Yes"
                                            checked={formData.replyDetails?.agreeWithClaim === "Yes"}
                                            onChange={(e) => handleInputChange('replyDetails', 'agreeWithClaim', e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="agreeYes">Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="agreeWithClaim"
                                            id="agreeNo"
                                            value="No"
                                            checked={formData.replyDetails?.agreeWithClaim === "No"}
                                            onChange={(e) => handleInputChange('replyDetails', 'agreeWithClaim', e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="agreeNo">No</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="agreeWithClaim"
                                            id="agreePartial"
                                            value="Partial"
                                            checked={formData.replyDetails?.agreeWithClaim === "Partial"}
                                            onChange={(e) => handleInputChange('replyDetails', 'agreeWithClaim', e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="agreePartial">Partial</label>
                                    </div>
                                    {errors.agreeWithClaim && <div className="text-danger">{errors.agreeWithClaim}</div>}
                                </div>
                            </div>
                            {/* Admitted Claims */}
                            <div className="form-group mb-3">
                                <label>Admitted Claims (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.replyDetails?.admittedClaims || ""}
                                    onChange={(e) => handleInputChange('replyDetails', 'admittedClaims', e.target.value)}
                                    placeholder="Enter Admitted Claims"
                                />
                            </div>
                            {/* Denied Claims */}
                            <div className="form-group mb-3">
                                <label>Denied Claims (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.replyDetails?.deniedClaims || ""}
                                    onChange={(e) => handleInputChange('replyDetails', 'deniedClaims', e.target.value)}
                                    placeholder="Enter Denied Claims"
                                />
                            </div>
                            {/* Justifications */}
                            <div className="form-group mb-3">
                                <label>Justifications/Explanations (optional):</label>
                                <textarea
                                    className="form-control"
                                    value={formData.replyDetails?.justifications || ""}
                                    onChange={(e) => handleInputChange('replyDetails', 'justifications', e.target.value)}
                                    placeholder="Provide Justifications/Explanations"
                                    rows={4}
                                />
                            </div>
                            <label><strong>Amount Claimed</strong></label>
                            {/* Amount Claimed */}
                            <div className="form-group mb-3">
                                <label>Agreed Amount (optional):</label>
                                <NumericFormat
                                    className={`form-control ${errors.agreedAmount ? 'is-invalid' : ''}`}
                                    value={formData.replyDetails?.agreedAmount || ""}
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        handleInputChange('replyDetails', 'agreedAmount', value);
                                    }}
                                    thousandSeparator=","
                                    placeholder="Enter Agreed Amount"
                                    allowNegative={false}
                                />
                                {errors.agreedAmount && <div className="invalid-feedback">{errors.agreedAmount}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Disputed Amount (optional):</label>
                                <NumericFormat
                                    className={`form-control ${errors.disputedAmount ? 'is-invalid' : ''}`}
                                    value={formData.replyDetails?.disputedAmount || ""}
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        handleInputChange('replyDetails', 'disputedAmount', value);
                                    }}
                                    thousandSeparator=","
                                    placeholder="Enter Disputed Amount"
                                    allowNegative={false}
                                />
                                {errors.disputedAmount && <div className="invalid-feedback">{errors.disputedAmount}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Reason for Dispute:</label>
                                <textarea
                                    className={`form-control ${errors.reasonForDispute ? 'is-invalid' : ''}`}
                                    value={formData.replyDetails?.reasonForDispute || ""}
                                    onChange={(e) => handleInputChange('replyDetails', 'reasonForDispute', e.target.value)}
                                    placeholder="Enter Reason for Dispute"
                                    rows={4}
                                />
                                {errors.reasonForDispute && <div className="invalid-feedback">{errors.reasonForDispute}</div>}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Additional Information */}
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <h4>5. Additional Information</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Counter Claims (optional):</label>
                                <textarea
                                    className="form-control"
                                    value={formData.counterClaims || ""}
                                    onChange={(e) => setFormData(prev => ({ ...prev, counterClaims: e.target.value }))}
                                    placeholder="Enter Counter Claims"
                                    rows={4}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Legal Grounds for Reply (optional):</label>
                                <textarea
                                    className="form-control"
                                    value={formData.legalGrounds || ""}
                                    onChange={(e) => setFormData(prev => ({ ...prev, legalGrounds: e.target.value }))}
                                    placeholder="Enter Legal Grounds for Reply"
                                    rows={4}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Supporting Documents (optional):</label>
                                <textarea
                                    className="form-control"
                                    value={formData.supportingDocuments || ""}
                                    onChange={(e) => setFormData(prev => ({ ...prev, supportingDocuments: e.target.value }))}
                                    placeholder="List Supporting Documents"
                                    rows={4}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="mt-4">
                    <button
                        type="submit"
                        className="btn px-4 py-2"
                        style={{
                            backgroundColor: '#166432',
                            color: '#C59D55',
                            border: 'none',
                            transition: 'background-color 0.3s, color 0.3s',
                        }}
                        onMouseEnter={(e) => {
                            e.target.style.backgroundColor = '#092415';
                            e.target.style.color = '#fff';
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.backgroundColor = '#166432';
                            e.target.style.color = '#C59D55';
                        }}
                    >
                        Next
                    </button>
                </div>
            </form>
        </div>
    );
}

export default ReplyLegalNoticeForm;
