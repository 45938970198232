import React, { useState } from 'react';
// Import PDF.js library
import * as pdfjsLib from 'pdfjs-dist';

// Set the workerSrc for PDF.js
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;


function CaseSummarizationForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});

    const handleTextChange = (e) => {
        const text = e.target.value;
        setFormData(prev => ({
            ...prev,
            jugmenttextContent: text,
            jugmentpdfContent: ''
        }));
    };

    // PDF file handling logic using pdf.js
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const text = await extractTextFromPDF(file);
                setFormData((prev) => ({
                    ...prev,
                    jugmenttextContent: '',
                    jugmentpdfContent: text,
                }));
            } catch (error) {
                console.error('Error extracting text from PDF:', error);
                setErrors({ input: 'Failed to extract text from PDF.' });
            }
        }
    };

    const extractTextFromPDF = async (file) => {
        const fileReader = new FileReader();
        return new Promise((resolve, reject) => {
            fileReader.onload = function () {
                const typedarray = new Uint8Array(this.result);
                pdfjsLib
                    .getDocument({ data: typedarray })
                    .promise.then(async (pdf) => {
                        let pdfText = '';
                        for (let i = 1; i <= pdf.numPages; i++) {
                            const page = await pdf.getPage(i);
                            const textContent = await page.getTextContent();
                            const pageText = textContent.items
                                .map((item) => item.str)
                                .join(' ');
                            pdfText += pageText + '\n';
                        }
                        resolve(pdfText);
                    })
                    .catch((err) => {
                        console.error('Error parsing PDF:', err);
                        reject(err);
                    });
            };
            fileReader.onerror = reject;
            fileReader.readAsArrayBuffer(file);
        });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.jugmenttextContent && !formData.jugmentpdfContent) {
            formErrors.input = 'Please enter text or upload a PDF.';
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            onSubmit();
        }
    };

    const isTextEntered = formData.jugmenttextContent && formData.jugmenttextContent.length > 0;

    return (
        <div className="mb-2">
            <h2 className="text-center">Case Summarization</h2>
            <form onSubmit={handleSubmit}>
                {/* PDF File Upload Input */}
                <div className="form-group">
                    <label>Upload a PDF File:</label>
                    <input
                        type="file"
                        className="form-control"
                        accept="application/pdf"
                        onChange={handleFileChange}
                        disabled={isTextEntered}
                    />
                </div>

                <div className="form-group">
                    <label>Or Case Text:</label>
                    <textarea
                        className="form-control"
                        value={formData.jugmenttextContent || ''}
                        onChange={handleTextChange}
                        placeholder="Paste the text of the case here"
                        rows={10}
                    ></textarea>
                </div>

                {errors.input && <div className="text-danger">{errors.input}</div>}
                <button
                    type="submit"
                    className="btn"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>
            </form>
        </div>
    );
}

export default CaseSummarizationForm;
