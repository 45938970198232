import React, { useState } from 'react';

function LegalAppealForm({ formData, setFormData, onSubmit }) {
  const [errors, setErrors] = useState({});

  const trialCourts = [
    'Lahore Civil Court',
    'Karachi Civil Court',
    'Islamabad Civil Court',
    // Add more trial courts as needed
  ];

  const appellateCourts = [
    'Lahore High Court',
    'Karachi High Court',
    'Islamabad High Court',
    'Supreme Court of Pakistan',
    // Add more appellate courts as needed
  ];

  const jurisdictions = ['Civil', 'Criminal', 'Constitutional', 'Administrative'];

  const appealTypes = ['Civil', 'Criminal', 'Constitutional', 'Administrative', 'Other'];

  const legalGroundsOptions = [
    'Error in Law',
    'Procedural Error',
    'Factual Error',
    'Insufficient Evidence',
    'Inadequate Reasoning',
    'New Evidence',
    'Other',
  ];

  const reliefSoughtOptions = [
    'Set aside the lower court\'s decision',
    'Order a new trial',
    'Reduce Sentence',
    'Modify Damages',
    'Other',
  ];

  const governingLaws = [
    'Pakistan Civil Procedure Code (CPC)',
    'Pakistan Penal Code (PPC)',
    'Constitution of Pakistan',
    'Other',
  ];

  const [otherAppealType, setOtherAppealType] = useState('');
  const [otherGoverningLaw, setOtherGoverningLaw] = useState('');

  const handleInputChange = (section, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value,
      },
    }));
  };

  const handleMultiSelectChange = (section, field, value) => {
    setFormData(prev => {
      const currentValues = prev[section][field] || [];
      if (currentValues.includes(value)) {
        // Remove the value
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [field]: currentValues.filter(item => item !== value),
          },
        };
      } else {
        // Add the value
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [field]: [...currentValues, value],
          },
        };
      }
    });
  };

  const handleFileChange = (section, field, files) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: files[0],
      },
    }));
  };

  const validateForm = () => {
    let formErrors = {};

    // Validate Case Information
    if (!formData.caseInformation?.caseTitle) formErrors.caseTitle = 'Case Title is required';
    if (!formData.caseInformation?.caseNumber) formErrors.caseNumber = 'Case Number is required';
    if (!formData.caseInformation?.trialCourt) formErrors.trialCourt = 'Trial Court is required';
    if (!formData.caseInformation?.trialCourtJudgmentDate)
      formErrors.trialCourtJudgmentDate = 'Trial Court Judgment Date is required';
    if (!formData.caseInformation?.appellateCourt) formErrors.appellateCourt = 'Appellate Court is required';
    if (!formData.caseInformation?.jurisdiction) formErrors.jurisdiction = 'Jurisdiction is required';

    // Validate Grounds for Appeal
    if (!formData.groundsForAppeal?.typeOfAppeal) formErrors.typeOfAppeal = 'Type of Appeal is required';
    if (!formData.groundsForAppeal?.legalGrounds || formData.groundsForAppeal?.legalGrounds.length === 0)
      formErrors.legalGrounds = 'At least one Legal Ground for Appeal is required';

    // Validate Appeal Issues
    if (!formData.appealIssues?.issuesToBeAppealed)
      formErrors.issuesToBeAppealed = 'Issues to be Appealed are required';

    // Validate Desired Outcome
    if (!formData.desiredOutcome?.reliefSought || formData.desiredOutcome?.reliefSought.length === 0)
      formErrors.reliefSought = 'At least one Relief Sought is required';

    // Validate Applicable Law and Legal Authority
    if (!formData.applicableLaw?.relevantStatutes)
      formErrors.relevantStatutes = 'Relevant Statutes are required';
    if (!formData.applicableLaw?.relevantCaseLaw)
      formErrors.relevantCaseLaw = 'Relevant Case Law is required';

    // Validate Procedural Information
    if (!formData.proceduralInformation?.dateOfFilingNotice)
      formErrors.dateOfFilingNotice = 'Date of Filing Notice of Appeal is required';
    if (!formData.proceduralInformation?.deadlineForFilingAppeal)
      formErrors.deadlineForFilingAppeal = 'Deadline for Filing Appeal is required';

    // Validate Representation Information
    if (!formData.representation?.lawyerName) formErrors.lawyerName = 'Appellant’s Lawyer Name is required';
    if (!formData.representation?.lawFirmName) formErrors.lawFirmName = 'Law Firm Name is required';
    if (!formData.representation?.email) formErrors.lawyerEmail = 'Lawyer’s Email is required';
    if (!formData.representation?.phone) formErrors.lawyerPhone = 'Lawyer’s Phone is required';

    // Validate Appellant Information
    if (!formData.appellant?.fullName) formErrors.appellantFullName = 'Appellant’s Full Name is required';
    if (!formData.appellant?.address) formErrors.appellantAddress = 'Appellant’s Address is required';
    if (!formData.appellant?.email) formErrors.appellantEmail = 'Appellant’s Email is required';
    if (!formData.appellant?.phone) formErrors.appellantPhone = 'Appellant’s Phone is required';
    if (!formData.appellant?.type) formErrors.appellantType = 'Type of Appellant is required';

    // Validate Respondent Information
    if (!formData.respondent?.fullName) formErrors.respondentFullName = 'Respondent’s Full Name is required';
    if (!formData.respondent?.address) formErrors.respondentAddress = 'Respondent’s Address is required';
    if (!formData.respondent?.type) formErrors.respondentType = 'Type of Respondent is required';

    // Validate Jurisdiction and Governing Law
    if (!formData.jurisdictionLaw?.governingLaw || formData.jurisdictionLaw?.governingLaw.length === 0)
      formErrors.governingLaw = 'At least one Governing Law is required';
    if (!formData.jurisdictionLaw?.jurisdiction) formErrors.jurisdictionField = 'Jurisdiction is required';

    // Validate Signature Confirmation
    if (!formData.confirmation?.confirmation)
      formErrors.confirmation = 'You must confirm that the information provided is accurate';

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form data to submit:', formData);
      setFormData(formData)
      onSubmit();
    }
  };

  return (
    <div className="mb-2">
      <h2 className="text-center">Legal Appeal Form</h2>
      <form onSubmit={handleSubmit}>
        {/* 1. Case Information */}
        <h4 className="mt-4">1. Case Information</h4>
        <div className="form-group">
          <label>Case Title:</label>
          <input
            type="text"
            className={`form-control ${errors.caseTitle ? 'is-invalid' : ''}`}
            value={formData.caseInformation?.caseTitle || ''}
            onChange={e => handleInputChange('caseInformation', 'caseTitle', e.target.value)}
            placeholder="Enter Case Title"
          />
          {errors.caseTitle && <div className="invalid-feedback">{errors.caseTitle}</div>}
        </div>
        <div className="form-group">
          <label>Case Number:</label>
          <input
            type="text"
            className={`form-control ${errors.caseNumber ? 'is-invalid' : ''}`}
            value={formData.caseInformation?.caseNumber || ''}
            onChange={e => handleInputChange('caseInformation', 'caseNumber', e.target.value)}
            placeholder="Enter Case Number"
          />
          {errors.caseNumber && <div className="invalid-feedback">{errors.caseNumber}</div>}
        </div>
        <div className="form-group">
          <label>Trial Court:</label>
          <select
            className={`form-control ${errors.trialCourt ? 'is-invalid' : ''}`}
            value={formData.caseInformation?.trialCourt || ''}
            onChange={e => handleInputChange('caseInformation', 'trialCourt', e.target.value)}
          >
            <option value="">Select Trial Court</option>
            {trialCourts.map(court => (
              <option key={court} value={court}>
                {court}
              </option>
            ))}
          </select>
          {errors.trialCourt && <div className="invalid-feedback">{errors.trialCourt}</div>}
        </div>
        <div className="form-group">
          <label>Trial Court Judgment Date:</label>
          <input
            type="date"
            className={`form-control ${errors.trialCourtJudgmentDate ? 'is-invalid' : ''}`}
            value={formData.caseInformation?.trialCourtJudgmentDate || ''}
            onChange={e =>
              handleInputChange('caseInformation', 'trialCourtJudgmentDate', e.target.value)
            }
          />
          {errors.trialCourtJudgmentDate && (
            <div className="invalid-feedback">{errors.trialCourtJudgmentDate}</div>
          )}
        </div>
        <div className="form-group">
          <label>Appellate Court:</label>
          <select
            className={`form-control ${errors.appellateCourt ? 'is-invalid' : ''}`}
            value={formData.caseInformation?.appellateCourt || ''}
            onChange={e => handleInputChange('caseInformation', 'appellateCourt', e.target.value)}
          >
            <option value="">Select Appellate Court</option>
            {appellateCourts.map(court => (
              <option key={court} value={court}>
                {court}
              </option>
            ))}
          </select>
          {errors.appellateCourt && <div className="invalid-feedback">{errors.appellateCourt}</div>}
        </div>
        <div className="form-group">
          <label>Jurisdiction:</label>
          <select
            className={`form-control ${errors.jurisdiction ? 'is-invalid' : ''}`}
            value={formData.caseInformation?.jurisdiction || ''}
            onChange={e => handleInputChange('caseInformation', 'jurisdiction', e.target.value)}
          >
            <option value="">Select Jurisdiction</option>
            {jurisdictions.map(jurisdiction => (
              <option key={jurisdiction} value={jurisdiction}>
                {jurisdiction}
              </option>
            ))}
          </select>
          {errors.jurisdiction && <div className="invalid-feedback">{errors.jurisdiction}</div>}
        </div>

        {/* 2. Grounds for Appeal */}
        <h4 className="mt-4">2. Grounds for Appeal</h4>
        <div className="form-group">
          <label>Type of Appeal:</label>
          <select
            className={`form-control ${errors.typeOfAppeal ? 'is-invalid' : ''}`}
            value={formData.groundsForAppeal?.typeOfAppeal || ''}
            onChange={e => {
              handleInputChange('groundsForAppeal', 'typeOfAppeal', e.target.value);
              if (e.target.value !== 'Other') setOtherAppealType('');
            }}
          >
            <option value="">Select Type of Appeal</option>
            {appealTypes.map(type => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          {errors.typeOfAppeal && <div className="invalid-feedback">{errors.typeOfAppeal}</div>}
        </div>
        {formData.groundsForAppeal?.typeOfAppeal === 'Other' && (
          <div className="form-group">
            <label>Please specify:</label>
            <input
              type="text"
              className="form-control"
              value={otherAppealType}
              onChange={e => setOtherAppealType(e.target.value)}
              placeholder="Specify Type of Appeal"
            />
          </div>
        )}
        <div className="form-group">
          <label>Legal Grounds for Appeal:</label>
          {legalGroundsOptions.map(option => (
            <div key={option} className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id={`legalGrounds-${option}`}
                checked={formData.groundsForAppeal?.legalGrounds?.includes(option) || false}
                onChange={() => handleMultiSelectChange('groundsForAppeal', 'legalGrounds', option)}
              />
              <label className="form-check-label" htmlFor={`legalGrounds-${option}`}>
                {option}
              </label>
            </div>
          ))}
          {errors.legalGrounds && <div className="text-danger">{errors.legalGrounds}</div>}
        </div>
        {formData.groundsForAppeal?.legalGrounds?.includes('Other') && (
          <div className="form-group">
            <label>Please specify:</label>
            <input
              type="text"
              className="form-control"
              value={formData.groundsForAppeal?.otherLegalGrounds || ''}
              onChange={e =>
                handleInputChange('groundsForAppeal', 'otherLegalGrounds', e.target.value)
              }
              placeholder="Specify Other Legal Grounds"
            />
          </div>
        )}

        {/* 3. Appeal Issues */}
        <h4 className="mt-4">3. Appeal Issues</h4>
        <div className="form-group">
          <label>Issues to be Appealed:</label>
          <textarea
            className={`form-control ${errors.issuesToBeAppealed ? 'is-invalid' : ''}`}
            value={formData.appealIssues?.issuesToBeAppealed || ''}
            onChange={e => handleInputChange('appealIssues', 'issuesToBeAppealed', e.target.value)}
            placeholder="List the issues being raised in the appeal"
          ></textarea>
          {errors.issuesToBeAppealed && (
            <div className="invalid-feedback">{errors.issuesToBeAppealed}</div>
          )}
        </div>
        <div className="form-group">
          <label>Trial Record References:</label>
          <textarea
            className="form-control"
            value={formData.appealIssues?.trialRecordReferences || ''}
            onChange={e =>
              handleInputChange('appealIssues', 'trialRecordReferences', e.target.value)
            }
            placeholder="Provide references to the trial record (e.g., page numbers, paragraphs)"
          ></textarea>
        </div>

        {/* 4. Desired Outcome */}
        <h4 className="mt-4">4. Desired Outcome</h4>
        <div className="form-group">
          <label>Relief Sought:</label>
          {reliefSoughtOptions.map(option => (
            <div key={option} className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id={`reliefSought-${option}`}
                checked={formData.desiredOutcome?.reliefSought?.includes(option) || false}
                onChange={() => handleMultiSelectChange('desiredOutcome', 'reliefSought', option)}
              />
              <label className="form-check-label" htmlFor={`reliefSought-${option}`}>
                {option}
              </label>
            </div>
          ))}
          {errors.reliefSought && <div className="text-danger">{errors.reliefSought}</div>}
        </div>
        {formData.desiredOutcome?.reliefSought?.includes('Other') && (
          <div className="form-group">
            <label>Please specify:</label>
            <input
              type="text"
              className="form-control"
              value={formData.desiredOutcome?.otherReliefSought || ''}
              onChange={e =>
                handleInputChange('desiredOutcome', 'otherReliefSought', e.target.value)
              }
              placeholder="Specify Other Relief Sought"
            />
          </div>
        )}

        {/* 5. Applicable Law and Legal Authority */}
        <h4 className="mt-4">5. Applicable Law and Legal Authority</h4>
        <div className="form-group">
          <label>Relevant Statutes:</label>
          <textarea
            className={`form-control ${errors.relevantStatutes ? 'is-invalid' : ''}`}
            value={formData.applicableLaw?.relevantStatutes || ''}
            onChange={e => handleInputChange('applicableLaw', 'relevantStatutes', e.target.value)}
            placeholder="List relevant statutes"
          ></textarea>
          {errors.relevantStatutes && (
            <div className="invalid-feedback">{errors.relevantStatutes}</div>
          )}
        </div>
        <div className="form-group">
          <label>Relevant Case Law:</label>
          <textarea
            className={`form-control ${errors.relevantCaseLaw ? 'is-invalid' : ''}`}
            value={formData.applicableLaw?.relevantCaseLaw || ''}
            onChange={e => handleInputChange('applicableLaw', 'relevantCaseLaw', e.target.value)}
            placeholder="List relevant case law"
          ></textarea>
          {errors.relevantCaseLaw && (
            <div className="invalid-feedback">{errors.relevantCaseLaw}</div>
          )}
        </div>

        {/* 6. Supporting Documents */}
        {/* <h4 className="mt-4">6. Supporting Documents</h4>
        <div className="form-group">
          <label>Upload Trial Court Judgment:</label>
          <input
            type="file"
            className="form-control-file"
            onChange={e => handleFileChange('supportingDocuments', 'trialCourtJudgment', e.target.files)}
          />
        </div>
        <div className="form-group">
          <label>Upload Notice of Appeal:</label>
          <input
            type="file"
            className="form-control-file"
            onChange={e => handleFileChange('supportingDocuments', 'noticeOfAppeal', e.target.files)}
          />
        </div>
        <div className="form-group">
          <label>Upload Trial Record/Transcripts:</label>
          <input
            type="file"
            className="form-control-file"
            onChange={e => handleFileChange('supportingDocuments', 'trialRecord', e.target.files)}
          />
        </div>
        <div className="form-group">
          <label>Upload New Evidence (if applicable):</label>
          <input
            type="file"
            className="form-control-file"
            onChange={e => handleFileChange('supportingDocuments', 'newEvidence', e.target.files)}
          />
        </div>
        <div className="form-group">
          <label>Additional Supporting Documents:</label>
          <input
            type="file"
            className="form-control-file"
            onChange={e => handleFileChange('supportingDocuments', 'additionalDocuments', e.target.files)}
          />
        </div> */}

        {/* 7. Procedural Information */}
        <h4 className="mt-4">6. Procedural Information</h4>
        <div className="form-group">
          <label>Date of Filing Notice of Appeal:</label>
          <input
            type="date"
            className={`form-control ${errors.dateOfFilingNotice ? 'is-invalid' : ''}`}
            value={formData.proceduralInformation?.dateOfFilingNotice || ''}
            onChange={e =>
              handleInputChange('proceduralInformation', 'dateOfFilingNotice', e.target.value)
            }
          />
          {errors.dateOfFilingNotice && (
            <div className="invalid-feedback">{errors.dateOfFilingNotice}</div>
          )}
        </div>
        <div className="form-group">
          <label>Deadline for Filing Appeal:</label>
          <input
            type="date"
            className={`form-control ${errors.deadlineForFilingAppeal ? 'is-invalid' : ''}`}
            value={formData.proceduralInformation?.deadlineForFilingAppeal || ''}
            onChange={e =>
              handleInputChange('proceduralInformation', 'deadlineForFilingAppeal', e.target.value)
            }
          />
          {errors.deadlineForFilingAppeal && (
            <div className="invalid-feedback">{errors.deadlineForFilingAppeal}</div>
          )}
        </div>
        <div className="form-group">
          <label>Any Previous Extensions:</label>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="previousExtensionsYes"
              name="previousExtensions"
              value="Yes"
              checked={formData.proceduralInformation?.previousExtensions === 'Yes'}
              onChange={e =>
                handleInputChange('proceduralInformation', 'previousExtensions', e.target.value)
              }
            />
            <label className="form-check-label" htmlFor="previousExtensionsYes">
              Yes
            </label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="previousExtensionsNo"
              name="previousExtensions"
              value="No"
              checked={formData.proceduralInformation?.previousExtensions === 'No'}
              onChange={e =>
                handleInputChange('proceduralInformation', 'previousExtensions', e.target.value)
              }
            />
            <label className="form-check-label" htmlFor="previousExtensionsNo">
              No
            </label>
          </div>
        </div>
        {formData.proceduralInformation?.previousExtensions === 'Yes' && (
          <div className="form-group">
            <label>Provide details:</label>
            <textarea
              className="form-control"
              value={formData.proceduralInformation?.extensionDetails || ''}
              onChange={e =>
                handleInputChange('proceduralInformation', 'extensionDetails', e.target.value)
              }
              placeholder="Provide details of previous extensions"
            ></textarea>
          </div>
        )}

        {/* 8. Representation Information */}
        <h4 className="mt-4">7. Representation Information</h4>
        <div className="form-group">
          <label>Appellant’s Lawyer Name:</label>
          <input
            type="text"
            className={`form-control ${errors.lawyerName ? 'is-invalid' : ''}`}
            value={formData.representation?.lawyerName || ''}
            onChange={e => handleInputChange('representation', 'lawyerName', e.target.value)}
            placeholder="Enter Lawyer's Name"
          />
          {errors.lawyerName && <div className="invalid-feedback">{errors.lawyerName}</div>}
        </div>
        <div className="form-group">
          <label>Law Firm Name:</label>
          <input
            type="text"
            className={`form-control ${errors.lawFirmName ? 'is-invalid' : ''}`}
            value={formData.representation?.lawFirmName || ''}
            onChange={e => handleInputChange('representation', 'lawFirmName', e.target.value)}
            placeholder="Enter Law Firm Name"
          />
          {errors.lawFirmName && <div className="invalid-feedback">{errors.lawFirmName}</div>}
        </div>
        <div className="form-group">
          <label>Lawyer’s Email:</label>
          <input
            type="email"
            className={`form-control ${errors.lawyerEmail ? 'is-invalid' : ''}`}
            value={formData.representation?.email || ''}
            onChange={e => handleInputChange('representation', 'email', e.target.value)}
            placeholder="Enter Lawyer's Email"
          />
          {errors.lawyerEmail && <div className="invalid-feedback">{errors.lawyerEmail}</div>}
        </div>
        <div className="form-group">
          <label>Lawyer’s Phone:</label>
          <input
            type="text"
            className={`form-control ${errors.lawyerPhone ? 'is-invalid' : ''}`}
            value={formData.representation?.phone || ''}
            onChange={e => handleInputChange('representation', 'phone', e.target.value)}
            placeholder="Enter Lawyer's Phone Number"
          />
          {errors.lawyerPhone && <div className="invalid-feedback">{errors.lawyerPhone}</div>}
        </div>

        {/* 9. Appellant Information */}
        <h4 className="mt-4">8. Appellant Information</h4>
        <div className="form-group">
          <label>Appellant's Full Name:</label>
          <input
            type="text"
            className={`form-control ${errors.appellantFullName ? 'is-invalid' : ''}`}
            value={formData.appellant?.fullName || ''}
            onChange={e => handleInputChange('appellant', 'fullName', e.target.value)}
            placeholder="Enter Appellant's Full Name"
          />
          {errors.appellantFullName && (
            <div className="invalid-feedback">{errors.appellantFullName}</div>
          )}
        </div>
        <div className="form-group">
          <label>Appellant’s CNIC (if individual):</label>
          <input
            type="text"
            className="form-control"
            value={formData.appellant?.cnic || ''}
            onChange={e => handleInputChange('appellant', 'cnic', e.target.value)}
            placeholder="Enter Appellant's CNIC"
          />
        </div>
        <div className="form-group">
          <label>Appellant's Address:</label>
          <input
            type="text"
            className={`form-control ${errors.appellantAddress ? 'is-invalid' : ''}`}
            value={formData.appellant?.address || ''}
            onChange={e => handleInputChange('appellant', 'address', e.target.value)}
            placeholder="Enter Appellant's Address"
          />
          {errors.appellantAddress && (
            <div className="invalid-feedback">{errors.appellantAddress}</div>
          )}
        </div>
        <div className="form-group">
          <label>Appellant’s Email:</label>
          <input
            type="email"
            className={`form-control ${errors.appellantEmail ? 'is-invalid' : ''}`}
            value={formData.appellant?.email || ''}
            onChange={e => handleInputChange('appellant', 'email', e.target.value)}
            placeholder="Enter Appellant's Email"
          />
          {errors.appellantEmail && (
            <div className="invalid-feedback">{errors.appellantEmail}</div>
          )}
        </div>
        <div className="form-group">
          <label>Appellant’s Phone:</label>
          <input
            type="text"
            className={`form-control ${errors.appellantPhone ? 'is-invalid' : ''}`}
            value={formData.appellant?.phone || ''}
            onChange={e => handleInputChange('appellant', 'phone', e.target.value)}
            placeholder="Enter Appellant's Phone Number"
          />
          {errors.appellantPhone && (
            <div className="invalid-feedback">{errors.appellantPhone}</div>
          )}
        </div>
        <div className="form-group">
          <label>Type of Appellant:</label>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="appellantTypeIndividual"
              name="appellantType"
              value="Individual"
              checked={formData.appellant?.type === 'Individual'}
              onChange={e => handleInputChange('appellant', 'type', e.target.value)}
            />
            <label className="form-check-label" htmlFor="appellantTypeIndividual">
              Individual
            </label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="appellantTypeBusiness"
              name="appellantType"
              value="Business Entity"
              checked={formData.appellant?.type === 'Business Entity'}
              onChange={e => handleInputChange('appellant', 'type', e.target.value)}
            />
            <label className="form-check-label" htmlFor="appellantTypeBusiness">
              Business Entity
            </label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="appellantTypeOther"
              name="appellantType"
              value="Other"
              checked={formData.appellant?.type === 'Other'}
              onChange={e => handleInputChange('appellant', 'type', e.target.value)}
            />
            <label className="form-check-label" htmlFor="appellantTypeOther">
              Other
            </label>
          </div>
          {errors.appellantType && <div className="text-danger">{errors.appellantType}</div>}
        </div>

        {/* 10. Respondent Information */}
        <h4 className="mt-4">9. Respondent Information</h4>
        <div className="form-group">
          <label>Respondent’s Full Name:</label>
          <input
            type="text"
            className={`form-control ${errors.respondentFullName ? 'is-invalid' : ''}`}
            value={formData.respondent?.fullName || ''}
            onChange={e => handleInputChange('respondent', 'fullName', e.target.value)}
            placeholder="Enter Respondent's Full Name"
          />
          {errors.respondentFullName && (
            <div className="invalid-feedback">{errors.respondentFullName}</div>
          )}
        </div>
        <div className="form-group">
          <label>Respondent’s CNIC (if individual):</label>
          <input
            type="text"
            className="form-control"
            value={formData.respondent?.cnic || ''}
            onChange={e => handleInputChange('respondent', 'cnic', e.target.value)}
            placeholder="Enter Respondent's CNIC"
          />
        </div>
        <div className="form-group">
          <label>Respondent’s Address:</label>
          <input
            type="text"
            className={`form-control ${errors.respondentAddress ? 'is-invalid' : ''}`}
            value={formData.respondent?.address || ''}
            onChange={e => handleInputChange('respondent', 'address', e.target.value)}
            placeholder="Enter Respondent's Address"
          />
          {errors.respondentAddress && (
            <div className="invalid-feedback">{errors.respondentAddress}</div>
          )}
        </div>
        <div className="form-group">
          <label>Respondent’s Email:</label>
          <input
            type="email"
            className="form-control"
            value={formData.respondent?.email || ''}
            onChange={e => handleInputChange('respondent', 'email', e.target.value)}
            placeholder="Enter Respondent's Email"
          />
        </div>
        <div className="form-group">
          <label>Respondent’s Phone:</label>
          <input
            type="text"
            className="form-control"
            value={formData.respondent?.phone || ''}
            onChange={e => handleInputChange('respondent', 'phone', e.target.value)}
            placeholder="Enter Respondent's Phone Number"
          />
        </div>
        <div className="form-group">
          <label>Type of Respondent:</label>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="respondentTypeIndividual"
              name="respondentType"
              value="Individual"
              checked={formData.respondent?.type === 'Individual'}
              onChange={e => handleInputChange('respondent', 'type', e.target.value)}
            />
            <label className="form-check-label" htmlFor="respondentTypeIndividual">
              Individual
            </label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="respondentTypeBusiness"
              name="respondentType"
              value="Business Entity"
              checked={formData.respondent?.type === 'Business Entity'}
              onChange={e => handleInputChange('respondent', 'type', e.target.value)}
            />
            <label className="form-check-label" htmlFor="respondentTypeBusiness">
              Business Entity
            </label>
          </div>
          <div className="form-check">
            <input
              type="radio"
              className="form-check-input"
              id="respondentTypeOther"
              name="respondentType"
              value="Other"
              checked={formData.respondent?.type === 'Other'}
              onChange={e => handleInputChange('respondent', 'type', e.target.value)}
            />
            <label className="form-check-label" htmlFor="respondentTypeOther">
              Other
            </label>
          </div>
          {errors.respondentType && <div className="text-danger">{errors.respondentType}</div>}
        </div>

        {/* 11. Jurisdiction and Governing Law */}
        <h4 className="mt-4">10. Jurisdiction and Governing Law</h4>
        <div className="form-group">
          <label>Governing Law:</label>
          {governingLaws.map(law => (
            <div key={law} className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id={`governingLaw-${law}`}
                checked={formData.jurisdictionLaw?.governingLaw?.includes(law) || false}
                onChange={() => handleMultiSelectChange('jurisdictionLaw', 'governingLaw', law)}
              />
              <label className="form-check-label" htmlFor={`governingLaw-${law}`}>
                {law}
              </label>
            </div>
          ))}
          {errors.governingLaw && <div className="text-danger">{errors.governingLaw}</div>}
        </div>
        {formData.jurisdictionLaw?.governingLaw?.includes('Other') && (
          <div className="form-group">
            <label>Please specify:</label>
            <input
              type="text"
              className="form-control"
              value={otherGoverningLaw}
              onChange={e => setOtherGoverningLaw(e.target.value)}
              placeholder="Specify Other Governing Law"
            />
          </div>
        )}
        <div className="form-group">
          <label>Jurisdiction:</label>
          <select
            className={`form-control ${errors.jurisdictionField ? 'is-invalid' : ''}`}
            value={formData.jurisdictionLaw?.jurisdiction || ''}
            onChange={e => handleInputChange('jurisdictionLaw', 'jurisdiction', e.target.value)}
          >
            <option value="">Select Jurisdiction</option>
            {appellateCourts.map(court => (
              <option key={court} value={court}>
                {court}
              </option>
            ))}
            <option value="Other">Other</option>
          </select>
          {errors.jurisdictionField && (
            <div className="invalid-feedback">{errors.jurisdictionField}</div>
          )}
        </div>
        {formData.jurisdictionLaw?.jurisdiction === 'Other' && (
          <div className="form-group">
            <label>Please specify:</label>
            <input
              type="text"
              className="form-control"
              value={formData.jurisdictionLaw?.otherJurisdiction || ''}
              onChange={e =>
                handleInputChange('jurisdictionLaw', 'otherJurisdiction', e.target.value)
              }
              placeholder="Specify Other Jurisdiction"
            />
          </div>
        )}

        {/* 12. Signature Fields */}
        <h4 className="mt-4">11. Signature Fields</h4>
        <div className="form-group">
          <label>Appellant's Lawyer’s Signature:</label>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="lawyerSignature"
              checked={formData.signatures?.lawyerSignature || false}
              onChange={e =>
                handleInputChange('signatures', 'lawyerSignature', e.target.checked)
              }
            />
            <label className="form-check-label" htmlFor="lawyerSignature">
              I, the Appellant's Lawyer, acknowledge and sign this appeal.
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Appellant's Signature:</label>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="appellantSignature"
              checked={formData.signatures?.appellantSignature || false}
              onChange={e =>
                handleInputChange('signatures', 'appellantSignature', e.target.checked)
              }
            />
            <label className="form-check-label" htmlFor="appellantSignature">
              I, the Appellant, acknowledge and sign this appeal.
            </label>
          </div>
        </div>

        {/* 13. Additional Notes or Comments */}
        <h4 className="mt-4">12. Additional Notes or Comments</h4>
        <div className="form-group">
          <label>Additional Notes:</label>
          <textarea
            className="form-control"
            value={formData.additionalNotes?.notes || ''}
            onChange={e => handleInputChange('additionalNotes', 'notes', e.target.value)}
            placeholder="Enter any additional notes or comments"
          ></textarea>
        </div>

        {/* 14. Submission Confirmation */}
        <h4 className="mt-4">13. Submission Confirmation</h4>
        <div className="form-group">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="confirmation"
              checked={formData.confirmation?.confirmation || false}
              onChange={e => handleInputChange('confirmation', 'confirmation', e.target.checked)}
            />
            <label className="form-check-label" htmlFor="confirmation">
              I confirm that the information provided is accurate and complete.
            </label>
          </div>
          {errors.confirmation && <div className="text-danger">{errors.confirmation}</div>}
        </div>

        {/* Submit Button */}
        {/* <button type="submit" className="btn btn-primary">
          Next
        </button> */}
        <button
          type="submit"
          className="btn"
          style={{
            backgroundColor: '#166432',
            color: '#C59D55',
            border: 'none',
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = '#092415';
            e.target.style.color = '#fff';
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = '#166432';
            e.target.style.color = '#C59D55';
          }}
        >
          Next
        </button>
      </form>
    </div>
  );
}

export default LegalAppealForm;
