// Profile.js
import React from 'react';
import { Link } from 'react-router-dom';
import style from './LandingPage.module.css'

const LandingPage = () => {
    return (
        <div className="container mt-5">
            {/* Introductory Text */}
            <div className="text-center mb-5">
                <h2>Welcome to PAK LAW ASSIST</h2>
                <p className="lead mt-2">
                    Use our AI-powered tools to conduct legal research, draft documents, summarize cases, and more.
                </p>
                <p className="lead">
                    Start exploring by selecting a feature below.
                </p>
            </div>

            {/* Cards Section */}
            <div className="row">
                {/* Case Summarization Card */}
                <div className="col-md-4 mb-4">
                    <div className="card h-100 shadow-sm">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Case Summarization</h5>
                            <p className="card-text flex-grow-1">
                                Summarize complex legal cases quickly and accurately.
                            </p>
                            <Link to="/dashboard/form?draft_type_id=10" className={`btn ${style.btn_color} mt-auto fw-bold`}>
                                <strong>Start Case Summarization</strong>
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Legal Drafts Card */}
                <div className="col-md-4 mb-4">
                    <div className="card h-100 shadow-sm">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Legal Drafts</h5>
                            <p className="card-text flex-grow-1">
                                Generate automated legal drafts like bail applications, contracts, and petitions.
                            </p>
                            <Link to="/dashboard/LegalDrafts" className={`btn ${style.btn_color} mt-auto fw-bold`}>
                                <strong>Create Legal Draft</strong>
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Legal Research Agent/Chatbot Card */}
                <div className="col-md-4 mb-4">
                    <div className="card h-100 shadow-sm">
                        <div className="card-body d-flex flex-column">
                            <h5 className="card-title">Legal Research Agent</h5>
                            <p className="card-text flex-grow-1">
                                Interact with our AI legal research assistant to find case laws, precedents, and legal insights.
                            </p>
                            <Link to="/dashboard/chatbot" className={`btn ${style.btn_color} mt-auto fw-bold`}>
                                <strong>Launch Legal Research Chat</strong>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
