// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardLayout_d-flex__z\\+8hS {
    display: flex;
}

.DashboardLayout_content__T0-00 {
    padding: 20px;
    /* Other styles */
}

.DashboardLayout_flex-grow-1__M5YjT {
    flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/DashboardLayout.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".d-flex {\n    display: flex;\n}\n\n.content {\n    padding: 20px;\n    /* Other styles */\n}\n\n.flex-grow-1 {\n    flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"d-flex": `DashboardLayout_d-flex__z+8hS`,
	"content": `DashboardLayout_content__T0-00`,
	"flex-grow-1": `DashboardLayout_flex-grow-1__M5YjT`
};
export default ___CSS_LOADER_EXPORT___;
