import React, { useState } from 'react';

function EmploymentContractForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});

    const employmentTypes = [
        'Permanent',
        'Contractual',
        'Probationary',
        'Temporary',
    ];

    const disputeResolutionMethods = [
        'Arbitration',
        'Mediation',
        'Court Litigation',
    ];

    const handleInputChange = (section, field, value) => {
        setFormData(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    };

    const validateForm = () => {
        let formErrors = {};

        // Validate Parties Information
        if (!formData.employer?.companyName) formErrors.companyName = "Company Name is required";
        if (!formData.employer?.registeredAddress) formErrors.registeredAddress = "Registered Address is required";
        if (!formData.employer?.registrationNumber) formErrors.registrationNumber = "Company Registration Number / NTN is required";
        if (!formData.employer?.email) formErrors.employerEmail = "Employer's Email is required";
        if (!formData.employer?.phone) formErrors.employerPhone = "Employer's Phone is required";

        if (!formData.employee?.fullName) formErrors.employeeFullName = "Employee's Full Name is required";
        if (!formData.employee?.cnic) formErrors.employeeCnic = "Employee's CNIC Number is required";
        if (!formData.employee?.address) formErrors.employeeAddress = "Employee's Residential Address is required";
        if (!formData.employee?.email) formErrors.employeeEmail = "Employee's Email is required";
        if (!formData.employee?.phone) formErrors.employeePhone = "Employee's Phone is required";

        // Validate Job Details
        if (!formData.jobDetails?.jobTitle) formErrors.jobTitle = "Job Title is required";
        if (!formData.jobDetails?.jobDescription) formErrors.jobDescription = "Job Description is required";
        if (!formData.jobDetails?.department) formErrors.department = "Department is required";
        if (!formData.jobDetails?.placeOfWork) formErrors.placeOfWork = "Place of Work is required";
        if (!formData.jobDetails?.reportingManager) formErrors.reportingManager = "Reporting Manager/Supervisor is required";

        // Validate Employment Terms
        if (!formData.employmentTerms?.startDate) formErrors.startDate = "Start Date is required";
        if (!formData.employmentTerms?.employmentType) formErrors.employmentType = "Type of Employment is required";
        if (formData.employmentTerms?.employmentType === 'Probationary') {
            if (!formData.employmentTerms?.probationPeriod) formErrors.probationPeriod = "Probation Period Duration is required";
            if (!formData.employmentTerms?.confirmationConditions) formErrors.confirmationConditions = "Conditions for Confirmation are required";
        }

        // Validate Working Hours
        if (!formData.workingHours?.workingDays) formErrors.workingDays = "Working Days per Week are required";
        if (!formData.workingHours?.dailyHours) formErrors.dailyHours = "Daily Working Hours are required";
        if (!formData.workingHours?.weeklyHours) formErrors.weeklyHours = "Total Weekly Hours are required";
        if (!formData.workingHours?.overtimeEligibility) formErrors.overtimeEligibility = "Overtime Eligibility is required";
        if (formData.workingHours?.overtimeEligibility === 'Yes') {
            if (!formData.workingHours?.overtimeRate) formErrors.overtimeRate = "Overtime Rate is required";
            if (!formData.workingHours?.maxOvertimeHours) formErrors.maxOvertimeHours = "Maximum Overtime Hours are required";
        }

        // Validate Remuneration
        if (!formData.remuneration?.basicSalary) formErrors.basicSalary = "Basic Salary Amount is required";
        if (!formData.remuneration?.currency) formErrors.currency = "Currency is required";
        if (!formData.remuneration?.paymentFrequency) formErrors.paymentFrequency = "Payment Frequency is required";
        if (!formData.remuneration?.paymentMethod) formErrors.paymentMethod = "Payment Method is required";

        // Validate Leave Entitlements
        if (!formData.leaveEntitlements?.annualLeaveDays) formErrors.annualLeaveDays = "Annual Leave Days per Year are required";
        if (!formData.leaveEntitlements?.sickLeaveDays) formErrors.sickLeaveDays = "Sick Leave Days per Year are required";
        if (!formData.leaveEntitlements?.casualLeaveDays) formErrors.casualLeaveDays = "Casual Leave Days per Year are required";

        // Validate Dispute Resolution
        if (!formData.disputeResolution?.governingLaw) formErrors.governingLaw = "Governing Law is required";
        if (!formData.disputeResolution?.jurisdiction) formErrors.jurisdiction = "Jurisdiction is required";
        if (!formData.disputeResolution?.disputeMethod) formErrors.disputeMethod = "Dispute Resolution Method is required";

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form data to submit:', formData);
            setFormData(formData);
            onSubmit();
        }
    };

    return (
        <div className="mb-2">
            <h2 className="text-center">Employment Contract Form</h2>
            <form onSubmit={handleSubmit}>
                {/* 1. Parties Information */}
                <h4 className="mt-4">1. Parties Information</h4>
                <div className="border rounded p-3 mb-3">
                    <h5>Employer Information</h5>
                    <div className="form-group">
                        <label>Company Name:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.companyName ? 'is-invalid' : ''}`}
                            value={formData.employer?.companyName || ''}
                            onChange={(e) => handleInputChange('employer', 'companyName', e.target.value)}
                            placeholder="Enter Company Name"
                        />
                        {errors.companyName && <div className="invalid-feedback">{errors.companyName}</div>}
                    </div>
                    <div className="form-group">
                        <label>Registered Address:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.registeredAddress ? 'is-invalid' : ''}`}
                            value={formData.employer?.registeredAddress || ''}
                            onChange={(e) => handleInputChange('employer', 'registeredAddress', e.target.value)}
                            placeholder="Enter Registered Address"
                        />
                        {errors.registeredAddress && <div className="invalid-feedback">{errors.registeredAddress}</div>}
                    </div>
                    <div className="form-group">
                        <label>Company Registration Number / NTN:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.registrationNumber ? 'is-invalid' : ''}`}
                            value={formData.employer?.registrationNumber || ''}
                            onChange={(e) => handleInputChange('employer', 'registrationNumber', e.target.value)}
                            placeholder="Enter Registration Number or NTN"
                        />
                        {errors.registrationNumber && <div className="invalid-feedback">{errors.registrationNumber}</div>}
                    </div>
                    <div className="form-group">
                        <label>Email:</label>
                        <input
                            type="email"
                            className={`form-control ${errors.employerEmail ? 'is-invalid' : ''}`}
                            value={formData.employer?.email || ''}
                            onChange={(e) => handleInputChange('employer', 'email', e.target.value)}
                            placeholder="Enter Employer's Email"
                        />
                        {errors.employerEmail && <div className="invalid-feedback">{errors.employerEmail}</div>}
                    </div>
                    <div className="form-group">
                        <label>Phone:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.employerPhone ? 'is-invalid' : ''}`}
                            value={formData.employer?.phone || ''}
                            onChange={(e) => handleInputChange('employer', 'phone', e.target.value)}
                            placeholder="Enter Employer's Phone Number"
                        />
                        {errors.employerPhone && <div className="invalid-feedback">{errors.employerPhone}</div>}
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Employee Information</h5>
                    <div className="form-group">
                        <label>Full Name:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.employeeFullName ? 'is-invalid' : ''}`}
                            value={formData.employee?.fullName || ''}
                            onChange={(e) => handleInputChange('employee', 'fullName', e.target.value)}
                            placeholder="Enter Employee's Full Name"
                        />
                        {errors.employeeFullName && <div className="invalid-feedback">{errors.employeeFullName}</div>}
                    </div>
                    <div className="form-group">
                        <label>CNIC Number:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.employeeCnic ? 'is-invalid' : ''}`}
                            value={formData.employee?.cnic || ''}
                            onChange={(e) => handleInputChange('employee', 'cnic', e.target.value)}
                            placeholder="Enter Employee's CNIC Number"
                        />
                        {errors.employeeCnic && <div className="invalid-feedback">{errors.employeeCnic}</div>}
                    </div>
                    <div className="form-group">
                        <label>Residential Address:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.employeeAddress ? 'is-invalid' : ''}`}
                            value={formData.employee?.address || ''}
                            onChange={(e) => handleInputChange('employee', 'address', e.target.value)}
                            placeholder="Enter Employee's Residential Address"
                        />
                        {errors.employeeAddress && <div className="invalid-feedback">{errors.employeeAddress}</div>}
                    </div>
                    <div className="form-group">
                        <label>Email:</label>
                        <input
                            type="email"
                            className={`form-control ${errors.employeeEmail ? 'is-invalid' : ''}`}
                            value={formData.employee?.email || ''}
                            onChange={(e) => handleInputChange('employee', 'email', e.target.value)}
                            placeholder="Enter Employee's Email"
                        />
                        {errors.employeeEmail && <div className="invalid-feedback">{errors.employeeEmail}</div>}
                    </div>
                    <div className="form-group">
                        <label>Phone:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.employeePhone ? 'is-invalid' : ''}`}
                            value={formData.employee?.phone || ''}
                            onChange={(e) => handleInputChange('employee', 'phone', e.target.value)}
                            placeholder="Enter Employee's Phone Number"
                        />
                        {errors.employeePhone && <div className="invalid-feedback">{errors.employeePhone}</div>}
                    </div>
                </div>

                {/* 2. Job Details */}
                <h4 className="mt-4">2. Job Details</h4>
                <div className="form-group">
                    <label>Job Title:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.jobTitle ? 'is-invalid' : ''}`}
                        value={formData.jobDetails?.jobTitle || ''}
                        onChange={(e) => handleInputChange('jobDetails', 'jobTitle', e.target.value)}
                        placeholder="Enter Job Title"
                    />
                    {errors.jobTitle && <div className="invalid-feedback">{errors.jobTitle}</div>}
                </div>
                <div className="form-group">
                    <label>Job Description:</label>
                    <textarea
                        className={`form-control ${errors.jobDescription ? 'is-invalid' : ''}`}
                        value={formData.jobDetails?.jobDescription || ''}
                        onChange={(e) => handleInputChange('jobDetails', 'jobDescription', e.target.value)}
                        placeholder="Enter Job Description"
                    ></textarea>
                    {errors.jobDescription && <div className="invalid-feedback">{errors.jobDescription}</div>}
                </div>
                <div className="form-group">
                    <label>Department:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.department ? 'is-invalid' : ''}`}
                        value={formData.jobDetails?.department || ''}
                        onChange={(e) => handleInputChange('jobDetails', 'department', e.target.value)}
                        placeholder="Enter Department"
                    />
                    {errors.department && <div className="invalid-feedback">{errors.department}</div>}
                </div>
                <div className="form-group">
                    <label>Place of Work:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.placeOfWork ? 'is-invalid' : ''}`}
                        value={formData.jobDetails?.placeOfWork || ''}
                        onChange={(e) => handleInputChange('jobDetails', 'placeOfWork', e.target.value)}
                        placeholder="Enter Place of Work"
                    />
                    {errors.placeOfWork && <div className="invalid-feedback">{errors.placeOfWork}</div>}
                </div>
                <div className="form-group">
                    <label>Reporting Manager/Supervisor:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.reportingManager ? 'is-invalid' : ''}`}
                        value={formData.jobDetails?.reportingManager || ''}
                        onChange={(e) => handleInputChange('jobDetails', 'reportingManager', e.target.value)}
                        placeholder="Enter Reporting Manager/Supervisor"
                    />
                    {errors.reportingManager && <div className="invalid-feedback">{errors.reportingManager}</div>}
                </div>

                {/* 3. Employment Terms */}
                <h4 className="mt-4">3. Employment Terms</h4>
                <div className="form-group">
                    <label>Start Date:</label>
                    <input
                        type="date"
                        className={`form-control ${errors.startDate ? 'is-invalid' : ''}`}
                        value={formData.employmentTerms?.startDate || ''}
                        onChange={(e) => handleInputChange('employmentTerms', 'startDate', e.target.value)}
                    />
                    {errors.startDate && <div className="invalid-feedback">{errors.startDate}</div>}
                </div>
                <div className="form-group">
                    <label>Type of Employment:</label>
                    <select
                        className={`form-control ${errors.employmentType ? 'is-invalid' : ''}`}
                        value={formData.employmentTerms?.employmentType || ''}
                        onChange={(e) => handleInputChange('employmentTerms', 'employmentType', e.target.value)}
                    >
                        <option value="">Select Employment Type</option>
                        {employmentTypes.map((type) => (
                            <option key={type} value={type}>
                                {type}
                            </option>
                        ))}
                    </select>
                    {errors.employmentType && <div className="invalid-feedback">{errors.employmentType}</div>}
                </div>

                {formData.employmentTerms?.employmentType === 'Probationary' && (
                    <>
                        <div className="form-group">
                            <label>Probation Period Duration:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.probationPeriod ? 'is-invalid' : ''}`}
                                value={formData.employmentTerms?.probationPeriod || ''}
                                onChange={(e) => handleInputChange('employmentTerms', 'probationPeriod', e.target.value)}
                                placeholder="Enter Probation Period Duration (e.g., 3 months)"
                            />
                            {errors.probationPeriod && <div className="invalid-feedback">{errors.probationPeriod}</div>}
                        </div>
                        <div className="form-group">
                            <label>Conditions for Confirmation:</label>
                            <textarea
                                className={`form-control ${errors.confirmationConditions ? 'is-invalid' : ''}`}
                                value={formData.employmentTerms?.confirmationConditions || ''}
                                onChange={(e) => handleInputChange('employmentTerms', 'confirmationConditions', e.target.value)}
                                placeholder="Enter Conditions for Confirmation"
                            ></textarea>
                            {errors.confirmationConditions && <div className="invalid-feedback">{errors.confirmationConditions}</div>}
                        </div>
                    </>
                )}

                {/* 4. Working Hours */}
                <h4 className="mt-4">4. Working Hours</h4>
                <div className="form-group">
                    <label>Working Days per Week:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.workingDays ? 'is-invalid' : ''}`}
                        value={formData.workingHours?.workingDays || ''}
                        onChange={(e) => handleInputChange('workingHours', 'workingDays', e.target.value)}
                        placeholder="Enter Working Days per Week (e.g., Monday to Friday)"
                    />
                    {errors.workingDays && <div className="invalid-feedback">{errors.workingDays}</div>}
                </div>
                <div className="form-group">
                    <label>Daily Working Hours:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.dailyHours ? 'is-invalid' : ''}`}
                        value={formData.workingHours?.dailyHours || ''}
                        onChange={(e) => handleInputChange('workingHours', 'dailyHours', e.target.value)}
                        placeholder="Enter Daily Working Hours (e.g., 9:00 AM to 5:00 PM)"
                    />
                    {errors.dailyHours && <div className="invalid-feedback">{errors.dailyHours}</div>}
                </div>
                <div className="form-group">
                    <label>Total Weekly Hours:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.weeklyHours ? 'is-invalid' : ''}`}
                        value={formData.workingHours?.weeklyHours || ''}
                        onChange={(e) => handleInputChange('workingHours', 'weeklyHours', e.target.value)}
                        placeholder="Enter Total Weekly Hours (e.g., 40 hours)"
                    />
                    {errors.weeklyHours && <div className="invalid-feedback">{errors.weeklyHours}</div>}
                </div>
                <div className="form-group">
                    <label>Overtime Eligibility:</label>
                    <select
                        className={`form-control ${errors.overtimeEligibility ? 'is-invalid' : ''}`}
                        value={formData.workingHours?.overtimeEligibility || ''}
                        onChange={(e) => handleInputChange('workingHours', 'overtimeEligibility', e.target.value)}
                    >
                        <option value="">Select Overtime Eligibility</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                    {errors.overtimeEligibility && <div className="invalid-feedback">{errors.overtimeEligibility}</div>}
                </div>

                {formData.workingHours?.overtimeEligibility === 'Yes' && (
                    <>
                        <div className="form-group">
                            <label>Overtime Rate:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.overtimeRate ? 'is-invalid' : ''}`}
                                value={formData.workingHours?.overtimeRate || ''}
                                onChange={(e) => handleInputChange('workingHours', 'overtimeRate', e.target.value)}
                                placeholder="Enter Overtime Rate (e.g., 1.5 times regular pay)"
                            />
                            {errors.overtimeRate && <div className="invalid-feedback">{errors.overtimeRate}</div>}
                        </div>
                        <div className="form-group">
                            <label>Maximum Overtime Hours:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.maxOvertimeHours ? 'is-invalid' : ''}`}
                                value={formData.workingHours?.maxOvertimeHours || ''}
                                onChange={(e) => handleInputChange('workingHours', 'maxOvertimeHours', e.target.value)}
                                placeholder="Enter Maximum Overtime Hours"
                            />
                            {errors.maxOvertimeHours && <div className="invalid-feedback">{errors.maxOvertimeHours}</div>}
                        </div>
                    </>
                )}

                {/* 5. Remuneration */}
                <h4 className="mt-4">5. Remuneration</h4>
                <div className="form-group">
                    <label>Basic Salary:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.basicSalary ? 'is-invalid' : ''}`}
                        value={formData.remuneration?.basicSalary || ''}
                        onChange={(e) => handleInputChange('remuneration', 'basicSalary', e.target.value)}
                        placeholder="Enter Basic Salary Amount"
                    />
                    {errors.basicSalary && <div className="invalid-feedback">{errors.basicSalary}</div>}
                </div>
                <div className="form-group">
                    <label>Currency:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.currency ? 'is-invalid' : ''}`}
                        value={formData.remuneration?.currency || 'PKR'}
                        onChange={(e) => handleInputChange('remuneration', 'currency', e.target.value)}
                        placeholder="Enter Currency (e.g., PKR)"
                    />
                    {errors.currency && <div className="invalid-feedback">{errors.currency}</div>}
                </div>
                <div className="form-group">
                    <label>Payment Frequency:</label>
                    <select
                        className={`form-control ${errors.paymentFrequency ? 'is-invalid' : ''}`}
                        value={formData.remuneration?.paymentFrequency || ''}
                        onChange={(e) => handleInputChange('remuneration', 'paymentFrequency', e.target.value)}
                    >
                        <option value="">Select Payment Frequency</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Bi-weekly">Bi-weekly</option>
                    </select>
                    {errors.paymentFrequency && <div className="invalid-feedback">{errors.paymentFrequency}</div>}
                </div>
                <div className="form-group">
                    <label>Payment Method:</label>
                    <select
                        className={`form-control ${errors.paymentMethod ? 'is-invalid' : ''}`}
                        value={formData.remuneration?.paymentMethod || ''}
                        onChange={(e) => handleInputChange('remuneration', 'paymentMethod', e.target.value)}
                    >
                        <option value="">Select Payment Method</option>
                        <option value="Bank Transfer">Bank Transfer</option>
                        <option value="Cheque">Cheque</option>
                    </select>
                    {errors.paymentMethod && <div className="invalid-feedback">{errors.paymentMethod}</div>}
                </div>
                {/* Additional Allowances and Benefits */}
                <div className="form-group">
                    <label>Allowances and Benefits:</label>
                    <textarea
                        className="form-control"
                        value={formData.remuneration?.allowances || ''}
                        onChange={(e) => handleInputChange('remuneration', 'allowances', e.target.value)}
                        placeholder="Enter Allowances and Benefits"
                    ></textarea>
                </div>
                {/* Bonuses */}
                <div className="form-group">
                    <label>Bonuses:</label>
                    <textarea
                        className="form-control"
                        value={formData.remuneration?.bonuses || ''}
                        onChange={(e) => handleInputChange('remuneration', 'bonuses', e.target.value)}
                        placeholder="Enter Bonuses Details"
                    ></textarea>
                </div>

                {/* 6. Leave Entitlements */}
                <h4 className="mt-4">6. Leave Entitlements</h4>
                <div className="form-group">
                    <label>Annual Leave Days Per Year:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.annualLeaveDays ? 'is-invalid' : ''}`}
                        value={formData.leaveEntitlements?.annualLeaveDays || ''}
                        onChange={(e) => handleInputChange('leaveEntitlements', 'annualLeaveDays', e.target.value)}
                        placeholder="Enter Annual Leave Days Per Year"
                    />
                    {errors.annualLeaveDays && <div className="invalid-feedback">{errors.annualLeaveDays}</div>}
                </div>
                <div className="form-group">
                    <label>Sick Leave Days Per Year:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.sickLeaveDays ? 'is-invalid' : ''}`}
                        value={formData.leaveEntitlements?.sickLeaveDays || ''}
                        onChange={(e) => handleInputChange('leaveEntitlements', 'sickLeaveDays', e.target.value)}
                        placeholder="Enter Sick Leave Days Per Year"
                    />
                    {errors.sickLeaveDays && <div className="invalid-feedback">{errors.sickLeaveDays}</div>}
                </div>
                <div className="form-group">
                    <label>Casual Leave Days Per Year:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.casualLeaveDays ? 'is-invalid' : ''}`}
                        value={formData.leaveEntitlements?.casualLeaveDays || ''}
                        onChange={(e) => handleInputChange('leaveEntitlements', 'casualLeaveDays', e.target.value)}
                        placeholder="Enter Casual Leave Days Per Year"
                    />
                    {errors.casualLeaveDays && <div className="invalid-feedback">{errors.casualLeaveDays}</div>}
                </div>
                {/* Additional Leaves */}
                <div className="form-group">
                    <label>Additional Leave Details (Maternity, Paternity, etc.):</label>
                    <textarea
                        className="form-control"
                        value={formData.leaveEntitlements?.additionalLeaves || ''}
                        onChange={(e) => handleInputChange('leaveEntitlements', 'additionalLeaves', e.target.value)}
                        placeholder="Enter Additional Leave Details"
                    ></textarea>
                </div>

                {/* 7. Benefits and Allowances */}
                <h4 className="mt-4">7. Benefits and Allowances</h4>
                <div className="form-group">
                    <label>Health Insurance Details:</label>
                    <textarea
                        className="form-control"
                        value={formData.benefits?.healthInsurance || ''}
                        onChange={(e) => handleInputChange('benefits', 'healthInsurance', e.target.value)}
                        placeholder="Enter Health Insurance Coverage Details"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Retirement Benefits:</label>
                    <textarea
                        className="form-control"
                        value={formData.benefits?.retirementBenefits || ''}
                        onChange={(e) => handleInputChange('benefits', 'retirementBenefits', e.target.value)}
                        placeholder="Enter Retirement Benefits Details"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Other Benefits:</label>
                    <textarea
                        className="form-control"
                        value={formData.benefits?.otherBenefits || ''}
                        onChange={(e) => handleInputChange('benefits', 'otherBenefits', e.target.value)}
                        placeholder="Enter Other Benefits"
                    ></textarea>
                </div>

                {/* 8. Confidentiality and Restrictive Covenants */}
                <h4 className="mt-4">8. Confidentiality and Restrictive Covenants</h4>
                <div className="form-group">
                    <label>Confidentiality Clause:</label>
                    <select
                        className="form-control"
                        value={formData.confidentiality?.clause || ''}
                        onChange={(e) => handleInputChange('confidentiality', 'clause', e.target.value)}
                    >
                        <option value="">Select Option</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                {formData.confidentiality?.clause === 'Yes' && (
                    <div className="form-group">
                        <label>Confidentiality Details:</label>
                        <textarea
                            className="form-control"
                            value={formData.confidentiality?.details || ''}
                            onChange={(e) => handleInputChange('confidentiality', 'details', e.target.value)}
                            placeholder="Enter Confidentiality Details"
                        ></textarea>
                    </div>
                )}
                <div className="form-group">
                    <label>Non-Compete Clause:</label>
                    <select
                        className="form-control"
                        value={formData.nonCompete?.clause || ''}
                        onChange={(e) => handleInputChange('nonCompete', 'clause', e.target.value)}
                    >
                        <option value="">Select Option</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                {formData.nonCompete?.clause === 'Yes' && (
                    <>
                        <div className="form-group">
                            <label>Duration:</label>
                            <input
                                type="text"
                                className="form-control"
                                value={formData.nonCompete?.duration || ''}
                                onChange={(e) => handleInputChange('nonCompete', 'duration', e.target.value)}
                                placeholder="Enter Duration (e.g., 1 year after termination)"
                            />
                        </div>
                        <div className="form-group">
                            <label>Geographical Scope:</label>
                            <input
                                type="text"
                                className="form-control"
                                value={formData.nonCompete?.geographicalScope || ''}
                                onChange={(e) => handleInputChange('nonCompete', 'geographicalScope', e.target.value)}
                                placeholder="Enter Geographical Scope (e.g., within Pakistan)"
                            />
                        </div>
                    </>
                )}
                <div className="form-group">
                    <label>Non-Solicitation Clause:</label>
                    <select
                        className="form-control"
                        value={formData.nonSolicitation?.clause || ''}
                        onChange={(e) => handleInputChange('nonSolicitation', 'clause', e.target.value)}
                    >
                        <option value="">Select Option</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </select>
                </div>
                {formData.nonSolicitation?.clause === 'Yes' && (
                    <div className="form-group">
                        <label>Non-Solicitation Details:</label>
                        <textarea
                            className="form-control"
                            value={formData.nonSolicitation?.details || ''}
                            onChange={(e) => handleInputChange('nonSolicitation', 'details', e.target.value)}
                            placeholder="Enter Non-Solicitation Details"
                        ></textarea>
                    </div>
                )}

                {/* 9. Termination Conditions */}
                <h4 className="mt-4">9. Termination Conditions</h4>
                <div className="form-group">
                    <label>Notice Period by Employer:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.termination?.noticePeriodEmployer || ''}
                        onChange={(e) => handleInputChange('termination', 'noticePeriodEmployer', e.target.value)}
                        placeholder="Enter Notice Period by Employer (e.g., 30 days)"
                    />
                </div>
                <div className="form-group">
                    <label>Notice Period by Employee:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.termination?.noticePeriodEmployee || ''}
                        onChange={(e) => handleInputChange('termination', 'noticePeriodEmployee', e.target.value)}
                        placeholder="Enter Notice Period by Employee (e.g., 30 days)"
                    />
                </div>
                <div className="form-group">
                    <label>Grounds for Termination:</label>
                    <textarea
                        className="form-control"
                        value={formData.termination?.grounds || ''}
                        onChange={(e) => handleInputChange('termination', 'grounds', e.target.value)}
                        placeholder="Enter Grounds for Termination"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Severance Pay Details:</label>
                    <textarea
                        className="form-control"
                        value={formData.termination?.severancePay || ''}
                        onChange={(e) => handleInputChange('termination', 'severancePay', e.target.value)}
                        placeholder="Enter Severance Pay Details"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Retirement Age:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.termination?.retirementAge || ''}
                        onChange={(e) => handleInputChange('termination', 'retirementAge', e.target.value)}
                        placeholder="Enter Retirement Age (e.g., 60 years)"
                    />
                </div>

                {/* 10. Dispute Resolution */}
                <h4 className="mt-4">10. Dispute Resolution</h4>
                <div className="form-group">
                    <label>Governing Law:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.governingLaw ? 'is-invalid' : ''}`}
                        value={formData.disputeResolution?.governingLaw || 'Pakistan'}
                        onChange={(e) => handleInputChange('disputeResolution', 'governingLaw', e.target.value)}
                    />
                    {errors.governingLaw && <div className="invalid-feedback">{errors.governingLaw}</div>}
                </div>
                <div className="form-group">
                    <label>Jurisdiction:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.jurisdiction ? 'is-invalid' : ''}`}
                        value={formData.disputeResolution?.jurisdiction || ''}
                        onChange={(e) => handleInputChange('disputeResolution', 'jurisdiction', e.target.value)}
                        placeholder="Enter Jurisdiction (e.g., Lahore Courts)"
                    />
                    {errors.jurisdiction && <div className="invalid-feedback">{errors.jurisdiction}</div>}
                </div>
                <div className="form-group">
                    <label>Dispute Resolution Method:</label>
                    <select
                        className={`form-control ${errors.disputeMethod ? 'is-invalid' : ''}`}
                        value={formData.disputeResolution?.disputeMethod || ''}
                        onChange={(e) => handleInputChange('disputeResolution', 'disputeMethod', e.target.value)}
                    >
                        <option value="">Select Method</option>
                        {disputeResolutionMethods.map((method) => (
                            <option key={method} value={method}>
                                {method}
                            </option>
                        ))}
                    </select>
                    {errors.disputeMethod && <div className="invalid-feedback">{errors.disputeMethod}</div>}
                </div>

                {/* 11. Other Terms */}
                <h4 className="mt-4">11. Other Terms</h4>
                <div className="form-group">
                    <label>Code of Conduct:</label>
                    <textarea
                        className="form-control"
                        value={formData.otherTerms?.codeOfConduct || ''}
                        onChange={(e) => handleInputChange('otherTerms', 'codeOfConduct', e.target.value)}
                        placeholder="Reference to company policies"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Employee Handbook Acknowledgment:</label>
                    <textarea
                        className="form-control"
                        value={formData.otherTerms?.employeeHandbook || ''}
                        onChange={(e) => handleInputChange('otherTerms', 'employeeHandbook', e.target.value)}
                        placeholder="Acknowledgment of receipt"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Amendments to Contract Procedure:</label>
                    <textarea
                        className="form-control"
                        value={formData.otherTerms?.amendments || ''}
                        onChange={(e) => handleInputChange('otherTerms', 'amendments', e.target.value)}
                        placeholder="Procedure for amendments"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Entire Agreement Clause:</label>
                    <select
                        className="form-control"
                        value={formData.otherTerms?.entireAgreement || ''}
                        onChange={(e) => handleInputChange('otherTerms', 'entireAgreement', e.target.value)}
                    >
                        <option value="">Select Option</option>
                        <option value="Included">Included</option>
                        <option value="Not Included">Not Included</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Severability Clause:</label>
                    <select
                        className="form-control"
                        value={formData.otherTerms?.severability || ''}
                        onChange={(e) => handleInputChange('otherTerms', 'severability', e.target.value)}
                    >
                        <option value="">Select Option</option>
                        <option value="Included">Included</option>
                        <option value="Not Included">Not Included</option>
                    </select>
                </div>

                {/* 12. Signatures and Witnesses */}
                <h4 className="mt-4">12. Signatures and Witnesses</h4>
                <div className="border rounded p-3 mb-3">
                    <h5>Employer's Authorized Representative</h5>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.employerName || ''}
                            onChange={(e) => handleInputChange('signatures', 'employerName', e.target.value)}
                            placeholder="Enter Name"
                        />
                    </div>
                    <div className="form-group">
                        <label>Designation:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.employerDesignation || ''}
                            onChange={(e) => handleInputChange('signatures', 'employerDesignation', e.target.value)}
                            placeholder="Enter Designation"
                        />
                    </div>
                    <div className="form-group">
                        <label>Date:</label>
                        <input
                            type="date"
                            className="form-control"
                            value={formData.signatures?.employerDate || ''}
                            onChange={(e) => handleInputChange('signatures', 'employerDate', e.target.value)}
                        />
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Employee</h5>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.employeeName || ''}
                            onChange={(e) => handleInputChange('signatures', 'employeeName', e.target.value)}
                            placeholder="Enter Name"
                        />
                    </div>
                    <div className="form-group">
                        <label>Date:</label>
                        <input
                            type="date"
                            className="form-control"
                            value={formData.signatures?.employeeDate || ''}
                            onChange={(e) => handleInputChange('signatures', 'employeeDate', e.target.value)}
                        />
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Witness 1</h5>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.witness1Name || ''}
                            onChange={(e) => handleInputChange('signatures', 'witness1Name', e.target.value)}
                            placeholder="Enter Witness 1's Name"
                        />
                    </div>
                    <div className="form-group">
                        <label>CNIC Number:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.witness1Cnic || ''}
                            onChange={(e) => handleInputChange('signatures', 'witness1Cnic', e.target.value)}
                            placeholder="Enter Witness 1's CNIC Number"
                        />
                    </div>
                    <div className="form-group">
                        <label>Date:</label>
                        <input
                            type="date"
                            className="form-control"
                            value={formData.signatures?.witness1Date || ''}
                            onChange={(e) => handleInputChange('signatures', 'witness1Date', e.target.value)}
                        />
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Witness 2</h5>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.witness2Name || ''}
                            onChange={(e) => handleInputChange('signatures', 'witness2Name', e.target.value)}
                            placeholder="Enter Witness 2's Name"
                        />
                    </div>
                    <div className="form-group">
                        <label>CNIC Number:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.witness2Cnic || ''}
                            onChange={(e) => handleInputChange('signatures', 'witness2Cnic', e.target.value)}
                            placeholder="Enter Witness 2's CNIC Number"
                        />
                    </div>
                    <div className="form-group">
                        <label>Date:</label>
                        <input
                            type="date"
                            className="form-control"
                            value={formData.signatures?.witness2Date || ''}
                            onChange={(e) => handleInputChange('signatures', 'witness2Date', e.target.value)}
                        />
                    </div>
                </div>

                {/* Submit Button */}
                {/* <button type="submit" className="btn btn-primary">Next</button> */}
                <button
                    type="submit"
                    className="btn"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>
            </form>
        </div>
    );
}

export default EmploymentContractForm;