import React, { useState } from 'react';

function SalesAgreementForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});

    const handleInputChange = (section, field, value) => {
        setFormData(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value
            }
        }));
    };

    const validateForm = () => {
        let formErrors = {};

        // Validate Seller's Information
        if (!formData.seller?.fullName) formErrors.sellerFullName = "Seller's full name or business name is required";
        if (!formData.seller?.cnic) formErrors.sellerCnic = "Seller's CNIC/Company Registration Number is required";
        if (!formData.seller?.address) formErrors.sellerAddress = "Seller's address is required";
        if (!formData.seller?.contact) formErrors.sellerContact = "Seller's contact information is required";

        // Validate Buyer's Information
        if (!formData.buyer?.fullName) formErrors.buyerFullName = "Buyer's full name or business name is required";
        if (!formData.buyer?.cnic) formErrors.buyerCnic = "Buyer's CNIC/Company Registration Number is required";
        if (!formData.buyer?.address) formErrors.buyerAddress = "Buyer's address is required";
        if (!formData.buyer?.contact) formErrors.buyerContact = "Buyer's contact information is required";

        // Validate Sale Subject
        if (!formData.saleSubject?.description) formErrors.description = "Description of goods is required";
        if (!formData.saleSubject?.condition) formErrors.condition = "Condition of the goods is required";
        if (!formData.saleSubject?.ownershipConfirmation) formErrors.ownershipConfirmation = "Ownership confirmation is required";

        // Validate Sale Price and Payment Terms
        if (!formData.paymentTerms?.totalPrice) formErrors.totalPrice = "Total sale price is required";
        if (!formData.paymentTerms?.paymentMethod) formErrors.paymentMethod = "Payment method is required";
        if (!formData.paymentTerms?.paymentSchedule) formErrors.paymentSchedule = "Payment schedule is required";

        // Validate Transfer of Ownership and Delivery
        if (!formData.transfer?.ownershipTransferDate) formErrors.ownershipTransferDate = "Ownership transfer date is required";
        if (!formData.transfer?.deliveryDate) formErrors.deliveryDate = "Delivery date is required";
        if (!formData.transfer?.deliveryMethod) formErrors.deliveryMethod = "Delivery method is required";

        // Validate Warranties and Representations
        if (!formData.warranties?.sellerWarranties) formErrors.sellerWarranties = "Seller's warranties are required";
        if (!formData.warranties?.buyerRepresentations) formErrors.buyerRepresentations = "Buyer's representations are required";

        // Validate Dispute Resolution
        if (!formData.disputeResolution?.governingLaw) formErrors.governingLaw = "Governing law is required";
        if (!formData.disputeResolution?.disputeMethod) formErrors.disputeMethod = "Dispute resolution method is required";

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setFormData(formData);
            console.log("Form data to submit:", formData);
            onSubmit();
        }
    };

    return (
        <div className="mb-2">
            <h2 className="text-center">Sales Agreement Form</h2>
            <form onSubmit={handleSubmit}>
                {/* 1. Parties Involved */}
                <h4 className="mt-4">1. Parties Involved</h4>
                <div className="border rounded p-3 mb-3">
                    <h5>Seller's Information</h5>
                    <div className="form-group">
                        <label>Full Name or Business Name:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.sellerFullName ? 'is-invalid' : ''}`}
                            value={formData.seller?.fullName || ""}
                            onChange={(e) => handleInputChange('seller', 'fullName', e.target.value)}
                            placeholder="Enter Seller's full name or business name"
                        />
                        {errors.sellerFullName && <div className="invalid-feedback">{errors.sellerFullName}</div>}
                    </div>
                    <div className="form-group">
                        <label>CNIC/Company Registration Number:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.sellerCnic ? 'is-invalid' : ''}`}
                            value={formData.seller?.cnic || ""}
                            onChange={(e) => handleInputChange('seller', 'cnic', e.target.value)}
                            placeholder="Enter CNIC or Company Registration Number"
                        />
                        {errors.sellerCnic && <div className="invalid-feedback">{errors.sellerCnic}</div>}
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.sellerAddress ? 'is-invalid' : ''}`}
                            value={formData.seller?.address || ""}
                            onChange={(e) => handleInputChange('seller', 'address', e.target.value)}
                            placeholder="Enter Seller's address"
                        />
                        {errors.sellerAddress && <div className="invalid-feedback">{errors.sellerAddress}</div>}
                    </div>
                    <div className="form-group">
                        <label>Contact Information (Phone, Email):</label>
                        <input
                            type="text"
                            className={`form-control ${errors.sellerContact ? 'is-invalid' : ''}`}
                            value={formData.seller?.contact || ""}
                            onChange={(e) => handleInputChange('seller', 'contact', e.target.value)}
                            placeholder="Enter Seller's contact information"
                        />
                        {errors.sellerContact && <div className="invalid-feedback">{errors.sellerContact}</div>}
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Buyer's Information</h5>
                    <div className="form-group">
                        <label>Full Name or Business Name:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.buyerFullName ? 'is-invalid' : ''}`}
                            value={formData.buyer?.fullName || ""}
                            onChange={(e) => handleInputChange('buyer', 'fullName', e.target.value)}
                            placeholder="Enter Buyer's full name or business name"
                        />
                        {errors.buyerFullName && <div className="invalid-feedback">{errors.buyerFullName}</div>}
                    </div>
                    <div className="form-group">
                        <label>CNIC/Company Registration Number:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.buyerCnic ? 'is-invalid' : ''}`}
                            value={formData.buyer?.cnic || ""}
                            onChange={(e) => handleInputChange('buyer', 'cnic', e.target.value)}
                            placeholder="Enter CNIC or Company Registration Number"
                        />
                        {errors.buyerCnic && <div className="invalid-feedback">{errors.buyerCnic}</div>}
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.buyerAddress ? 'is-invalid' : ''}`}
                            value={formData.buyer?.address || ""}
                            onChange={(e) => handleInputChange('buyer', 'address', e.target.value)}
                            placeholder="Enter Buyer's address"
                        />
                        {errors.buyerAddress && <div className="invalid-feedback">{errors.buyerAddress}</div>}
                    </div>
                    <div className="form-group">
                        <label>Contact Information (Phone, Email):</label>
                        <input
                            type="text"
                            className={`form-control ${errors.buyerContact ? 'is-invalid' : ''}`}
                            value={formData.buyer?.contact || ""}
                            onChange={(e) => handleInputChange('buyer', 'contact', e.target.value)}
                            placeholder="Enter Buyer's contact information"
                        />
                        {errors.buyerContact && <div className="invalid-feedback">{errors.buyerContact}</div>}
                    </div>
                </div>

                {/* 2. Sale Subject */}
                <h4 className="mt-4">2. Sale Subject</h4>
                <div className="form-group">
                    <label>Description of Goods:</label>
                    <textarea
                        className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                        value={formData.saleSubject?.description || ""}
                        onChange={(e) => handleInputChange('saleSubject', 'description', e.target.value)}
                        placeholder="Enter detailed description, specifications, brand, model, quantity, serial numbers if applicable"
                    ></textarea>
                    {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                </div>
                <div className="form-group">
                    <label>Condition of Goods:</label>
                    <select
                        className={`form-control ${errors.condition ? 'is-invalid' : ''}`}
                        value={formData.saleSubject?.condition || ""}
                        onChange={(e) => handleInputChange('saleSubject', 'condition', e.target.value)}
                    >
                        <option value="">Select Condition</option>
                        <option value="New">New</option>
                        <option value="Used">Used</option>
                        <option value="Refurbished">Refurbished</option>
                    </select>
                    {errors.condition && <div className="invalid-feedback">{errors.condition}</div>}
                </div>
                <div className="form-group form-check">
                    <input
                        type="checkbox"
                        className={`form-check-input ${errors.ownershipConfirmation ? 'is-invalid' : ''}`}
                        checked={formData.saleSubject?.ownershipConfirmation || false}
                        onChange={(e) => handleInputChange('saleSubject', 'ownershipConfirmation', e.target.checked)}
                    />
                    <label className="form-check-label">
                        I confirm that I have the legal right to sell the goods.
                    </label>
                    {errors.ownershipConfirmation && <div className="invalid-feedback d-block">{errors.ownershipConfirmation}</div>}
                </div>

                {/* 3. Sale Price and Payment Terms */}
                <h4 className="mt-4">3. Sale Price and Payment Terms</h4>
                <div className="form-group">
                    <label>Total Sale Price (PKR):</label>
                    <input
                        type="number"
                        className={`form-control ${errors.totalPrice ? 'is-invalid' : ''}`}
                        value={formData.paymentTerms?.totalPrice || ""}
                        onChange={(e) => handleInputChange('paymentTerms', 'totalPrice', e.target.value)}
                        placeholder="Enter total sale price"
                    />
                    {errors.totalPrice && <div className="invalid-feedback">{errors.totalPrice}</div>}
                </div>
                <div className="form-group">
                    <label>Payment Method:</label>
                    <select
                        className={`form-control ${errors.paymentMethod ? 'is-invalid' : ''}`}
                        value={formData.paymentTerms?.paymentMethod || ""}
                        onChange={(e) => handleInputChange('paymentTerms', 'paymentMethod', e.target.value)}
                    >
                        <option value="">Select Payment Method</option>
                        <option value="Bank Transfer">Bank Transfer</option>
                        <option value="Cheque">Cheque</option>
                        <option value="Cash">Cash</option>
                    </select>
                    {errors.paymentMethod && <div className="invalid-feedback">{errors.paymentMethod}</div>}
                </div>
                <div className="form-group">
                    <label>Payment Schedule:</label>
                    <textarea
                        className={`form-control ${errors.paymentSchedule ? 'is-invalid' : ''}`}
                        value={formData.paymentTerms?.paymentSchedule || ""}
                        onChange={(e) => handleInputChange('paymentTerms', 'paymentSchedule', e.target.value)}
                        placeholder="Describe payment schedule (e.g., 20% down payment, remaining in monthly installments)"
                    ></textarea>
                    {errors.paymentSchedule && <div className="invalid-feedback">{errors.paymentSchedule}</div>}
                </div>
                <div className="form-group">
                    <label>Deposit or Earnest Money (if any):</label>
                    <input
                        type="number"
                        className="form-control"
                        value={formData.paymentTerms?.deposit || ""}
                        onChange={(e) => handleInputChange('paymentTerms', 'deposit', e.target.value)}
                        placeholder="Enter deposit amount"
                    />
                </div>
                <div className="form-group">
                    <label>Deposit Payment Date:</label>
                    <input
                        type="date"
                        className="form-control"
                        value={formData.paymentTerms?.depositDate || ""}
                        onChange={(e) => handleInputChange('paymentTerms', 'depositDate', e.target.value)}
                    />
                </div>

                {/* 4. Transfer of Ownership and Delivery */}
                <h4 className="mt-4">4. Transfer of Ownership and Delivery</h4>
                <div className="form-group">
                    <label>Ownership Transfer Date:</label>
                    <input
                        type="date"
                        className={`form-control ${errors.ownershipTransferDate ? 'is-invalid' : ''}`}
                        value={formData.transfer?.ownershipTransferDate || ""}
                        onChange={(e) => handleInputChange('transfer', 'ownershipTransferDate', e.target.value)}
                    />
                    {errors.ownershipTransferDate && <div className="invalid-feedback">{errors.ownershipTransferDate}</div>}
                </div>
                <div className="form-group">
                    <label>Delivery Date:</label>
                    <input
                        type="date"
                        className={`form-control ${errors.deliveryDate ? 'is-invalid' : ''}`}
                        value={formData.transfer?.deliveryDate || ""}
                        onChange={(e) => handleInputChange('transfer', 'deliveryDate', e.target.value)}
                    />
                    {errors.deliveryDate && <div className="invalid-feedback">{errors.deliveryDate}</div>}
                </div>
                <div className="form-group">
                    <label>Delivery Method:</label>
                    <select
                        className={`form-control ${errors.deliveryMethod ? 'is-invalid' : ''}`}
                        value={formData.transfer?.deliveryMethod || ""}
                        onChange={(e) => handleInputChange('transfer', 'deliveryMethod', e.target.value)}
                    >
                        <option value="">Select Delivery Method</option>
                        <option value="Seller will ship the items">Seller will ship the items</option>
                        <option value="Buyer will collect the items">Buyer will collect the items</option>
                        <option value="Other">Other</option>
                    </select>
                    {errors.deliveryMethod && <div className="invalid-feedback">{errors.deliveryMethod}</div>}
                </div>

                {/* 5. Warranties and Representations */}
                <h4 className="mt-4">5. Warranties and Representations</h4>
                <div className="form-group">
                    <label>Seller's Warranties:</label>
                    <textarea
                        className={`form-control ${errors.sellerWarranties ? 'is-invalid' : ''}`}
                        value={formData.warranties?.sellerWarranties || ""}
                        onChange={(e) => handleInputChange('warranties', 'sellerWarranties', e.target.value)}
                        placeholder="Describe any warranties provided by the seller"
                    ></textarea>
                    {errors.sellerWarranties && <div className="invalid-feedback">{errors.sellerWarranties}</div>}
                </div>
                <div className="form-group">
                    <label>Buyer's Representations:</label>
                    <textarea
                        className={`form-control ${errors.buyerRepresentations ? 'is-invalid' : ''}`}
                        value={formData.warranties?.buyerRepresentations || ""}
                        onChange={(e) => handleInputChange('warranties', 'buyerRepresentations', e.target.value)}
                        placeholder="Confirmations from the buyer (e.g., financial ability to complete the purchase)"
                    ></textarea>
                    {errors.buyerRepresentations && <div className="invalid-feedback">{errors.buyerRepresentations}</div>}
                </div>

                {/* 6. Dispute Resolution */}
                <h4 className="mt-4">6. Dispute Resolution</h4>
                <div className="form-group">
                    <label>Governing Law:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.governingLaw ? 'is-invalid' : ''}`}
                        value={formData.disputeResolution?.governingLaw || "Pakistan"}
                        onChange={(e) => handleInputChange('disputeResolution', 'governingLaw', e.target.value)}
                        placeholder="Enter governing law"
                    />
                    {errors.governingLaw && <div className="invalid-feedback">{errors.governingLaw}</div>}
                </div>
                <div className="form-group">
                    <label>Dispute Resolution Method:</label>
                    <select
                        className={`form-control ${errors.disputeMethod ? 'is-invalid' : ''}`}
                        value={formData.disputeResolution?.disputeMethod || ""}
                        onChange={(e) => handleInputChange('disputeResolution', 'disputeMethod', e.target.value)}
                    >
                        <option value="">Select Dispute Resolution Method</option>
                        <option value="Arbitration">Arbitration</option>
                        <option value="Mediation">Mediation</option>
                        <option value="Court Litigation">Court Litigation</option>
                    </select>
                    {errors.disputeMethod && <div className="invalid-feedback">{errors.disputeMethod}</div>}
                </div>

                {/* 7. Default and Penalties */}
                <h4 className="mt-4">7. Default and Penalties</h4>
                <div className="form-group">
                    <label>Consequences of Default:</label>
                    <textarea
                        className="form-control"
                        value={formData.defaultPenalties?.consequences || ""}
                        onChange={(e) => handleInputChange('defaultPenalties', 'consequences', e.target.value)}
                        placeholder="Describe consequences if either party defaults"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Late Payment Penalties:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.defaultPenalties?.latePayment || ""}
                        onChange={(e) => handleInputChange('defaultPenalties', 'latePayment', e.target.value)}
                        placeholder="Describe any penalties or interest on overdue amounts"
                    />
                </div>

                {/* 8. Miscellaneous Clauses */}
                <h4 className="mt-4">8. Miscellaneous Clauses</h4>
                <div className="form-group form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={formData.miscellaneous?.indemnity || false}
                        onChange={(e) => handleInputChange('miscellaneous', 'indemnity', e.target.checked)}
                    />
                    <label className="form-check-label">
                        Include Indemnity Clause
                    </label>
                </div>
                <div className="form-group form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={formData.miscellaneous?.forceMajeure || false}
                        onChange={(e) => handleInputChange('miscellaneous', 'forceMajeure', e.target.checked)}
                    />
                    <label className="form-check-label">
                        Include Force Majeure Clause
                    </label>
                </div>
                <div className="form-group form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        checked={formData.miscellaneous?.confidentiality || false}
                        onChange={(e) => handleInputChange('miscellaneous', 'confidentiality', e.target.checked)}
                    />
                    <label className="form-check-label">
                        Include Confidentiality Clause
                    </label>
                </div>

                {/* 9. Signatures and Witnesses */}
                <h4 className="mt-4">9. Signatures and Witnesses</h4>
                <div className="border rounded p-3 mb-3">
                    <h5>Seller's Signature</h5>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.sellerName || ""}
                            onChange={(e) => handleInputChange('signatures', 'sellerName', e.target.value)}
                            placeholder="Enter Seller's name"
                        />
                    </div>
                    <div className="form-group">
                        <label>Date:</label>
                        <input
                            type="date"
                            className="form-control"
                            value={formData.signatures?.sellerDate || ""}
                            onChange={(e) => handleInputChange('signatures', 'sellerDate', e.target.value)}
                        />
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Buyer's Signature</h5>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.buyerName || ""}
                            onChange={(e) => handleInputChange('signatures', 'buyerName', e.target.value)}
                            placeholder="Enter Buyer's name"
                        />
                    </div>
                    <div className="form-group">
                        <label>Date:</label>
                        <input
                            type="date"
                            className="form-control"
                            value={formData.signatures?.buyerDate || ""}
                            onChange={(e) => handleInputChange('signatures', 'buyerDate', e.target.value)}
                        />
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Witness 1</h5>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.witness1Name || ""}
                            onChange={(e) => handleInputChange('signatures', 'witness1Name', e.target.value)}
                            placeholder="Enter Witness 1's name"
                        />
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.witness1Address || ""}
                            onChange={(e) => handleInputChange('signatures', 'witness1Address', e.target.value)}
                            placeholder="Enter Witness 1's address"
                        />
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Witness 2</h5>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.witness2Name || ""}
                            onChange={(e) => handleInputChange('signatures', 'witness2Name', e.target.value)}
                            placeholder="Enter Witness 2's name"
                        />
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.witness2Address || ""}
                            onChange={(e) => handleInputChange('signatures', 'witness2Address', e.target.value)}
                            placeholder="Enter Witness 2's address"
                        />
                    </div>
                </div>

                {/* Submit Button */}
                {/* <button type="submit" className="btn btn-primary">Next</button> */}
                <button
                    type="submit"
                    className="btn"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>
            </form>
        </div>
    );
}

export default SalesAgreementForm;
