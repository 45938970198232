import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import styles from './DashboardLayout.module.css';
import { HistoryProvider } from '../Context/HistoryContext'
import { ChatProvider } from '../Context/ChatContext';

const DashboardLayout = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <HistoryProvider>
            <ChatProvider>
                <>
                    <Navbar setIsOpen={setIsOpen} isOpen={isOpen} />
                    <div className={styles['d-flex']}>
                        <Sidebar isOpen={isOpen} />
                        <div className={`${styles.content} ${styles['flex-grow-1']}`}>
                            <Outlet />
                        </div>
                    </div>
                </>
            </ChatProvider>
        </HistoryProvider>
    );
};

export default DashboardLayout;