import React, { useState } from 'react';

function ServiceAgreementForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});

    const serviceTypes = [
        'Consulting',
        'Contracting',
        'Freelancing',
        'General Services',
        'IT/Software',
        'Marketing/Advertising',
    ];

    const handleInputChange = (section, field, value) => {
        if (section === 'main') {
            setFormData(prev => ({
                ...prev,
                [field]: value,
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [section]: {
                    ...prev[section],
                    [field]: value,
                },
            }));
        }
    };

    const validateForm = () => {
        let formErrors = {};

        // Validate Service Type
        if (!formData.serviceType) formErrors.serviceType = 'Service type is required';

        // Validate Service Provider Information
        if (!formData.serviceProvider?.fullName) formErrors.serviceProviderFullName = "Service Provider's full name or business name is required";
        if (!formData.serviceProvider?.cnic) formErrors.serviceProviderCnic = "Service Provider's CNIC/Company Registration Number is required";
        if (!formData.serviceProvider?.address) formErrors.serviceProviderAddress = "Service Provider's address is required";
        if (!formData.serviceProvider?.contact) formErrors.serviceProviderContact = "Service Provider's contact information is required";

        // Validate Client Information
        if (!formData.client?.fullName) formErrors.clientFullName = "Client's full name or business name is required";
        if (!formData.client?.cnic) formErrors.clientCnic = "Client's CNIC/Company Registration Number is required";
        if (!formData.client?.address) formErrors.clientAddress = "Client's address is required";
        if (!formData.client?.contact) formErrors.clientContact = "Client's contact information is required";

        // Validate Scope of Services
        if (!formData.scopeOfServices?.description) formErrors.description = "Description of services is required";
        if (!formData.scopeOfServices?.serviceLocation) formErrors.serviceLocation = "Service location is required";
        if (!formData.scopeOfServices?.performanceStandards) formErrors.performanceStandards = "Performance standards are required";

        // Validate Duration and Timeline
        if (!formData.duration?.startDate) formErrors.startDate = "Start date is required";
        if (!formData.duration?.endDate) formErrors.endDate = "End date or indication of ongoing service is required";

        // Validate Payment Terms
        if (!formData.paymentTerms?.serviceFee) formErrors.serviceFee = "Service fee is required";
        if (!formData.paymentTerms?.paymentSchedule) formErrors.paymentSchedule = "Payment schedule is required";
        if (!formData.paymentTerms?.paymentMethod) formErrors.paymentMethod = "Payment method is required";

        // Validate Dispute Resolution
        if (!formData.disputeResolution?.governingLaw) formErrors.governingLaw = "Governing law is required";
        if (!formData.disputeResolution?.disputeMethod) formErrors.disputeMethod = "Dispute resolution method is required";
        if (!formData.disputeResolution?.jurisdiction) formErrors.jurisdiction = "Jurisdiction is required";

        // Add additional validations based on service type
        if (formData.serviceType === 'Consulting') {
            if (!formData.consulting?.deliverables) formErrors.deliverables = "Consulting deliverables are required";
            if (!formData.consulting?.performanceReviewMilestones) formErrors.performanceReviewMilestones = "Performance review milestones are required";
            if (!formData.consulting?.ipOwnership) formErrors.ipOwnership = "IP ownership details are required";
        } else if (formData.serviceType === 'Contracting') {
            if (!formData.contracting?.materialsResponsibility) formErrors.materialsResponsibility = "Material or equipment responsibilities are required";
            if (!formData.contracting?.siteInformation) formErrors.siteInformation = "Site information is required";
            if (!formData.contracting?.insuranceLiability) formErrors.insuranceLiability = "Insurance and liability details are required";
            if (!formData.contracting?.compliance) formErrors.compliance = "Compliance with local regulations is required";
        } else if (formData.serviceType === 'Freelancing') {
            if (!formData.freelancing?.projectPayments) formErrors.projectPayments = "Project-based payments details are required";
            if (!formData.freelancing?.ownershipOfWork) formErrors.ownershipOfWork = "Ownership of work details are required";
            if (!formData.freelancing?.revisions) formErrors.revisions = "Revisions and edits details are required";
        } else if (formData.serviceType === 'General Services') {
            if (!formData.generalServices?.serviceLocation) formErrors.generalServiceLocation = "Service location is required";
            if (!formData.generalServices?.suppliesMaterials) formErrors.suppliesMaterials = "Supplies and materials responsibilities are required";
        } else if (formData.serviceType === 'IT/Software') {
            if (!formData.itSoftware?.sla) formErrors.sla = "Service Level Agreement (SLA) details are required";
            if (!formData.itSoftware?.maintenanceUpdates) formErrors.maintenanceUpdates = "Maintenance and updates details are required";
            if (!formData.itSoftware?.intellectualProperty) formErrors.intellectualProperty = "Intellectual property details are required";
        } else if (formData.serviceType === 'Marketing/Advertising') {
            if (!formData.marketing?.campaignDetails) formErrors.campaignDetails = "Campaign details are required";
            if (!formData.marketing?.performanceMetrics) formErrors.performanceMetrics = "Target goals/performance metrics are required";
            if (!formData.marketing?.contentOwnership) formErrors.contentOwnership = "Content ownership details are required";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form data to submit:', formData);
            setFormData(formData);
            onSubmit();
        }
    };

    return (
        <div className="mb-2">
            <h2 className="text-center">Service Agreement Form</h2>
            <form onSubmit={handleSubmit}>
                {/* Service Type */}
                <h4 className="mt-4">1. Type of Service Agreement</h4>
                <div className="form-group">
                    <label>Service Type:</label>
                    <select
                        className={`form-control ${errors.serviceType ? 'is-invalid' : ''}`}
                        value={formData.serviceType || ''}
                        onChange={(e) => handleInputChange('main', 'serviceType', e.target.value)}
                    >
                        <option value="">Select Service Type</option>
                        {serviceTypes.map((type) => (
                            <option key={type} value={type}>
                                {type}
                            </option>
                        ))}
                    </select>
                    {errors.serviceType && <div className="invalid-feedback">{errors.serviceType}</div>}
                </div>

                {/* 2. Parties Involved */}
                <h4 className="mt-4">2. Parties Involved</h4>
                <div className="border rounded p-3 mb-3">
                    <h5>Service Provider Information</h5>
                    <div className="form-group">
                        <label>Full Name or Business Name:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.serviceProviderFullName ? 'is-invalid' : ''}`}
                            value={formData.serviceProvider?.fullName || ''}
                            onChange={(e) => handleInputChange('serviceProvider', 'fullName', e.target.value)}
                            placeholder="Enter Service Provider's full name or business name"
                        />
                        {errors.serviceProviderFullName && <div className="invalid-feedback">{errors.serviceProviderFullName}</div>}
                    </div>
                    <div className="form-group">
                        <label>CNIC/Company Registration Number:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.serviceProviderCnic ? 'is-invalid' : ''}`}
                            value={formData.serviceProvider?.cnic || ''}
                            onChange={(e) => handleInputChange('serviceProvider', 'cnic', e.target.value)}
                            placeholder="Enter CNIC or Company Registration Number"
                        />
                        {errors.serviceProviderCnic && <div className="invalid-feedback">{errors.serviceProviderCnic}</div>}
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.serviceProviderAddress ? 'is-invalid' : ''}`}
                            value={formData.serviceProvider?.address || ''}
                            onChange={(e) => handleInputChange('serviceProvider', 'address', e.target.value)}
                            placeholder="Enter Service Provider's address"
                        />
                        {errors.serviceProviderAddress && <div className="invalid-feedback">{errors.serviceProviderAddress}</div>}
                    </div>
                    <div className="form-group">
                        <label>Contact Information (Email, Phone):</label>
                        <input
                            type="text"
                            className={`form-control ${errors.serviceProviderContact ? 'is-invalid' : ''}`}
                            value={formData.serviceProvider?.contact || ''}
                            onChange={(e) => handleInputChange('serviceProvider', 'contact', e.target.value)}
                            placeholder="Enter Service Provider's contact information"
                        />
                        {errors.serviceProviderContact && <div className="invalid-feedback">{errors.serviceProviderContact}</div>}
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Client Information</h5>
                    <div className="form-group">
                        <label>Full Name or Business Name:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.clientFullName ? 'is-invalid' : ''}`}
                            value={formData.client?.fullName || ''}
                            onChange={(e) => handleInputChange('client', 'fullName', e.target.value)}
                            placeholder="Enter Client's full name or business name"
                        />
                        {errors.clientFullName && <div className="invalid-feedback">{errors.clientFullName}</div>}
                    </div>
                    <div className="form-group">
                        <label>CNIC/Company Registration Number:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.clientCnic ? 'is-invalid' : ''}`}
                            value={formData.client?.cnic || ''}
                            onChange={(e) => handleInputChange('client', 'cnic', e.target.value)}
                            placeholder="Enter CNIC or Company Registration Number"
                        />
                        {errors.clientCnic && <div className="invalid-feedback">{errors.clientCnic}</div>}
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.clientAddress ? 'is-invalid' : ''}`}
                            value={formData.client?.address || ''}
                            onChange={(e) => handleInputChange('client', 'address', e.target.value)}
                            placeholder="Enter Client's address"
                        />
                        {errors.clientAddress && <div className="invalid-feedback">{errors.clientAddress}</div>}
                    </div>
                    <div className="form-group">
                        <label>Contact Information (Email, Phone):</label>
                        <input
                            type="text"
                            className={`form-control ${errors.clientContact ? 'is-invalid' : ''}`}
                            value={formData.client?.contact || ''}
                            onChange={(e) => handleInputChange('client', 'contact', e.target.value)}
                            placeholder="Enter Client's contact information"
                        />
                        {errors.clientContact && <div className="invalid-feedback">{errors.clientContact}</div>}
                    </div>
                </div>

                {/* 3. Scope of Services */}
                <h4 className="mt-4">3. Scope of Services</h4>
                <div className="form-group">
                    <label>Description of Services:</label>
                    <textarea
                        className={`form-control ${errors.description ? 'is-invalid' : ''}`}
                        value={formData.scopeOfServices?.description || ''}
                        onChange={(e) => handleInputChange('scopeOfServices', 'description', e.target.value)}
                        placeholder="Provide a detailed description of the services"
                    ></textarea>
                    {errors.description && <div className="invalid-feedback">{errors.description}</div>}
                </div>
                <div className="form-group">
                    <label>Service Location:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.serviceLocation ? 'is-invalid' : ''}`}
                        value={formData.scopeOfServices?.serviceLocation || ''}
                        onChange={(e) => handleInputChange('scopeOfServices', 'serviceLocation', e.target.value)}
                        placeholder="Enter where the services will be provided"
                    />
                    {errors.serviceLocation && <div className="invalid-feedback">{errors.serviceLocation}</div>}
                </div>
                <div className="form-group">
                    <label>Performance Standards:</label>
                    <textarea
                        className={`form-control ${errors.performanceStandards ? 'is-invalid' : ''}`}
                        value={formData.scopeOfServices?.performanceStandards || ''}
                        onChange={(e) => handleInputChange('scopeOfServices', 'performanceStandards', e.target.value)}
                        placeholder="Outline any performance standards or KPIs"
                    ></textarea>
                    {errors.performanceStandards && <div className="invalid-feedback">{errors.performanceStandards}</div>}
                </div>

                {/* 4. Duration and Timeline */}
                <h4 className="mt-4">4. Duration and Timeline</h4>
                <div className="form-group">
                    <label>Start Date:</label>
                    <input
                        type="date"
                        className={`form-control ${errors.startDate ? 'is-invalid' : ''}`}
                        value={formData.duration?.startDate || ''}
                        onChange={(e) => handleInputChange('duration', 'startDate', e.target.value)}
                    />
                    {errors.startDate && <div className="invalid-feedback">{errors.startDate}</div>}
                </div>
                <div className="form-group">
                    <label>End Date (if applicable):</label>
                    <input
                        type="date"
                        className={`form-control ${errors.endDate ? 'is-invalid' : ''}`}
                        value={formData.duration?.endDate || ''}
                        onChange={(e) => handleInputChange('duration', 'endDate', e.target.value)}
                    />
                    {errors.endDate && <div className="invalid-feedback">{errors.endDate}</div>}
                </div>
                <div className="form-group">
                    <label>Key Milestones/Deadlines:</label>
                    <textarea
                        className="form-control"
                        value={formData.duration?.milestones || ''}
                        onChange={(e) => handleInputChange('duration', 'milestones', e.target.value)}
                        placeholder="List any specific deadlines or milestones"
                    ></textarea>
                </div>

                {/* 5. Payment Terms */}
                <h4 className="mt-4">5. Payment Terms</h4>
                <div className="form-group">
                    <label>Service Fee:</label>
                    <input
                        type="number"
                        className={`form-control ${errors.serviceFee ? 'is-invalid' : ''}`}
                        value={formData.paymentTerms?.serviceFee || ''}
                        onChange={(e) => handleInputChange('paymentTerms', 'serviceFee', e.target.value)}
                        placeholder="Enter the total amount to be paid"
                    />
                    {errors.serviceFee && <div className="invalid-feedback">{errors.serviceFee}</div>}
                </div>
                <div className="form-group">
                    <label>Payment Schedule:</label>
                    <textarea
                        className={`form-control ${errors.paymentSchedule ? 'is-invalid' : ''}`}
                        value={formData.paymentTerms?.paymentSchedule || ''}
                        onChange={(e) => handleInputChange('paymentTerms', 'paymentSchedule', e.target.value)}
                        placeholder="Specify payment schedule"
                    ></textarea>
                    {errors.paymentSchedule && <div className="invalid-feedback">{errors.paymentSchedule}</div>}
                </div>
                <div className="form-group">
                    <label>Payment Method:</label>
                    <select
                        className={`form-control ${errors.paymentMethod ? 'is-invalid' : ''}`}
                        value={formData.paymentTerms?.paymentMethod || ''}
                        onChange={(e) => handleInputChange('paymentTerms', 'paymentMethod', e.target.value)}
                    >
                        <option value="">Select Payment Method</option>
                        <option value="Bank Transfer">Bank Transfer</option>
                        <option value="Cheque">Cheque</option>
                        <option value="Cash">Cash</option>
                    </select>
                    {errors.paymentMethod && <div className="invalid-feedback">{errors.paymentMethod}</div>}
                </div>
                <div className="form-group">
                    <label>Late Payment Penalties:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.paymentTerms?.latePaymentPenalties || ''}
                        onChange={(e) => handleInputChange('paymentTerms', 'latePaymentPenalties', e.target.value)}
                        placeholder="Specify penalties for late payments"
                    />
                </div>

                {/* 6. Specific Inputs for Selected Service Type */}
                {formData.serviceType === 'Consulting' && (
                    <>
                        <h4 className="mt-4">6. Consulting Specific Terms</h4>
                        <div className="form-group">
                            <label>Consulting Deliverables:</label>
                            <textarea
                                className={`form-control ${errors.deliverables ? 'is-invalid' : ''}`}
                                value={formData.consulting?.deliverables || ''}
                                onChange={(e) => handleInputChange('consulting', 'deliverables', e.target.value)}
                                placeholder="Detail the outputs you will deliver"
                            ></textarea>
                            {errors.deliverables && <div className="invalid-feedback">{errors.deliverables}</div>}
                        </div>
                        <div className="form-group">
                            <label>Performance Review Milestones:</label>
                            <textarea
                                className={`form-control ${errors.performanceReviewMilestones ? 'is-invalid' : ''}`}
                                value={formData.consulting?.performanceReviewMilestones || ''}
                                onChange={(e) => handleInputChange('consulting', 'performanceReviewMilestones', e.target.value)}
                                placeholder="Specify performance review milestones"
                            ></textarea>
                            {errors.performanceReviewMilestones && <div className="invalid-feedback">{errors.performanceReviewMilestones}</div>}
                        </div>
                        <div className="form-group">
                            <label>IP Ownership:</label>
                            <textarea
                                className={`form-control ${errors.ipOwnership ? 'is-invalid' : ''}`}
                                value={formData.consulting?.ipOwnership || ''}
                                onChange={(e) => handleInputChange('consulting', 'ipOwnership', e.target.value)}
                                placeholder="Specify intellectual property ownership details"
                            ></textarea>
                            {errors.ipOwnership && <div className="invalid-feedback">{errors.ipOwnership}</div>}
                        </div>
                    </>
                )}

                {formData.serviceType === 'Contracting' && (
                    <>
                        <h4 className="mt-4">6. Contracting Specific Terms</h4>
                        <div className="form-group">
                            <label>Material or Equipment Responsibilities:</label>
                            <textarea
                                className={`form-control ${errors.materialsResponsibility ? 'is-invalid' : ''}`}
                                value={formData.contracting?.materialsResponsibility || ''}
                                onChange={(e) => handleInputChange('contracting', 'materialsResponsibility', e.target.value)}
                                placeholder="Specify who provides materials, equipment, or tools"
                            ></textarea>
                            {errors.materialsResponsibility && <div className="invalid-feedback">{errors.materialsResponsibility}</div>}
                        </div>
                        <div className="form-group">
                            <label>Site Information:</label>
                            <textarea
                                className={`form-control ${errors.siteInformation ? 'is-invalid' : ''}`}
                                value={formData.contracting?.siteInformation || ''}
                                onChange={(e) => handleInputChange('contracting', 'siteInformation', e.target.value)}
                                placeholder="Provide details about the work site"
                            ></textarea>
                            {errors.siteInformation && <div className="invalid-feedback">{errors.siteInformation}</div>}
                        </div>
                        <div className="form-group">
                            <label>Insurance and Liability Clauses:</label>
                            <textarea
                                className={`form-control ${errors.insuranceLiability ? 'is-invalid' : ''}`}
                                value={formData.contracting?.insuranceLiability || ''}
                                onChange={(e) => handleInputChange('contracting', 'insuranceLiability', e.target.value)}
                                placeholder="Specify insurance and liability details"
                            ></textarea>
                            {errors.insuranceLiability && <div className="invalid-feedback">{errors.insuranceLiability}</div>}
                        </div>
                        <div className="form-group">
                            <label>Compliance with Local Regulations:</label>
                            <textarea
                                className={`form-control ${errors.compliance ? 'is-invalid' : ''}`}
                                value={formData.contracting?.compliance || ''}
                                onChange={(e) => handleInputChange('contracting', 'compliance', e.target.value)}
                                placeholder="Outline compliance with local regulations"
                            ></textarea>
                            {errors.compliance && <div className="invalid-feedback">{errors.compliance}</div>}
                        </div>
                    </>
                )}

                {formData.serviceType === 'Freelancing' && (
                    <>
                        <h4 className="mt-4">6. Freelancing Specific Terms</h4>
                        <div className="form-group">
                            <label>Project-Based Payments:</label>
                            <textarea
                                className={`form-control ${errors.projectPayments ? 'is-invalid' : ''}`}
                                value={formData.freelancing?.projectPayments || ''}
                                onChange={(e) => handleInputChange('freelancing', 'projectPayments', e.target.value)}
                                placeholder="Detail per-project pricing"
                            ></textarea>
                            {errors.projectPayments && <div className="invalid-feedback">{errors.projectPayments}</div>}
                        </div>
                        <div className="form-group">
                            <label>Ownership of Work:</label>
                            <textarea
                                className={`form-control ${errors.ownershipOfWork ? 'is-invalid' : ''}`}
                                value={formData.freelancing?.ownershipOfWork || ''}
                                onChange={(e) => handleInputChange('freelancing', 'ownershipOfWork', e.target.value)}
                                placeholder="Clarify ownership of deliverables"
                            ></textarea>
                            {errors.ownershipOfWork && <div className="invalid-feedback">{errors.ownershipOfWork}</div>}
                        </div>
                        <div className="form-group">
                            <label>Revisions and Edits:</label>
                            <textarea
                                className={`form-control ${errors.revisions ? 'is-invalid' : ''}`}
                                value={formData.freelancing?.revisions || ''}
                                onChange={(e) => handleInputChange('freelancing', 'revisions', e.target.value)}
                                placeholder="Specify terms for revisions"
                            ></textarea>
                            {errors.revisions && <div className="invalid-feedback">{errors.revisions}</div>}
                        </div>
                    </>
                )}

                {formData.serviceType === 'General Services' && (
                    <>
                        <h4 className="mt-4">6. General Services Specific Terms</h4>
                        <div className="form-group">
                            <label>Service Location:</label>
                            <input
                                type="text"
                                className={`form-control ${errors.generalServiceLocation ? 'is-invalid' : ''}`}
                                value={formData.generalServices?.serviceLocation || ''}
                                onChange={(e) => handleInputChange('generalServices', 'serviceLocation', e.target.value)}
                                placeholder="Enter where services will be delivered"
                            />
                            {errors.generalServiceLocation && <div className="invalid-feedback">{errors.generalServiceLocation}</div>}
                        </div>
                        <div className="form-group">
                            <label>Supplies and Materials:</label>
                            <textarea
                                className={`form-control ${errors.suppliesMaterials ? 'is-invalid' : ''}`}
                                value={formData.generalServices?.suppliesMaterials || ''}
                                onChange={(e) => handleInputChange('generalServices', 'suppliesMaterials', e.target.value)}
                                placeholder="Specify who provides supplies or materials"
                            ></textarea>
                            {errors.suppliesMaterials && <div className="invalid-feedback">{errors.suppliesMaterials}</div>}
                        </div>
                    </>
                )}

                {formData.serviceType === 'IT/Software' && (
                    <>
                        <h4 className="mt-4">6. IT/Software Specific Terms</h4>
                        <div className="form-group">
                            <label>Service Level Agreement (SLA):</label>
                            <textarea
                                className={`form-control ${errors.sla ? 'is-invalid' : ''}`}
                                value={formData.itSoftware?.sla || ''}
                                onChange={(e) => handleInputChange('itSoftware', 'sla', e.target.value)}
                                placeholder="Define expected performance levels"
                            ></textarea>
                            {errors.sla && <div className="invalid-feedback">{errors.sla}</div>}
                        </div>
                        <div className="form-group">
                            <label>Maintenance and Updates:</label>
                            <textarea
                                className={`form-control ${errors.maintenanceUpdates ? 'is-invalid' : ''}`}
                                value={formData.itSoftware?.maintenanceUpdates || ''}
                                onChange={(e) => handleInputChange('itSoftware', 'maintenanceUpdates', e.target.value)}
                                placeholder="Specify maintenance and updates details"
                            ></textarea>
                            {errors.maintenanceUpdates && <div className="invalid-feedback">{errors.maintenanceUpdates}</div>}
                        </div>
                        <div className="form-group">
                            <label>Intellectual Property:</label>
                            <textarea
                                className={`form-control ${errors.intellectualProperty ? 'is-invalid' : ''}`}
                                value={formData.itSoftware?.intellectualProperty || ''}
                                onChange={(e) => handleInputChange('itSoftware', 'intellectualProperty', e.target.value)}
                                placeholder="Clarify IP ownership"
                            ></textarea>
                            {errors.intellectualProperty && <div className="invalid-feedback">{errors.intellectualProperty}</div>}
                        </div>
                    </>
                )}

                {formData.serviceType === 'Marketing/Advertising' && (
                    <>
                        <h4 className="mt-4">6. Marketing/Advertising Specific Terms</h4>
                        <div className="form-group">
                            <label>Campaign Details:</label>
                            <textarea
                                className={`form-control ${errors.campaignDetails ? 'is-invalid' : ''}`}
                                value={formData.marketing?.campaignDetails || ''}
                                onChange={(e) => handleInputChange('marketing', 'campaignDetails', e.target.value)}
                                placeholder="Detail the specific marketing campaigns"
                            ></textarea>
                            {errors.campaignDetails && <div className="invalid-feedback">{errors.campaignDetails}</div>}
                        </div>
                        <div className="form-group">
                            <label>Target Goals/Performance Metrics:</label>
                            <textarea
                                className={`form-control ${errors.performanceMetrics ? 'is-invalid' : ''}`}
                                value={formData.marketing?.performanceMetrics || ''}
                                onChange={(e) => handleInputChange('marketing', 'performanceMetrics', e.target.value)}
                                placeholder="Define KPIs or goals"
                            ></textarea>
                            {errors.performanceMetrics && <div className="invalid-feedback">{errors.performanceMetrics}</div>}
                        </div>
                        <div className="form-group">
                            <label>Content Ownership:</label>
                            <textarea
                                className={`form-control ${errors.contentOwnership ? 'is-invalid' : ''}`}
                                value={formData.marketing?.contentOwnership || ''}
                                onChange={(e) => handleInputChange('marketing', 'contentOwnership', e.target.value)}
                                placeholder="Define ownership of creative assets"
                            ></textarea>
                            {errors.contentOwnership && <div className="invalid-feedback">{errors.contentOwnership}</div>}
                        </div>
                    </>
                )}

                {/* 7. Termination of Agreement */}
                <h4 className="mt-4">7. Termination of Agreement</h4>
                <div className="form-group">
                    <label>Termination Conditions:</label>
                    <textarea
                        className="form-control"
                        value={formData.termination?.conditions || ''}
                        onChange={(e) => handleInputChange('termination', 'conditions', e.target.value)}
                        placeholder="Define conditions for termination"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Termination Notice Period:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.termination?.noticePeriod || ''}
                        onChange={(e) => handleInputChange('termination', 'noticePeriod', e.target.value)}
                        placeholder="Enter notice period (e.g., 30 days)"
                    />
                </div>
                <div className="form-group">
                    <label>Refund or Payment on Termination:</label>
                    <textarea
                        className="form-control"
                        value={formData.termination?.refundPayment || ''}
                        onChange={(e) => handleInputChange('termination', 'refundPayment', e.target.value)}
                        placeholder="Specify refund or payment terms upon termination"
                    ></textarea>
                </div>

                {/* 8. Dispute Resolution */}
                <h4 className="mt-4">8. Dispute Resolution</h4>
                <div className="form-group">
                    <label>Governing Law:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.governingLaw ? 'is-invalid' : ''}`}
                        value={formData.disputeResolution?.governingLaw || 'Pakistan'}
                        onChange={(e) => handleInputChange('disputeResolution', 'governingLaw', e.target.value)}
                    />
                    {errors.governingLaw && <div className="invalid-feedback">{errors.governingLaw}</div>}
                </div>
                <div className="form-group">
                    <label>Dispute Resolution Method:</label>
                    <select
                        className={`form-control ${errors.disputeMethod ? 'is-invalid' : ''}`}
                        value={formData.disputeResolution?.disputeMethod || ''}
                        onChange={(e) => handleInputChange('disputeResolution', 'disputeMethod', e.target.value)}
                    >
                        <option value="">Select Method</option>
                        <option value="Arbitration">Arbitration</option>
                        <option value="Mediation">Mediation</option>
                        <option value="Court Litigation">Court Litigation</option>
                    </select>
                    {errors.disputeMethod && <div className="invalid-feedback">{errors.disputeMethod}</div>}
                </div>
                <div className="form-group">
                    <label>Jurisdiction:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.jurisdiction ? 'is-invalid' : ''}`}
                        value={formData.disputeResolution?.jurisdiction || ''}
                        onChange={(e) => handleInputChange('disputeResolution', 'jurisdiction', e.target.value)}
                        placeholder="Enter jurisdiction (e.g., Lahore, Karachi)"
                    />
                    {errors.jurisdiction && <div className="invalid-feedback">{errors.jurisdiction}</div>}
                </div>

                {/* 9. Liability and Indemnification */}
                <h4 className="mt-4">9. Liability and Indemnification</h4>
                <div className="form-group">
                    <label>Limitation of Liability:</label>
                    <textarea
                        className="form-control"
                        value={formData.liability?.limitation || ''}
                        onChange={(e) => handleInputChange('liability', 'limitation', e.target.value)}
                        placeholder="Specify limitation of liability"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Indemnification Clause:</label>
                    <textarea
                        className="form-control"
                        value={formData.liability?.indemnification || ''}
                        onChange={(e) => handleInputChange('liability', 'indemnification', e.target.value)}
                        placeholder="Specify indemnification details"
                    ></textarea>
                </div>

                {/* 10. Force Majeure Clause */}
                <h4 className="mt-4">10. Force Majeure Clause</h4>
                <div className="form-group">
                    <label>Events Considered Force Majeure:</label>
                    <textarea
                        className="form-control"
                        value={formData.forceMajeure?.events || ''}
                        onChange={(e) => handleInputChange('forceMajeure', 'events', e.target.value)}
                        placeholder="Define events that will excuse performance"
                    ></textarea>
                </div>

                {/* 11. Signatures and Witnesses */}
                <h4 className="mt-4">11. Signatures and Witnesses</h4>
                <div className="border rounded p-3 mb-3">
                    <h5>Service Provider's Signature</h5>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.serviceProviderName || ''}
                            onChange={(e) => handleInputChange('signatures', 'serviceProviderName', e.target.value)}
                            placeholder="Enter Service Provider's name"
                        />
                    </div>
                    <div className="form-group">
                        <label>Date:</label>
                        <input
                            type="date"
                            className="form-control"
                            value={formData.signatures?.serviceProviderDate || ''}
                            onChange={(e) => handleInputChange('signatures', 'serviceProviderDate', e.target.value)}
                        />
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Client's Signature</h5>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.clientName || ''}
                            onChange={(e) => handleInputChange('signatures', 'clientName', e.target.value)}
                            placeholder="Enter Client's name"
                        />
                    </div>
                    <div className="form-group">
                        <label>Date:</label>
                        <input
                            type="date"
                            className="form-control"
                            value={formData.signatures?.clientDate || ''}
                            onChange={(e) => handleInputChange('signatures', 'clientDate', e.target.value)}
                        />
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Witness 1</h5>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.witness1Name || ''}
                            onChange={(e) => handleInputChange('signatures', 'witness1Name', e.target.value)}
                            placeholder="Enter Witness 1's name"
                        />
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.witness1Address || ''}
                            onChange={(e) => handleInputChange('signatures', 'witness1Address', e.target.value)}
                            placeholder="Enter Witness 1's address"
                        />
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Witness 2</h5>
                    <div className="form-group">
                        <label>Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.witness2Name || ''}
                            onChange={(e) => handleInputChange('signatures', 'witness2Name', e.target.value)}
                            placeholder="Enter Witness 2's name"
                        />
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={formData.signatures?.witness2Address || ''}
                            onChange={(e) => handleInputChange('signatures', 'witness2Address', e.target.value)}
                            placeholder="Enter Witness 2's address"
                        />
                    </div>
                </div>

                {/* Submit Button */}
                {/* <button type="submit" className="btn btn-primary">Next</button> */}
                <button
                    type="submit"
                    className="btn"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>
            </form>
        </div>
    );
}

export default ServiceAgreementForm;
