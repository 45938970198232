import React, { useState, useEffect } from 'react';

function PartnershipDeedForm({formData, setFormData, onSubmit}) {
    // A single state that combines all the form sections

    const [errors, setErrors] = useState({}); // Store individual field errors here

    const addPartner = () => {
        setFormData(prev => ({
            ...prev,
            partners: [...prev.partners, { fullName: "", idNumber: "", address: "", role: "" }]
        }));
    };

    const removePartner = (index) => {
        if (formData.partners.length > 2) {
            const newPartners = formData.partners.filter((_, idx) => idx !== index);
            setFormData(prev => ({ ...prev, partners: newPartners }));
        }
    };

    const handlePartnerChange = (index, field, value) => {
        const newPartners = formData.partners.map((partner, idx) =>
            idx === index ? { ...partner, [field]: value } : partner
        );
        setFormData(prev => ({ ...prev, partners: newPartners }));
    };

    const handleInputChange = (section, field, value) => {
        setFormData(prev => ({
            ...prev,
            [section]: { ...prev[section], [field]: value }
        }));
    };

    const handleFinancialChange = (index, field, value, type) => {
        setFormData(prev => ({
            ...prev,
            financials: {
                ...prev.financials,
                [type]: { ...prev.financials[type], [index]: value }
            }
        }));
    };

    const handleResponsibilitiesChange = (index, value) => {
        setFormData(prev => ({
            ...prev,
            responsibilities: { ...prev.responsibilities, [index]: value }
        }));
    };

    // Form validation function (adjust this for combined state)
    const validateForm = () => {
        let formErrors = {};

        // Validate partner information
        formData.partners.forEach((partner, index) => {
            if (!partner.fullName) formErrors[`partner-${index}-fullName`] = "Full name is required";
            if (!partner.idNumber) formErrors[`partner-${index}-idNumber`] = "ID number is required";
            if (!partner.address) formErrors[`partner-${index}-address`] = "Address is required";
            if (!partner.role) formErrors[`partner-${index}-role`] = "Role is required";
        });

        // Validate business information
        if (!formData.businessInfo.businessName) formErrors.businessName = "Business name is required";
        if (!formData.businessInfo.businessNature) formErrors.businessNature = "Business nature is required";
        if (!formData.businessInfo.businessAddress) formErrors.businessAddress = "Business address is required";

        // Validate financial contributions and profit sharing
        formData.partners.forEach((partner, index) => {
            if (!formData.financials.capitalContributions[index]) formErrors[`capitalContributions-${index}`] = `Capital contribution for Partner ${index + 1} is required`;
            if (!formData.financials.profitSharingRatios[index]) formErrors[`profitSharingRatios-${index}`] = `Profit sharing ratio for Partner ${index + 1} is required`;
        });

        // Validate banking information
        if (!formData.bankingInfo.fiscalYearStart) formErrors.fiscalYearStart = "Fiscal year start date is required";
        if (!formData.bankingInfo.fiscalYearEnd) formErrors.fiscalYearEnd = "Fiscal year end date is required";
        else if (formData.bankingInfo.fiscalYearStart && formData.bankingInfo.fiscalYearEnd) {
            const start = new Date(formData.bankingInfo.fiscalYearStart);
            const end = new Date(formData.bankingInfo.fiscalYearEnd);
            if (end <= start) {
                formErrors.fiscalYearEnd = "Fiscal year end date must be after the start date";
            }
        }

        // Validate legal provisions
        if (!formData.legalProvisions.disputeResolutionMethod) formErrors.disputeResolutionMethod = "Dispute resolution method is required";
        if (!formData.legalProvisions.jurisdiction) formErrors.jurisdiction = "Jurisdiction is required";

        setErrors(formErrors);

        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Form data to submit:", formData);
            setFormData(formData);
            onSubmit();
        }
    };

    // Automatically hide errors after 5 seconds
    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            const timer = setTimeout(() => setErrors({}), 5000);
            return () => clearTimeout(timer);
        }
    }, [errors]);

    return (
        <div className="mb-2">
            <h2 className="text-center">Partnership Deed Form</h2>

            <form onSubmit={handleSubmit}>
                {errors.form && <div className="alert alert-danger">{errors.form}</div>}

                {/* Partner Information */}
                <h4 className="mt-4">1. Partner Information</h4>
                {formData.partners.map((partner, index) => (
                    <div key={index} className="border rounded p-3 mb-3">
                        <h5>Partner {index + 1}</h5>
                        <div className="form-group">
                            <label>Full Name:</label>
                            <input
                                type="text"
                                className={`form-control ${errors[`partner-${index}-fullName`] ? 'is-invalid' : ''}`}
                                value={partner.fullName}
                                onChange={(e) => handlePartnerChange(index, 'fullName', e.target.value)}
                                placeholder={`Enter Partner ${index + 1} Name`}
                            />
                            {errors[`partner-${index}-fullName`] && (
                                <div className="invalid-feedback">{errors[`partner-${index}-fullName`]}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label>ID/Identification Number:</label>
                            <input
                                type="text"
                                className={`form-control ${errors[`partner-${index}-idNumber`] ? 'is-invalid' : ''}`}
                                value={partner.idNumber}
                                onChange={(e) => handlePartnerChange(index, 'idNumber', e.target.value)}
                                placeholder="Enter ID Number"
                            />
                            {errors[`partner-${index}-idNumber`] && (
                                <div className="invalid-feedback">{errors[`partner-${index}-idNumber`]}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label>Address:</label>
                            <input
                                type="text"
                                className={`form-control ${errors[`partner-${index}-address`] ? 'is-invalid' : ''}`}
                                value={partner.address}
                                onChange={(e) => handlePartnerChange(index, 'address', e.target.value)}
                                placeholder="Enter Address"
                            />
                            {errors[`partner-${index}-address`] && (
                                <div className="invalid-feedback">{errors[`partner-${index}-address`]}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label>Role (Managing Partner, etc.):</label>
                            <input
                                type="text"
                                className={`form-control ${errors[`partner-${index}-role`] ? 'is-invalid' : ''}`}
                                value={partner.role}
                                onChange={(e) => handlePartnerChange(index, 'role', e.target.value)}
                                placeholder="Enter Role"
                            />
                            {errors[`partner-${index}-role`] && (
                                <div className="invalid-feedback">{errors[`partner-${index}-role`]}</div>
                            )}
                        </div>
                        {formData.partners.length > 2 && (
                            <button type="button" className="btn btn-danger" onClick={() => removePartner(index)}>
                                Remove Partner
                            </button>
                        )}
                    </div>
                ))}
                <button type="button" className="btn btn-link" onClick={addPartner}>
                    Add another Partner
                </button>

                {/* Business Information */}
                <h4 className="mt-4">2. Business Information</h4>
                <div className="form-group">
                    <label>Business Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.businessName ? 'is-invalid' : ''}`}
                        value={formData.businessInfo.businessName}
                        onChange={(e) => handleInputChange('businessInfo', 'businessName', e.target.value)}
                        placeholder="Enter Business Name"
                    />
                    {errors.businessName && <div className="invalid-feedback">{errors.businessName}</div>}
                </div>
                <div className="form-group">
                    <label>Business Nature:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.businessNature ? 'is-invalid' : ''}`}
                        value={formData.businessInfo.businessNature}
                        onChange={(e) => handleInputChange('businessInfo', 'businessNature', e.target.value)}
                        placeholder="Describe Business Nature"
                    />
                    {errors.businessNature && <div className="invalid-feedback">{errors.businessNature}</div>}
                </div>
                <div className="form-group">
                    <label>Business Address:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.businessAddress ? 'is-invalid' : ''}`}
                        value={formData.businessInfo.businessAddress}
                        onChange={(e) => handleInputChange('businessInfo', 'businessAddress', e.target.value)}
                        placeholder="Enter Business Address"
                    />
                    {errors.businessAddress && <div className="invalid-feedback">{errors.businessAddress}</div>}
                </div>

                {/* Financial Contributions */}
                <h4 className="mt-4">3. Financial Contributions</h4>
                {formData.partners.map((partner, index) => (
                    <div key={index} className="form-group">
                        <label>Partner {index + 1} Contribution (with Currency):</label>
                        <input
                            type="text"
                            className={`form-control ${errors[`capitalContributions-${index}`] ? 'is-invalid' : ''}`}
                            value={formData.financials.capitalContributions[index] || ""}
                            onChange={(e) =>
                                handleFinancialChange(index, 'capitalContributions', e.target.value, 'capitalContributions')
                            }
                            placeholder={`Enter Capital Contribution for Partner ${index + 1}`}
                        />
                        {errors[`capitalContributions-${index}`] && (
                            <div className="invalid-feedback">{errors[`capitalContributions-${index}`]}</div>
                        )}
                    </div>
                ))}
                {formData.partners.map((partner, index) => (
                    <div key={index} className="form-group">
                        <label>Partner {index + 1} Profit Sharing Ratio (%):</label>
                        <input
                            type="number"
                            className={`form-control ${errors[`profitSharingRatios-${index}`] ? 'is-invalid' : ''}`}
                            value={formData.financials.profitSharingRatios[index] || ""}
                            onChange={(e) =>
                                handleFinancialChange(index, 'profitSharingRatios', e.target.value, 'profitSharingRatios')
                            }
                            placeholder={`Enter Profit Sharing Ratio for Partner ${index + 1}`}
                        />
                        {errors[`profitSharingRatios-${index}`] && (
                            <div className="invalid-feedback">{errors[`profitSharingRatios-${index}`]}</div>
                        )}
                    </div>
                ))}

                {/* Banking and Financial Arrangements */}
                <h4 className="mt-4">4. Banking and Financial Arrangements</h4>
                <div className="form-group">
                    <label>Joint Signatory Required?</label>
                    <select
                        className="form-control"
                        value={formData.bankingInfo.jointSignatory}
                        onChange={(e) => handleInputChange('bankingInfo', 'jointSignatory', e.target.value === "true")}
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Other Banking Instructions:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.bankingInfo.bankInstructions}
                        onChange={(e) => handleInputChange('bankingInfo', 'bankInstructions', e.target.value)}
                        placeholder="Enter Other Banking Instructions"
                    />
                </div>
                <div className="form-group">
                    <label>Fiscal Year Start Date:</label>
                    <input
                        type="date"
                        className={`form-control ${errors.fiscalYearStart ? 'is-invalid' : ''}`}
                        value={formData.bankingInfo.fiscalYearStart}
                        onChange={(e) => handleInputChange('bankingInfo', 'fiscalYearStart', e.target.value)}
                    />
                    {errors.fiscalYearStart && <div className="invalid-feedback">{errors.fiscalYearStart}</div>}
                </div>
                <div className="form-group">
                    <label>Fiscal Year End Date:</label>
                    <input
                        type="date"
                        className={`form-control ${errors.fiscalYearEnd ? 'is-invalid' : ''}`}
                        value={formData.bankingInfo.fiscalYearEnd}
                        onChange={(e) => handleInputChange('bankingInfo', 'fiscalYearEnd', e.target.value)}
                    />
                    {errors.fiscalYearEnd && <div className="invalid-feedback">{errors.fiscalYearEnd}</div>}
                </div>

                {/* Role and Responsibilities */}
                <h4 className="mt-4">5. Role and Responsibilities</h4>
                {formData.partners.map((partner, index) => (
                    <div key={index} className="form-group">
                        <label>Partner {index + 1} Responsibilities:</label>
                        <textarea
                            className="form-control"
                            value={formData.responsibilities[index] || ""}
                            onChange={(e) => handleResponsibilitiesChange(index, e.target.value)}
                            placeholder={`Enter Responsibilities for Partner ${index + 1}`}
                        ></textarea>
                    </div>
                ))}
                {/* Dispute Resolution and Legal Provisions */}
                <h4 className="mt-4">6. Dispute Resolution and Legal Provisions</h4>
                <div className="form-group">
                    <label>Dispute Resolution Method:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.disputeResolutionMethod ? 'is-invalid' : ''}`}
                        value={formData.legalProvisions.disputeResolutionMethod}
                        onChange={(e) => handleInputChange('legalProvisions', 'disputeResolutionMethod', e.target.value)}
                        placeholder="Enter Dispute Resolution Method"
                    />
                    {errors.disputeResolutionMethod && (
                        <div className="invalid-feedback">{errors.disputeResolutionMethod}</div>
                    )}
                </div>
                <div className="form-group">
                    <label>Jurisdiction:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.jurisdiction ? 'is-invalid' : ''}`}
                        value={formData.legalProvisions.jurisdiction}
                        onChange={(e) => handleInputChange('legalProvisions', 'jurisdiction', e.target.value)}
                        placeholder="Enter Jurisdiction"
                    />
                    {errors.jurisdiction && <div className="invalid-feedback">{errors.jurisdiction}</div>}
                </div>

                {/* Success and Exit Clauses */}
                <h4 className="mt-4">7. Success and Exit Clauses</h4>
                <div className="form-group">
                    <label>Transfer Shares to Legal Heirs?</label>
                    <select
                        className="form-control"
                        value={formData.legalProvisions.transferSharesToHeirs}
                        onChange={(e) => handleInputChange('legalProvisions', 'transferSharesToHeirs', e.target.value === "true")}
                    >
                        <option value="false">No</option>
                        <option value="true">Yes</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Share Transfer Restrictions:</label>
                    <textarea
                        className="form-control"
                        value={formData.legalProvisions.shareTransferRestrictions}
                        onChange={(e) => handleInputChange('legalProvisions', 'shareTransferRestrictions', e.target.value)}
                        placeholder="Define Share Transfer Restrictions"
                    ></textarea>
                </div>

                {/* Submit */}
                {/* <button type="submit" className="btn btn-primary mt-4">Next</button> */}
                <button
                    type="submit"
                    className="btn mt-4"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>
            </form>
        </div>
    );
}

export default PartnershipDeedForm;
