import React, { createContext, useState } from 'react';

// Create the context for managing history items
export const HistoryContext = createContext();

// Provider component to wrap around parts of the app that need access to the history state
export const HistoryProvider = ({ children }) => {
  const [historyItems, setHistoryItems] = useState([]); // Store all history items
  const [selectedHistoryItemId, setSelectedHistoryItemId] = useState(null); // Store the selected item ID

  return (
    <HistoryContext.Provider
      value={{ historyItems, setHistoryItems, selectedHistoryItemId, setSelectedHistoryItemId }}
    >
      {children}
    </HistoryContext.Provider>
  );
};
