import React, { useState, useEffect } from 'react';

function SettlementAgreementForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});

    const handleInputChange = (section, field, value) => {
        // Handle nested fields
        if (field.includes('.')) {
            const [nestedSection, nestedField] = field.split('.'); // e.g. 'witness1.name'
            setFormData(prev => ({
                ...prev,
                [section]: {
                    ...prev[section],
                    [nestedSection]: {
                        ...prev[section][nestedSection],
                        [nestedField]: value
                    }
                }
            }));
        } else {
            // Handle non-nested fields
            setFormData(prev => ({
                ...prev,
                [section]: { ...prev[section], [field]: value }
            }));
        }
    };
    
    const validateForm = () => {
        let formErrors = {};

        // Validate Party 1 information
        if (!formData.party1.fullName) formErrors.party1FullName = "Full name or business name is required for Party 1";
        if (!formData.party1.cnic) formErrors.party1Cnic = "CNIC or registration number is required for Party 1";
        if (!formData.party1.address) formErrors.party1Address = "Address is required for Party 1";
        if (!formData.party1.contact) formErrors.party1Contact = "Contact information is required for Party 1";

        // Validate Party 2 information
        if (!formData.party2.fullName) formErrors.party2FullName = "Full name or business name is required for Party 2";
        if (!formData.party2.cnic) formErrors.party2Cnic = "CNIC or registration number is required for Party 2";
        if (!formData.party2.address) formErrors.party2Address = "Address is required for Party 2";
        if (!formData.party2.contact) formErrors.party2Contact = "Contact information is required for Party 2";

        // Validate Agreement details
        if (!formData.agreement.effectiveDate) formErrors.effectiveDate = "Effective date is required";
        if (!formData.agreement.purpose) formErrors.purpose = "Settlement purpose is required";
        if (!formData.agreement.jurisdiction) formErrors.jurisdiction = "Jurisdiction is required";

        // Validate Financial terms
        if (!formData.financial.totalAmountDue) formErrors.totalAmountDue = "Total amount due is required";
        if (!formData.financial.currency) formErrors.currency = "Currency is required";
        if (!formData.financial.paymentSchedule) formErrors.paymentSchedule = "Payment schedule is required";

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Form data to submit:", formData);
            setFormData(formData);
            onSubmit();
        }
    };

    return (
        <div className="mb-2">
            <h2 className="text-center">Settlement Agreement Form</h2>
            <form onSubmit={handleSubmit}>
                {/* Parties Involved */}
                <h4 className="mt-4">1. Parties Involved</h4>
                <div className="border rounded p-3 mb-3">
                    <h5>Party 1 (Creditor/Settler)</h5>
                    <div className="form-group">
                        <label>Full Name or Business Name:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.party1FullName ? 'is-invalid' : ''}`}
                            value={formData.party1.fullName}
                            onChange={(e) => handleInputChange('party1', 'fullName', e.target.value)}
                            placeholder="Enter full name or business name"
                        />
                        {errors.party1FullName && <div className="invalid-feedback">{errors.party1FullName}</div>}
                    </div>
                    <div className="form-group">
                        <label>CNIC/Company Registration Number:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.party1Cnic ? 'is-invalid' : ''}`}
                            value={formData.party1.cnic}
                            onChange={(e) => handleInputChange('party1', 'cnic', e.target.value)}
                            placeholder="Enter CNIC or registration number"
                        />
                        {errors.party1Cnic && <div className="invalid-feedback">{errors.party1Cnic}</div>}
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.party1Address ? 'is-invalid' : ''}`}
                            value={formData.party1.address}
                            onChange={(e) => handleInputChange('party1', 'address', e.target.value)}
                            placeholder="Enter address"
                        />
                        {errors.party1Address && <div className="invalid-feedback">{errors.party1Address}</div>}
                    </div>
                    <div className="form-group">
                        <label>Contact Information:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.party1Contact ? 'is-invalid' : ''}`}
                            value={formData.party1.contact}
                            onChange={(e) => handleInputChange('party1', 'contact', e.target.value)}
                            placeholder="Enter contact information"
                        />
                        {errors.party1Contact && <div className="invalid-feedback">{errors.party1Contact}</div>}
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Party 2 (Debtor/Settlee)</h5>
                    <div className="form-group">
                        <label>Full Name or Business Name:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.party2FullName ? 'is-invalid' : ''}`}
                            value={formData.party2.fullName}
                            onChange={(e) => handleInputChange('party2', 'fullName', e.target.value)}
                            placeholder="Enter full name or business name"
                        />
                        {errors.party2FullName && <div className="invalid-feedback">{errors.party2FullName}</div>}
                    </div>
                    <div className="form-group">
                        <label>CNIC/Company Registration Number:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.party2Cnic ? 'is-invalid' : ''}`}
                            value={formData.party2.cnic}
                            onChange={(e) => handleInputChange('party2', 'cnic', e.target.value)}
                            placeholder="Enter CNIC or registration number"
                        />
                        {errors.party2Cnic && <div className="invalid-feedback">{errors.party2Cnic}</div>}
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.party2Address ? 'is-invalid' : ''}`}
                            value={formData.party2.address}
                            onChange={(e) => handleInputChange('party2', 'address', e.target.value)}
                            placeholder="Enter address"
                        />
                        {errors.party2Address && <div className="invalid-feedback">{errors.party2Address}</div>}
                    </div>
                    <div className="form-group">
                        <label>Contact Information:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.party2Contact ? 'is-invalid' : ''}`}
                            value={formData.party2.contact}
                            onChange={(e) => handleInputChange('party2', 'contact', e.target.value)}
                            placeholder="Enter contact information"
                        />
                        {errors.party2Contact && <div className="invalid-feedback">{errors.party2Contact}</div>}
                    </div>
                </div>

                {/* Agreement Details */}
                <h4 className="mt-4">2. Agreement Details</h4>
                <div className="form-group">
                    <label>Effective Date:</label>
                    <input
                        type="date"
                        className={`form-control ${errors.effectiveDate ? 'is-invalid' : ''}`}
                        value={formData.agreement.effectiveDate}
                        onChange={(e) => handleInputChange('agreement', 'effectiveDate', e.target.value)}
                    />
                    {errors.effectiveDate && <div className="invalid-feedback">{errors.effectiveDate}</div>}
                </div>
                <div className="form-group">
                    <label>Settlement Purpose:</label>
                    <textarea
                        className={`form-control ${errors.purpose ? 'is-invalid' : ''}`}
                        value={formData.agreement.purpose}
                        onChange={(e) => handleInputChange('agreement', 'purpose', e.target.value)}
                        placeholder="Describe the purpose of the settlement"
                    ></textarea>
                    {errors.purpose && <div className="invalid-feedback">{errors.purpose}</div>}
                </div>
                <div className="form-group">
                    <label>Jurisdiction:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.jurisdiction ? 'is-invalid' : ''}`}
                        value={formData.agreement.jurisdiction}
                        onChange={(e) => handleInputChange('agreement', 'jurisdiction', e.target.value)}
                        placeholder="Enter jurisdiction"
                    />
                    {errors.jurisdiction && <div className="invalid-feedback">{errors.jurisdiction}</div>}
                </div>

                {/* Financial Settlement Terms */}
                <h4 className="mt-4">3. Financial Settlement Terms</h4>
                <div className="form-group">
                    <label>Total Amount Due:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.totalAmountDue ? 'is-invalid' : ''}`}
                        value={formData.financial.totalAmountDue}
                        onChange={(e) => handleInputChange('financial', 'totalAmountDue', e.target.value)}
                        placeholder="Enter total amount due"
                    />
                    {errors.totalAmountDue && <div className="invalid-feedback">{errors.totalAmountDue}</div>}
                </div>
                <div className="form-group">
                    <label>Currency:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.currency ? 'is-invalid' : ''}`}
                        value={formData.financial.currency}
                        onChange={(e) => handleInputChange('financial', 'currency', e.target.value)}
                        placeholder="Enter currency (e.g., PKR, USD)"
                    />
                    {errors.currency && <div className="invalid-feedback">{errors.currency}</div>}
                </div>
                <div className="form-group">
                    <label>Payment Schedule:</label>
                    <textarea
                        className={`form-control ${errors.paymentSchedule ? 'is-invalid' : ''}`}
                        value={formData.financial.paymentSchedule}
                        onChange={(e) => handleInputChange('financial', 'paymentSchedule', e.target.value)}
                        placeholder="Enter payment schedule"
                    ></textarea>
                    {errors.paymentSchedule && <div className="invalid-feedback">{errors.paymentSchedule}</div>}
                </div>

                {/* Ownership and Asset Transfer */}
                <h4 className="mt-4">4. Ownership and Asset Transfer (if applicable)</h4>
                <div className="form-group">
                    <label>Items to Be Transferred:</label>
                    <textarea
                        className="form-control"
                        value={formData.ownershipTransfer.itemsToTransfer}
                        onChange={(e) => handleInputChange('ownershipTransfer', 'itemsToTransfer', e.target.value)}
                        placeholder="Describe items to be transferred"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Transfer of Ownership Date:</label>
                    <input
                        type="date"
                        className="form-control"
                        value={formData.ownershipTransfer.transferDate}
                        onChange={(e) => handleInputChange('ownershipTransfer', 'transferDate', e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Conditions for Transfer:</label>
                    <textarea
                        className="form-control"
                        value={formData.ownershipTransfer.transferConditions}
                        onChange={(e) => handleInputChange('ownershipTransfer', 'transferConditions', e.target.value)}
                        placeholder="Enter conditions for transfer"
                    ></textarea>
                </div>

                {/* Dispute Resolution */}
                <h4 className="mt-4">5. Breach of Agreement and Dispute Resolution</h4>
                <div className="form-group">
                    <label>Dispute Resolution Method:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.disputeResolutionMethod ? 'is-invalid' : ''}`}
                        value={formData.disputeResolution.method}
                        onChange={(e) => handleInputChange('disputeResolution', 'method', e.target.value)}
                        placeholder="Enter dispute resolution method"
                    />
                    {errors.disputeResolutionMethod && <div className="invalid-feedback">{errors.disputeResolutionMethod}</div>}
                </div>
                <div className="form-group">
                    <label>Arbitration Clause:</label>
                    <textarea
                        className={`form-control ${errors.arbitrationClause ? 'is-invalid' : ''}`}
                        value={formData.disputeResolution.arbitrationClause}
                        onChange={(e) => handleInputChange('disputeResolution', 'arbitrationClause', e.target.value)}
                        placeholder="Enter arbitration clause"
                    ></textarea>
                    {errors.arbitrationClause && <div className="invalid-feedback">{errors.arbitrationClause}</div>}
                </div>

                {/* Confidentiality and Other Clauses */}
                <h4 className="mt-4">6. Confidentiality and Other Clauses</h4>
                <div className="form-group">
                    <label>Confidentiality Clause:</label>
                    <textarea
                        className="form-control"
                        value={formData.confidentiality.confidentialityClause}
                        onChange={(e) => handleInputChange('confidentiality', 'confidentialityClause', e.target.value)}
                        placeholder="Enter confidentiality clause"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Force Majeure:</label>
                    <textarea
                        className="form-control"
                        value={formData.confidentiality.forceMajeure}
                        onChange={(e) => handleInputChange('confidentiality', 'forceMajeure', e.target.value)}
                        placeholder="Enter force majeure clause"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Governing Law:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.confidentiality.governingLaw}
                        onChange={(e) => handleInputChange('confidentiality', 'governingLaw', e.target.value)}
                        placeholder="Enter governing law"
                    />
                </div>

                {/* Signatures */}
                <h4 className="mt-4">7. Witnesses</h4>
                <div className="form-group">
                    <label>Witness 1 Name:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.signatures.witness1.name}
                        onChange={(e) => handleInputChange('signatures', 'witness1.name', e.target.value)}
                        placeholder="Witness 1 name"
                    />
                </div>
                <div className="form-group">
                    <label>Witness 1 Address:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.signatures.witness1.address}
                        onChange={(e) => handleInputChange('signatures', 'witness1.address', e.target.value)}
                        placeholder="Witness 1 address"
                    />
                </div>
                <div className="form-group">
                    <label>Witness 2 Name:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.signatures.witness2.name}
                        onChange={(e) => handleInputChange('signatures', 'witness2.name', e.target.value)}
                        placeholder="Witness 2 name"
                    />
                </div>
                <div className="form-group">
                    <label>Witness 2 Address:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.signatures.witness2.address}
                        onChange={(e) => handleInputChange('signatures', 'witness2.address', e.target.value)}
                        placeholder="Witness 2 address"
                    />
                </div>

                {/* Submit */}
                {/* <button type="submit" className="btn btn-primary mt-4">Next</button> */}
                <button
                    type="submit"
                    className="btn mt-4"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>
            </form>
        </div>
    );
}

export default SettlementAgreementForm;
