import React, { useState } from 'react';

function NonDisclosureAgreementForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});

    const handleInputChange = (section, field, value) => {
        // Handle nested fields using optional chaining for safe access
        setFormData(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value
            }
        }));
    };

    const validateForm = () => {
        let formErrors = {};

        // Validate Disclosing Party
        if (!formData.disclosingParty?.fullName) formErrors.disclosingPartyFullName = "Full name or company name is required for Disclosing Party";
        if (!formData.disclosingParty?.address) formErrors.disclosingPartyAddress = "Address is required for Disclosing Party";
        if (!formData.disclosingParty?.contact) formErrors.disclosingPartyContact = "Contact information is required for Disclosing Party";

        // Validate Receiving Party
        if (!formData.receivingParty?.fullName) formErrors.receivingPartyFullName = "Full name or company name is required for Receiving Party";
        if (!formData.receivingParty?.address) formErrors.receivingPartyAddress = "Address is required for Receiving Party";
        if (!formData.receivingParty?.contact) formErrors.receivingPartyContact = "Contact information is required for Receiving Party";

        // Validate NDA Details
        if (!formData.NDADetail?.purpose) formErrors.purpose = "Purpose of agreement is required";
        if (!formData.NDADetail?.duration) formErrors.duration = "Duration of confidentiality is required";
        if (!formData.NDADetail?.governingLaw) formErrors.governingLaw = "Governing law is required";

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Form data to submit:", formData);
            setFormData(formData); // This line is redundant, as you already updated formData via handleInputChange
            onSubmit();
        }
    };

    return (
        <div className="mb-2">
            <h2 className="text-center">Non-Disclosure Agreement Form</h2>
            <form onSubmit={handleSubmit}>
                {/* Title and Parties Involved */}
                <h4 className="mt-4">1. Title and Date</h4>
                <div className="form-group">
                    <label>Title:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.NDABasicDetail?.title || ""}
                        onChange={(e) => handleInputChange('NDABasicDetail', 'title', e.target.value)}
                        placeholder="Enter title (e.g., Non-Disclosure Agreement)"
                    />
                </div>
                <div className="form-group">
                    <label>Date:</label>
                    <input
                        type="date"
                        className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                        value={formData.NDABasicDetail?.date || ""}
                        onChange={(e) => handleInputChange('NDABasicDetail', 'date', e.target.value)}
                    />
                    {errors.date && <div className="invalid-feedback">{errors.date}</div>}
                </div>

                <h4 className="mt-4">2. Parties Involved</h4>
                <div className="border rounded p-3 mb-3">
                    <h5>Disclosing Party (Grantor)</h5>
                    <div className="form-group">
                        <label>Full Name or Company Name:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.disclosingPartyFullName ? 'is-invalid' : ''}`}
                            value={formData.disclosingParty?.fullName || ""}
                            onChange={(e) => handleInputChange('disclosingParty', 'fullName', e.target.value)}
                            placeholder="Enter Disclosing Party's full name or company name"
                        />
                        {errors.disclosingPartyFullName && <div className="invalid-feedback">{errors.disclosingPartyFullName}</div>}
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.disclosingPartyAddress ? 'is-invalid' : ''}`}
                            value={formData.disclosingParty?.address || ""}
                            onChange={(e) => handleInputChange('disclosingParty', 'address', e.target.value)}
                            placeholder="Enter address"
                        />
                        {errors.disclosingPartyAddress && <div className="invalid-feedback">{errors.disclosingPartyAddress}</div>}
                    </div>
                    <div className="form-group">
                        <label>Contact Information (Email, Phone):</label>
                        <input
                            type="text"
                            className={`form-control ${errors.disclosingPartyContact ? 'is-invalid' : ''}`}
                            value={formData.disclosingParty?.contact || ""}
                            onChange={(e) => handleInputChange('disclosingParty', 'contact', e.target.value)}
                            placeholder="Enter contact information"
                        />
                        {errors.disclosingPartyContact && <div className="invalid-feedback">{errors.disclosingPartyContact}</div>}
                    </div>
                </div>

                <div className="border rounded p-3 mb-3">
                    <h5>Receiving Party (Recipient)</h5>
                    <div className="form-group">
                        <label>Full Name or Company Name:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.receivingPartyFullName ? 'is-invalid' : ''}`}
                            value={formData.receivingParty?.fullName || ""}
                            onChange={(e) => handleInputChange('receivingParty', 'fullName', e.target.value)}
                            placeholder="Enter Receiving Party's full name or company name"
                        />
                        {errors.receivingPartyFullName && <div className="invalid-feedback">{errors.receivingPartyFullName}</div>}
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <input
                            type="text"
                            className={`form-control ${errors.receivingPartyAddress ? 'is-invalid' : ''}`}
                            value={formData.receivingParty?.address || ""}
                            onChange={(e) => handleInputChange('receivingParty', 'address', e.target.value)}
                            placeholder="Enter address"
                        />
                        {errors.receivingPartyAddress && <div className="invalid-feedback">{errors.receivingPartyAddress}</div>}
                    </div>
                    <div className="form-group">
                        <label>Contact Information (Email, Phone):</label>
                        <input
                            type="text"
                            className={`form-control ${errors.receivingPartyContact ? 'is-invalid' : ''}`}
                            value={formData.receivingParty?.contact || ""}
                            onChange={(e) => handleInputChange('receivingParty', 'contact', e.target.value)}
                            placeholder="Enter contact information"
                        />
                        {errors.receivingPartyContact && <div className="invalid-feedback">{errors.receivingPartyContact}</div>}
                    </div>
                </div>

                {/* Purpose of Agreement */}
                <h4 className="mt-4">3. Purpose of Agreement</h4>
                <div className="form-group">
                    <label>Purpose:</label>
                    <textarea
                        className={`form-control ${errors.purpose ? 'is-invalid' : ''}`}
                        value={formData.NDADetail?.purpose || ""}
                        onChange={(e) => handleInputChange('NDADetail', 'purpose', e.target.value)}
                        placeholder="Describe the purpose of the agreement"
                    ></textarea>
                    {errors.purpose && <div className="invalid-feedback">{errors.purpose}</div>}
                </div>

                {/* Definition of Confidential Information */}
                <h4 className="mt-4">4. Definition of Confidential Information</h4>
                <div className="form-group">
                    <label>Confidential Information:</label>
                    <textarea
                        className="form-control"
                        value={formData.NDADetail?.confidentialInfo || ""}
                        onChange={(e) => handleInputChange('NDADetail', 'confidentialInfo', e.target.value)}
                        placeholder="Define confidential information"
                    ></textarea>
                </div>

                {/* Obligations of the Receiving Party */}
                <h4 className="mt-4">5. Obligations of the Receiving Party</h4>
                <div className="form-group">
                    <label>Obligations:</label>
                    <textarea
                        className="form-control"
                        value={formData.NDADetail?.obligations || ""}
                        onChange={(e) => handleInputChange('NDADetail', 'obligations', e.target.value)}
                        placeholder="List the obligations of the receiving party"
                    ></textarea>
                </div>

                {/* Duration of Confidentiality */}
                <h4 className="mt-4">6. Duration of Confidentiality</h4>
                <div className="form-group">
                    <label>Duration (e.g., number of years):</label>
                    <input
                        type="text"
                        className={`form-control ${errors.duration ? 'is-invalid' : ''}`}
                        value={formData.NDADetail?.duration || ""}
                        onChange={(e) => handleInputChange('NDADetail', 'duration', e.target.value)}
                        placeholder="Enter duration of confidentiality"
                    />
                    {errors.duration && <div className="invalid-feedback">{errors.duration}</div>}
                </div>

                {/* Return or Destruction of Information */}
                <h4 className="mt-4">7. Return or Destruction of Information</h4>
                <div className="form-group">
                    <label>Return or Destruction:</label>
                    <textarea
                        className="form-control"
                        value={formData.NDADetail?.returnordestructionInfo || ""}
                        onChange={(e) => handleInputChange('NDADetail', 'returnordestructionInfo', e.target.value)}
                        placeholder="List the return or destruction of information"
                    ></textarea>
                </div>

                {/* Remedies for Breach */}
                <h4 className="mt-4">8. Remedies for Breach</h4>
                <label>Remedies:</label>
                <div className="form-group">
                    <textarea
                        className="form-control"
                        value={formData.NDADetail?.breach || ""}
                        onChange={(e) => handleInputChange('NDADetail', 'breach', e.target.value)}
                        placeholder="List the remedies for breach"
                    ></textarea>
                </div>

                {/* Governing Law */}
                <h4 className="mt-4">9. Governing Law</h4>
                <div className="form-group">
                    <label>Governing Law:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.governingLaw ? 'is-invalid' : ''}`}
                        value={formData.NDADetail?.governingLaw || ""}
                        onChange={(e) => handleInputChange('NDADetail', 'governingLaw', e.target.value)}
                        placeholder="Enter governing law (e.g., State or Country)"
                    />
                    {errors.governingLaw && <div className="invalid-feedback">{errors.governingLaw}</div>}
                </div>

                {/* Submit Button */}
                {/* <button type="submit" className="btn btn-primary">Next</button> */}
                <button
                    type="submit"
                    className="btn"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>
            </form>
        </div>
    );
}

export default NonDisclosureAgreementForm;
