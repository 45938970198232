// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LegalDrafts_bg_color__1unA2 {
    background-color: #166432;
}

.LegalDrafts_text_color__L-oGh {
    color: #C59D55;
}

.LegalDrafts_text_color__L-oGh:hover {
    color: #fff;
}

.LegalDrafts_btn_color__juNxC {
    background-color: #166432;
    color: #C59D55;
    border: none;
}

.LegalDrafts_btn_color__juNxC:hover {
    color: #fff;
    background-color: #13522A;
}

/* Remove focus outline and box-shadow from active nav-links */
.LegalDrafts_nav_tabs__7hiWT .LegalDrafts_nav_link__5Qkwu:focus,
.LegalDrafts_nav_tabs__7hiWT .LegalDrafts_nav_link__5Qkwu.LegalDrafts_active__T4uN9:focus {
  box-shadow: none;
  outline: none;
}

/* Customize active tab border */
.LegalDrafts_nav_tabs__7hiWT .LegalDrafts_nav_link__5Qkwu.LegalDrafts_active__T4uN9 {
  border-color: #dee2e6 #dee2e6 #fff; /* Adjust as needed */
  /* Optionally, remove the border completely */
  /* border: none; */
}

/* Optional: Add a subtle underline to indicate the active tab */
.LegalDrafts_nav_tabs__7hiWT .LegalDrafts_nav_link__5Qkwu.LegalDrafts_active__T4uN9 {
  border-bottom: 2px solid #007bff; /* Example: blue underline */
}

/* Optional: Provide an alternative focus style for accessibility */
.LegalDrafts_nav_tabs__7hiWT .LegalDrafts_nav_link__5Qkwu:focus {
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25); /* Example: blue glow */
}`, "",{"version":3,"sources":["webpack://./src/pages/LegalDrafts.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA,8DAA8D;AAC9D;;EAEE,gBAAgB;EAChB,aAAa;AACf;;AAEA,gCAAgC;AAChC;EACE,kCAAkC,EAAE,qBAAqB;EACzD,6CAA6C;EAC7C,kBAAkB;AACpB;;AAEA,gEAAgE;AAChE;EACE,gCAAgC,EAAE,4BAA4B;AAChE;;AAEA,mEAAmE;AACnE;EACE,6CAA6C,EAAE,uBAAuB;AACxE","sourcesContent":[".bg_color {\n    background-color: #166432;\n}\n\n.text_color {\n    color: #C59D55;\n}\n\n.text_color:hover {\n    color: #fff;\n}\n\n.btn_color {\n    background-color: #166432;\n    color: #C59D55;\n    border: none;\n}\n\n.btn_color:hover {\n    color: #fff;\n    background-color: #13522A;\n}\n\n/* Remove focus outline and box-shadow from active nav-links */\n.nav_tabs .nav_link:focus,\n.nav_tabs .nav_link.active:focus {\n  box-shadow: none;\n  outline: none;\n}\n\n/* Customize active tab border */\n.nav_tabs .nav_link.active {\n  border-color: #dee2e6 #dee2e6 #fff; /* Adjust as needed */\n  /* Optionally, remove the border completely */\n  /* border: none; */\n}\n\n/* Optional: Add a subtle underline to indicate the active tab */\n.nav_tabs .nav_link.active {\n  border-bottom: 2px solid #007bff; /* Example: blue underline */\n}\n\n/* Optional: Provide an alternative focus style for accessibility */\n.nav_tabs .nav_link:focus {\n  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25); /* Example: blue glow */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg_color": `LegalDrafts_bg_color__1unA2`,
	"text_color": `LegalDrafts_text_color__L-oGh`,
	"btn_color": `LegalDrafts_btn_color__juNxC`,
	"nav_tabs": `LegalDrafts_nav_tabs__7hiWT`,
	"nav_link": `LegalDrafts_nav_link__5Qkwu`,
	"active": `LegalDrafts_active__T4uN9`
};
export default ___CSS_LOADER_EXPORT___;
