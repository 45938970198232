import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import style from './ChatArea.module.css';
import { HistoryContext } from '../../Context/HistoryContext';
import { ChatContext } from '../../Context/ChatContext';

export default function ChatArea() {
    const { messages, setMessages } = useContext(ChatContext);
    const { selectedHistoryItemId } = useContext(HistoryContext);
    const [isQuotaExceeded, setIsQuotaExceeded] = useState(false);
    const [displayFileName, setDisplayFileName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [judgmentUrl, setJudgmentUrl] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);
    const [fileContent, setFileContent] = useState('');
    const [modalType, setModalType] = useState(''); // 'judgment' or 'pdf'

    // These state vars used previously for pdf.js/iframe approach
    // We'll now use them for Adobe PDF Embed
    const [pdfFileName, setPdfFileName] = useState('');
    const [pdfPageNumber, setPdfPageNumber] = useState(null);

    const navigate = useNavigate();
    const [inputValue, setInputValue] = useState('');
    const commonUrl = process.env.REACT_APP_COMMON_URL;
    const chatHistoryRef = useRef(null);

    useEffect(() => {
        if (chatHistoryRef.current) {
            chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        }
    }, [messages]);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const sendQueryToAPI = async (query, historyItemId) => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.post(
                `${commonUrl}/api/query/`,
                { query: query, history_item_id: historyItemId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data.response;
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401) {
                    localStorage.removeItem('IsAuthenticated');
                    localStorage.removeItem('access_token');
                    navigate('/', {
                        state: {
                            message:
                                'You have been logged out due to an invalid or expired session.',
                        },
                    });
                } else if (error.response.status === 403) {
                    setIsQuotaExceeded(true);
                } else {
                    console.error('Error communicating with the API:', error);
                }
            } else {
                console.error('Error communicating with the API:', error);
            }
        }
    };

    const upgradeToPreminum = async () => {
        try {
            const token = localStorage.getItem('access_token');
            await axios.post(
                `${commonUrl}/api/logout`,
                {},
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            localStorage.clear();
            window.location.reload();
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const handleSendMessage = async () => {
        if (inputValue.trim()) {
            const newMessages = [...messages, { content: inputValue, role: 'user' }];
            setMessages(newMessages);
            setInputValue('');

            const botResponse = await sendQueryToAPI(inputValue, selectedHistoryItemId);
            setMessages((prevMessages) => [...prevMessages, { content: botResponse, role: 'bot' }]);

            window.dispatchEvent(new Event('userQuerySent'));
        }
    };

    const handleLinkClick = (event) => {
        event.preventDefault();
        const href = event.currentTarget.getAttribute('href');

        // Check if link is a PDF reference by pattern (e.g. FileName.pdf?p=PageNumber)
        const pdfMatch = href.match(/(.*\.pdf)\?p=(\d+)/);
        if (pdfMatch) {
            // It's a PDF link
            const fileName = pdfMatch[1];     // e.g. YourFile.pdf
            const pageNumber = parseInt(pdfMatch[2], 10);

            setModalType('pdf');
            setPdfFileName(fileName);
            setPdfPageNumber(pageNumber);
            setDisplayFileName(fileName.replace('.pdf', ''));
            setModalOpen(true);
            return;
        }

        // Otherwise, assume it's a judgment text link
        setModalType('judgment');
        const displayName = href.replace('-judgment.txt', '');
        setDisplayFileName(displayName);
        setJudgmentUrl(href);
        setModalOpen(true);
    };

    useEffect(() => {
        let links = [];
        if (chatHistoryRef.current) {
            links = chatHistoryRef.current.querySelectorAll('a');
            links.forEach(link => {
                link.addEventListener('click', handleLinkClick);
            });
        }

        return () => {
            links.forEach(link => {
                link.removeEventListener('click', handleLinkClick);
            });
        };
    }, [messages]);

    // Load Judgment content if modalType is 'judgment'
    useEffect(() => {
        if (modalType === 'judgment' && judgmentUrl && isModalOpen) {
            setFileContent('');
            setIsLoading(true);
            fetch(`/Judgments/${encodeURIComponent(judgmentUrl)}`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.text();
                })
                .then((text) => {
                    setFileContent(text);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.error('Error fetching file:', error);
                    setFileContent('Error fetching file content.');
                    setIsLoading(false);
                });
        }
    }, [judgmentUrl, isModalOpen, modalType]);

    // Integrate Adobe PDF Embed API when modalType is 'pdf'
    useEffect(() => {
        if (modalType === 'pdf' && isModalOpen && pdfFileName) {
            if (window.AdobeDC && window.AdobeDC.View) {
                const adobeContainer = document.getElementById('adobe-dc-view');
                if (adobeContainer) {
                    adobeContainer.innerHTML = ''; // Clear previous content
                }
    
                const viewerConfig = {
                    defaultViewMode: "FIT_WIDTH",
                    showPageControls: true,
                    showDownloadPDF: true,
                    showPrintPDF: true,
                };
    
                const adobeDCView = new window.AdobeDC.View({
                    clientId: "e9db8f73687a4f32808813d8c1d9a88a", // Replace with your Adobe PDF Embed API Client ID
                    divId: "adobe-dc-view",
                });
    
                const previewFilePromise = adobeDCView.previewFile(
                    {
                        content: { location: { url: `/PDFs/${pdfFileName}` } },
                        metaData: { fileName: pdfFileName },
                    },
                    viewerConfig
                );
    
                // Handle page navigation using `gotoLocation`
                previewFilePromise
                    .then((adobeViewer) => {
                        adobeViewer.getAPIs().then((apis) => {
                            if (pdfPageNumber && Number.isInteger(pdfPageNumber) && pdfPageNumber > 0) {
                                apis
                                    .gotoLocation(pdfPageNumber, 0, 0) // Navigate to the page (0, 0 for top-left corner)
                                    .then(() => console.log("Navigation successful"))
                                    .catch((error) => console.error("Navigation error:", error));
                            }
                        });
                    })
                    .catch((error) => console.error("Error loading PDF:", error));
            } else {
                console.error("AdobeDC not available. Make sure the PDF Embed API script is included in public/index.html.");
            }
        }
    }, [modalType, isModalOpen, pdfFileName, pdfPageNumber]);
    
    

    const closeModal = () => {
        setModalOpen(false);
        setFileContent('');
        setJudgmentUrl('');
        setPdfFileName('');
        setPdfPageNumber(null);
        setModalType('');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    return (
        <main>
            <div className={style.chat_container}>
                <div className={style.chat_history} ref={chatHistoryRef}>
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            className={`${style.message} ${message.role === 'user' ? style.user : style.bot}`}
                        >
                            {message.role === 'bot' ? (
                                <div dangerouslySetInnerHTML={{ __html: message.content }} />
                            ) : (
                                <p>{message.content}</p>
                            )}
                        </div>
                    ))}
                </div>
                {isQuotaExceeded && (
                    <div className={style.quotaMessage}>
                        <p>
                            Your quota has been exceeded. Please{' '}
                            <a href='/' onClick={upgradeToPreminum}>upgrade</a> to the premium version.
                        </p>
                    </div>
                )}
                <div className={style.input_container}>
                    <input
                        type="text"
                        placeholder="Message Legal Buddy"
                        name="message"
                        id="message"
                        className={style.input_field}
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                    />
                </div>

                {/* Modal */}
                <div
                    className={`modal fade ${isModalOpen ? 'show' : ''}`}
                    id="fileContentModal"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="fileContentModalLabel"
                    aria-hidden="true"
                    style={{
                        display: isModalOpen ? 'block' : 'none',
                        background: 'rgba(0,0,0,0.5)',
                    }}
                >
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="fileContentModalLabel">
                                    {displayFileName}
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={closeModal}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div
                                className="modal-body"
                                style={{ height: '80vh', overflow: 'auto' }}
                            >
                                {modalType === 'judgment' ? (
                                    isLoading ? (
                                        <p>Loading...</p>
                                    ) : fileContent && fileContent !== 'Error fetching file content.' ? (
                                        <pre>{fileContent}</pre>
                                    ) : (
                                        <p>{fileContent}</p>
                                    )
                                ) : modalType === 'pdf' ? (
                                    // Adobe PDF Embed container
                                    <div id="adobe-dc-view" style={{ height: '100%', width: '100%' }}></div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

