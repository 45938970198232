import React, { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

function LegalNoticeForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});

    const handleInputChange = (section, field, value) => {
        setFormData(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value
            }
        }));
    };

    const validateForm = () => {
        let formErrors = {};

        // Validate Debtor Information
        if (!formData.debtorInfo?.companyName) formErrors.debtorCompanyName = "Company Name is required for Debtor";
        if (!formData.debtorInfo?.address) formErrors.debtorAddress = "Address is required for Debtor";

        // Validate Creditor Information
        if (!formData.creditorInfo?.bankName) formErrors.creditorBankName = "Bank Name is required for Creditor";

        // Validate Outstanding Amount Details
        // if (!formData.outstandingDetails?.facilities || formData.outstandingDetails?.facilities.length === 0) {
        //     formErrors.facilities = "At least one facility must be selected";
        // }
        if (!formData.outstandingDetails?.totalPrincipal) formErrors.totalPrincipal = "Total Principal Amount is required";
        if (!formData.outstandingDetails?.totalMarkup) formErrors.totalMarkup = "Total Mark-up Due is required";
        // if (!formData.outstandingDetails?.aggregatedAmount) formErrors.aggregatedAmount = "Aggregated Amount is required";

        // Validate Amount Paid (assuming it's required)
        if (formData.outstandingDetails?.amountPaid === undefined || formData.outstandingDetails?.amountPaid === "") {
            formErrors.amountPaid = "Amount Paid is required";
        } else if (isNaN(Number(formData.outstandingDetails.amountPaid)) || Number(formData.outstandingDetails.amountPaid) < 0) {
            formErrors.amountPaid = "Amount Paid must be a non-negative number";
        }

        // Validate Dates and Deadlines
        // if (!formData.datesAndDeadlines?.paymentDeadLineDate) formErrors.paymentDeadLineDate = "Payment Deadline Date is required";
        if (!formData.datesAndDeadlines?.paymentDeadlineDate) {
            formErrors.paymentDeadlineDate = "Payment Deadline Date is required";
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Form data to submit:", formData);
            onSubmit();
        }
    };

    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            creditorInfo: {
                ...prev.creditorInfo,
                bankName: prev.creditorInfo?.bankName || 'Bank Of Punjab', // Pre-fill bank name
            },
            datesAndDeadlines: {
                ...prev.datesAndDeadlines,
                noticeDate: prev.datesAndDeadlines?.noticeDate || new Date().toISOString().split('T')[0], // Auto-fill notice date
            },
            outstandingDetails: {
                ...prev.outstandingDetails,
                penalCharges: prev.outstandingDetails?.penalCharges || 0,
                amountPaid: prev.outstandingDetails?.amountPaid || 0,
                aggregatedAmount: prev.outstandingDetails?.aggregatedAmount || 0,
            }
        }));
    }, [setFormData]);

    useEffect(() => {
        const totalPrincipal = parseFloat(formData.outstandingDetails?.totalPrincipal) || 0;
        const totalMarkup = parseFloat(formData.outstandingDetails?.totalMarkup) || 0;
        const penalCharges = parseFloat(formData.outstandingDetails?.penalCharges) || 0;
        const amountPaid = parseFloat(formData.outstandingDetails?.amountPaid) || 0;

        const aggregated = (totalPrincipal + totalMarkup + penalCharges) - amountPaid;

        setFormData(prev => ({
            ...prev,
            outstandingDetails: {
                ...prev.outstandingDetails,
                aggregatedAmount: aggregated >= 0 ? aggregated : 0
            }
        }));
    }, [
        formData.outstandingDetails?.totalPrincipal,
        formData.outstandingDetails?.totalMarkup,
        formData.outstandingDetails?.penalCharges,
        formData.outstandingDetails?.amountPaid,
        setFormData
    ]);

    // return (
    //     <div className="mb-2">
    //         <h2 className="text-center">Legal Notice Form</h2>
    //         <form onSubmit={handleSubmit}>
    //             {/* Debtor Information */}
    //             <h4 className="mt-4">1. Debtor Information</h4>
    //             <div className="border rounded p-3 mb-3">
    //                 <div className="form-group">
    //                     <label>Company Name:</label>
    //                     <input
    //                         type="text"
    //                         className={`form-control ${errors.debtorCompanyName ? 'is-invalid' : ''}`}
    //                         value={formData.debtorInfo?.companyName || ""}
    //                         onChange={(e) => handleInputChange('debtorInfo', 'companyName', e.target.value)}
    //                         placeholder="Enter Debtor's Company Name"
    //                     />
    //                     {errors.debtorCompanyName && <div className="invalid-feedback">{errors.debtorCompanyName}</div>}
    //                 </div>
    //                 <div className="form-group">
    //                     <label>Chief Executive's Name (optional):</label>
    //                     <input
    //                         type="text"
    //                         className="form-control"
    //                         value={formData.debtorInfo?.chiefExecutiveName || ""}
    //                         onChange={(e) => handleInputChange('debtorInfo', 'chiefExecutiveName', e.target.value)}
    //                         placeholder="Enter Chief Executive's Name"
    //                     />
    //                 </div>
    //                 <div className="form-group">
    //                     <label>Address:</label>
    //                     <input
    //                         type="text"
    //                         className={`form-control ${errors.debtorAddress ? 'is-invalid' : ''}`}
    //                         value={formData.debtorInfo?.address || ""}
    //                         onChange={(e) => handleInputChange('debtorInfo', 'address', e.target.value)}
    //                         placeholder="Enter Debtor's Address"
    //                     />
    //                     {errors.debtorAddress && <div className="invalid-feedback">{errors.debtorAddress}</div>}
    //                 </div>
    //             </div>

    //             {/* Creditor (Bank) Information */}
    //             <h4 className="mt-4">2. Creditor (Bank) Information</h4>
    //             <div className="border rounded p-3 mb-3">
    //                 <div className="form-group">
    //                     <label>Bank Name:</label>
    //                     <input
    //                         type="text"
    //                         className={`form-control ${errors.creditorBankName ? 'is-invalid' : ''}`}
    //                         value={formData.creditorInfo?.bankName || "Bank Of Punjab"}
    //                         onChange={(e) => handleInputChange('creditorInfo', 'bankName', e.target.value)}
    //                         placeholder="Enter Bank Name"
    //                     />
    //                     {errors.creditorBankName && <div className="invalid-feedback">{errors.creditorBankName}</div>}
    //                 </div>
    //                 <div className="form-group">
    //                     <label>Head Office Address (optional):</label>
    //                     <input
    //                         type="text"
    //                         className="form-control"
    //                         value={formData.creditorInfo?.headOfficeAddress || ""}
    //                         onChange={(e) => handleInputChange('creditorInfo', 'headOfficeAddress', e.target.value)}
    //                         placeholder="Enter Head Office Address"
    //                     />
    //                 </div>
    //             </div>

    //             {/* Outstanding Amount Details */}
    //             <h4 className="mt-4">3. Outstanding Amount Details</h4>
    //             <div className="border rounded p-3 mb-3">
    //                 {/* <div className="form-group">
    //                     <label>Facilities:</label>
    //                     <select
    //                         className={`form-control ${errors.facilities ? 'is-invalid' : ''}`}
    //                         value={formData.outstandingDetails?.facilities || []}
    //                         onChange={(e) => handleInputChange('outstandingDetails', 'facilities', Array.from(e.target.selectedOptions, option => option.value))}
    //                     >
    //                         <option value="RF">RF</option>
    //                         <option value="CF">CF</option>
    //                         <option value="FIM">FIM</option>
    //                         <option value="FTR">FTR</option>
    //                     </select>
    //                     {errors.facilities && <div className="invalid-feedback">{errors.facilities}</div>}
    //                 </div> */}
    //                 <div className="form-group">
    //                     <label>Total Principal Amount:</label>
    //                     <input
    //                         type="number"
    //                         className={`form-control ${errors.totalPrincipal ? 'is-invalid' : ''}`}
    //                         value={formData.outstandingDetails?.totalPrincipal || ""}
    //                         onChange={(e) => handleInputChange('outstandingDetails', 'totalPrincipal', e.target.value)}
    //                         placeholder="Enter Total Principal Amount"
    //                     />
    //                     {errors.totalPrincipal && <div className="invalid-feedback">{errors.totalPrincipal}</div>}
    //                 </div>
    //                 <div className="form-group">
    //                     <label>Total Mark-up:</label>
    //                     <input
    //                         type="number"
    //                         className={`form-control ${errors.totalMarkup ? 'is-invalid' : ''}`}
    //                         value={formData.outstandingDetails?.totalMarkup || ""}
    //                         onChange={(e) => handleInputChange('outstandingDetails', 'totalMarkup', e.target.value)}
    //                         placeholder="Enter Total Mark-up"
    //                     />
    //                     {errors.totalMarkup && <div className="invalid-feedback">{errors.totalMarkup}</div>}
    //                 </div>
    //                 <div className="form-group">
    //                     <label>Aggregated Amount:</label>
    //                     <input
    //                         type="number"
    //                         className={`form-control ${errors.aggregatedAmount ? 'is-invalid' : ''}`}
    //                         value={formData.outstandingDetails?.aggregatedAmount || ""}
    //                         onChange={(e) => handleInputChange('outstandingDetails', 'aggregatedAmount', e.target.value)}
    //                         placeholder="Enter Total Aggregated Amount"
    //                     />
    //                     {errors.aggregatedAmount && <div className="invalid-feedback">{errors.aggregatedAmount}</div>}
    //                 </div>
    //                 {/* <div className="form-group">
    //                     <label>Penal Charges (optional):</label>
    //                     <input
    //                         type="number"
    //                         className="form-control"
    //                         value={formData.outstandingDetails?.penalCharges || ""}
    //                         onChange={(e) => handleInputChange('outstandingDetails', 'penalCharges', e.target.value)}
    //                         placeholder="Enter Penal Charges"
    //                     />
    //                 </div> */}
    //             </div>

    //             {/* Dates and Deadlines */}
    //             <h4 className="mt-4">4. Dates and Deadlines</h4>
    //             <div className="border rounded p-3 mb-3">
    //                 <div className="form-group">
    //                     <label>Notice Date:</label>
    //                     <input
    //                         type="date"
    //                         className="form-control"
    //                         value={formData.datesAndDeadlines?.noticeDate || ""}
    //                         onChange={(e) => handleInputChange('datesAndDeadlines', 'noticeDate', e.target.value)}
    //                     />
    //                 </div>
    //                 <div className="form-group">
    //                     <label>Over Due Date:</label>
    //                     <input
    //                         type="date"
    //                         className="form-control"
    //                         onChange={(e) => handleInputChange('datesAndDeadlines', 'overDueDate', e.target.value)}
    //                     />
    //                 </div>
    //                 <div className="form-group">
    //                     <label>Payment Deadline Date:</label>
    //                     <select
    //                         className={`form-control ${errors.paymentDeadlineDate ? 'is-invalid' : ''}`}
    //                         value={formData.datesAndDeadlines?.paymentDeadlineDate || ""}
    //                         onChange={(e) => handleInputChange('datesAndDeadlines', 'paymentDeadlineDate', e.target.value)}
    //                     >
    //                         <option value="">Select Payment Deadline Period</option>
    //                         <option value="7">Within 7 days</option>
    //                         <option value="14">Within 14 days</option>
    //                         <option value="30">Within 30 days</option>
    //                         <option value="60">Within 60 days</option>
    //                         {/* Add more options as needed */}
    //                     </select>
    //                     {errors.paymentDeadlineDate && <div className="invalid-feedback">{errors.paymentDeadlineDate}</div>}
    //                 </div>

    //             </div>

    //             {/* Submit Button */}
    //             <button
    //                 type="submit"
    //                 className="btn"
    //                 style={{
    //                     backgroundColor: '#166432',
    //                     color: '#C59D55',
    //                     border: 'none',
    //                 }}
    //                 onMouseEnter={(e) => {
    //                     e.target.style.backgroundColor = '#092415';
    //                     e.target.style.color = '#fff';
    //                 }}
    //                 onMouseLeave={(e) => {
    //                     e.target.style.backgroundColor = '#166432';
    //                     e.target.style.color = '#C59D55';
    //                 }}
    //             >
    //                 Next
    //             </button>
    //         </form>
    //     </div>
    // );

    return (
        <div className="container mb-2">
            <h2 className="text-center">Legal Notice Form</h2>
            <form onSubmit={handleSubmit}>
                {/* First Row: Debtor Information & Creditor (Bank) Information */}
                <div className="row mt-4">
                    {/* Debtor Information */}
                    <div className="col-md-6 mb-3">
                        <h4>1. Debtor Information</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Company Name:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.debtorCompanyName ? 'is-invalid' : ''}`}
                                    value={formData.debtorInfo?.companyName || ""}
                                    onChange={(e) => handleInputChange('debtorInfo', 'companyName', e.target.value)}
                                    placeholder="Enter Debtor's Company Name"
                                />
                                {errors.debtorCompanyName && <div className="invalid-feedback">{errors.debtorCompanyName}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Chief Executive's Name (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.debtorInfo?.chiefExecutiveName || ""}
                                    onChange={(e) => handleInputChange('debtorInfo', 'chiefExecutiveName', e.target.value)}
                                    placeholder="Enter Chief Executive's Name"
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Address:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.debtorAddress ? 'is-invalid' : ''}`}
                                    value={formData.debtorInfo?.address || ""}
                                    onChange={(e) => handleInputChange('debtorInfo', 'address', e.target.value)}
                                    placeholder="Enter Debtor's Address"
                                />
                                {errors.debtorAddress && <div className="invalid-feedback">{errors.debtorAddress}</div>}
                            </div>
                        </div>
                    </div>

                    {/* Creditor (Bank) Information */}
                    <div className="col-md-6 mb-3">
                        <h4>2. Creditor (Bank) Information</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Bank Name:</label>
                                <input
                                    type="text"
                                    className={`form-control ${errors.creditorBankName ? 'is-invalid' : ''}`}
                                    value={formData.creditorInfo?.bankName || "Bank Of Punjab"}
                                    onChange={(e) => handleInputChange('creditorInfo', 'bankName', e.target.value)}
                                    placeholder="Enter Bank Name"
                                />
                                {errors.creditorBankName && <div className="invalid-feedback">{errors.creditorBankName}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Head Office Address (optional):</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.creditorInfo?.headOfficeAddress || ""}
                                    onChange={(e) => handleInputChange('creditorInfo', 'headOfficeAddress', e.target.value)}
                                    placeholder="Enter Head Office Address"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Second Row: Outstanding Amount Details & Dates and Deadlines */}
                {/* Second Row: Outstanding Amount Details & Dates and Deadlines */}
                <div className="row">
                    {/* Outstanding Amount Details */}
                    <div className="col-md-12 mb-3">
                        <h4>3. Outstanding Amount Details</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Total Principal Amount:</label>
                                <NumericFormat
                                    className={`form-control ${errors.totalPrincipal ? 'is-invalid' : ''}`}
                                    value={formData.outstandingDetails?.totalPrincipal || ""}
                                    onValueChange={(values) => {
                                        const { value } = values; // Raw unformatted value
                                        handleInputChange('outstandingDetails', 'totalPrincipal', value);
                                    }}
                                    thousandSeparator
                                    placeholder="Enter Total Principal Amount"
                                    isNumericString
                                />
                                {errors.totalPrincipal && <div className="invalid-feedback">{errors.totalPrincipal}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Total Mark-up:</label>
                                <NumericFormat
                                    className={`form-control ${errors.totalMarkup ? 'is-invalid' : ''}`}
                                    value={formData.outstandingDetails?.totalMarkup || ""}
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        handleInputChange('outstandingDetails', 'totalMarkup', value);
                                    }}
                                    thousandSeparator=","
                                    placeholder="Enter Total Mark-up"
                                    allowNegative={false}
                                />
                                {errors.totalMarkup && <div className="invalid-feedback">{errors.totalMarkup}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Penal Charges (optional):</label>
                                <NumericFormat
                                    className="form-control"
                                    value={formData.outstandingDetails?.penalCharges || ""}
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        handleInputChange('outstandingDetails', 'penalCharges', value);
                                    }}
                                    thousandSeparator=","
                                    placeholder="Enter Penal Charges"
                                    allowNegative={false}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Amount Paid:</label>
                                <NumericFormat
                                    className={`form-control ${errors.amountPaid ? 'is-invalid' : ''}`}
                                    value={formData.outstandingDetails?.amountPaid || ""}
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        handleInputChange('outstandingDetails', 'amountPaid', value);
                                    }}
                                    thousandSeparator=","
                                    placeholder="Enter Amount Paid"
                                    allowNegative={false}
                                />
                                {errors.amountPaid && <div className="invalid-feedback">{errors.amountPaid}</div>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Aggregated Amount:</label>
                                <NumericFormat
                                    className="form-control"
                                    value={formData.outstandingDetails?.aggregatedAmount || 0}
                                    displayType="text"
                                    thousandSeparator=","
                                    placeholder="Aggregated Amount"
                                    allowNegative={false}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Dates and Deadlines */}
                    <div className="col-md-12 mb-3">
                        <h4>4. Dates and Deadlines</h4>
                        <div className="border rounded p-3">
                            <div className="form-group mb-3">
                                <label>Notice Date:</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={formData.datesAndDeadlines?.noticeDate || ""}
                                    onChange={(e) => handleInputChange('datesAndDeadlines', 'noticeDate', e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Over Due Date:</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={formData.datesAndDeadlines?.overDueDate || ""}
                                    onChange={(e) => handleInputChange('datesAndDeadlines', 'overDueDate', e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>Payment Deadline Period:</label>
                                <select
                                    className={`form-control ${errors.paymentDeadlineDate ? 'is-invalid' : ''}`}
                                    value={formData.datesAndDeadlines?.paymentDeadlineDate || ""}
                                    onChange={(e) => handleInputChange('datesAndDeadlines', 'paymentDeadlineDate', e.target.value)}
                                >
                                    <option value="">Select Payment Deadline Period</option>
                                    <option value="Immediately">Immediately</option>
                                    <option value="Within 7 days">Within 7 days</option>
                                    <option value="Within 14 days">Within 14 days</option>
                                    <option value="Within 30 days">Within 30 days</option>
                                    <option value="Within 60 days">Within 60 days</option>
                                    {/* Add more options as needed */}
                                </select>
                                {errors.paymentDeadlineDate && <div className="invalid-feedback">{errors.paymentDeadlineDate}</div>}
                            </div>
                        </div>
                    </div>
                </div>


                {/* Submit Button */}
                <div className="mt-4">
                    <button
                        type="submit"
                        className="btn px-4 py-2"
                        style={{
                            backgroundColor: '#166432',
                            color: '#C59D55',
                            border: 'none',
                            transition: 'background-color 0.3s, color 0.3s',
                        }}
                        onMouseEnter={(e) => {
                            e.target.style.backgroundColor = '#092415';
                            e.target.style.color = '#fff';
                        }}
                        onMouseLeave={(e) => {
                            e.target.style.backgroundColor = '#166432';
                            e.target.style.color = '#C59D55';
                        }}
                    >
                        Next
                    </button>
                </div>
            </form>
        </div>
    );

}

export default LegalNoticeForm;
