import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

export default function SignIn() {
    const commonUrl = process.env.REACT_APP_COMMON_URL;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const [logoutMessage, setLogoutMessage] = useState(location.state?.message || '');
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    // Handle reCAPTCHA token change
    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    // Handle form submit (login logic)
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!recaptchaToken) {
            setErrorMessage('Please complete the reCAPTCHA verification.');
            return;
        }

        try {
            const response = await Axios.post(`${commonUrl}/api/login`, {
                email,
                password,
                recaptchaToken
            });
            const { access_token } = response.data;

            // Store JWT in local storage
            localStorage.setItem('access_token', access_token);
            localStorage.setItem('IsAuthenticated', JSON.stringify(true));

            // Navigate to dashboard page
            navigate('/dashboard');
        } catch (error) {
            setErrorMessage('Invalid email or password. Please try again.');
        }
    };

    // Clear messages after 5 seconds
    useEffect(() => {
        let timer;
        if (logoutMessage || errorMessage) {
            timer = setTimeout(() => {
                setLogoutMessage('');
                setErrorMessage('');
            }, 5000); // 5 seconds
        }
        return () => clearTimeout(timer);
    }, [logoutMessage, errorMessage]);

    return (
        <main className="d-flex w-100">
            <div className="container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">
                            <div className="text-center mt-4">
                                <h1 className="h2">Welcome To Pak Law Assist</h1>
                                <p className="lead">
                                    Sign in to your account to continue
                                </p>
                            </div>
                            {/* Signup section moved to top */}
                            <div className="text-center mt-3 mb-4">
                                <p className="lead" style={{ fontWeight: 'bold' }}>
                                    Don't have an account?{' '}
                                    <a
                                        href="/signup"
                                        style={{ color: '#166432', textDecoration: 'underline', fontWeight: 'bold' }}
                                        onMouseEnter={(e) => (e.target.style.color = '#092415')}
                                        onMouseLeave={(e) => (e.target.style.color = '#166432')}
                                    >
                                        Sign up
                                    </a>
                                </p>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="m-sm-4">
                                        {/* Display the logout message if present */}
                                        {logoutMessage && (
                                            <div className="alert alert-warning">
                                                {logoutMessage}
                                            </div>
                                        )}

                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label className="form-label">Email</label>
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="email"
                                                    value={email}
                                                    onChange={(event) => setEmail(event.target.value)}
                                                    placeholder="Enter your email"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Password</label>
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="password"
                                                    value={password}
                                                    onChange={(event) => setPassword(event.target.value)}
                                                    placeholder="Enter your password"
                                                />
                                                <small>
                                                    <a href="#">Forgot password?</a>
                                                </small>
                                            </div>

                                            {/* Display any login error messages */}
                                            {errorMessage && (
                                                <div className="alert alert-danger">
                                                    {errorMessage}
                                                </div>
                                            )}

                                            {/* Google reCAPTCHA */}
                                            <div className="mb-3">
                                                <ReCAPTCHA
                                                    sitekey="6Ld7oHQqAAAAAHJUXxjieXjEl1QCnEYkmSHbAFW6"
                                                    onChange={handleRecaptchaChange}
                                                />
                                            </div>

                                            <div className="text-center mt-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg"
                                                    style={{
                                                        backgroundColor: '#166432',
                                                        color: '#C59D55',
                                                        border: 'none'
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        e.target.style.backgroundColor = '#092415';
                                                        e.target.style.color = '#fff';
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.target.style.backgroundColor = '#166432';
                                                        e.target.style.color = '#C59D55';
                                                    }}
                                                >
                                                    Sign in
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
