import React, { useState, useEffect } from 'react';

function BailApplicationForm({ formData: initialData, setFormData, onSubmit }) {
    const [formData, setFormDataInternal] = useState(initialData);
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Handle form input changes
    const handleChange = (e) => {
        setFormDataInternal({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // Clear error messages after 3 seconds
    const clearErrorAfterDelay = () => {
        setTimeout(() => {
            setErrors({});
        }, 5000);
    };

    // Form validation logic
    const validateForm = () => {
        const newErrors = {};

        if (!formData.courtName) newErrors.courtName = 'Court Name is required';
        if (!formData.city) newErrors.city = 'City is required';
        if (!formData.petitioner) newErrors.petitioner = 'Petitioner Name is required';
        if (!formData.respondents) newErrors.respondents = 'Respondents are required';
        if (!formData.firNumber) newErrors.firNumber = 'FIR Number is required';

        // Date validation: FIR date should not be in the future
        if (!formData.firDate) {
            newErrors.firDate = 'FIR Date is required';
        } else {
            const selectedDate = new Date(formData.firDate);
            const currentDate = new Date();
            if (selectedDate > currentDate) {
                newErrors.firDate = 'FIR Date cannot be in the future';
            }
        }

        if (!formData.offenseSection) newErrors.offenseSection = 'Offense Under Section is required';
        if (!formData.policeStation) newErrors.policeStation = 'Police Station is required';
        if (!formData.firDescription) newErrors.firDescription = 'FIR Description is required';
        if (!formData.petitionerArguments) newErrors.petitionerArguments = 'Petitioner Arguments are required';
        if (!formData.advocateName) newErrors.advocateName = 'Advocate Name is required';
        if (!formData.licenseNumber) newErrors.licenseNumber = 'License Number is required';

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            clearErrorAfterDelay();  // Clear error messages after 3 seconds
        }

        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);

        if (validateForm()) {
            setFormData(formData); // Set the form data at the parent level
            onSubmit(); // Trigger the onSubmit callback to navigate to preview or next step
        }
    };

    // Trigger form validation when form data changes, only if the form has been submitted
    useEffect(() => {
        if (isSubmitted) {
            validateForm();
        }
    }, [formData, isSubmitted]);

    return (
        <div className="mb-2">
            <h1 className="text-center">Bail Application</h1>

            <form onSubmit={handleSubmit} className="mt-4">
                {/* Court Name */}
                <div className="form-group mb-3">
                    <label><strong>Court Name</strong></label>
                    <input
                        type="text"
                        className="form-control"
                        name="courtName"
                        value={formData.courtName}
                        onChange={handleChange}
                        placeholder="Enter Court Name"
                    />
                    {errors.courtName && isSubmitted && <p className="text-danger">{errors.courtName}</p>}
                </div>

                {/* City */}
                <div className="form-group mb-3">
                    <label><strong>City</strong></label>
                    <input
                        type="text"
                        className="form-control"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        placeholder="Enter City"
                    />
                    {errors.city && isSubmitted && <p className="text-danger">{errors.city}</p>}
                </div>

                {/* Petition Type */}
                <div className="form-group mb-3">
                    <label><strong>Petition Type</strong></label>
                    <select
                        className="form-control"
                        name="petitionType"
                        value={formData.petitionType}
                        onChange={handleChange}
                    >
                        <option value="">Select Petition Type</option>
                        <option value="Post-Arrest Bail Request">Post-Arrest Bail Request</option>
                        <option value="Pre-Arrest Bail Request">Pre-Arrest Bail Request</option>
                        <option value="Cancellation of Bail">Cancellation of Bail</option>
                    </select>
                    {errors.petitionType && isSubmitted && <p className="text-danger">{errors.petitionType}</p>}
                </div>

                {/* Petitioner Name */}
                <div className="form-group mb-3">
                    <label><strong>Petitioner Name</strong></label>
                    <input
                        type="text"
                        className="form-control"
                        name="petitioner"
                        value={formData.petitioner}
                        onChange={handleChange}
                        placeholder="Enter Petitioner's Full Name"
                    />
                    {errors.petitioner && isSubmitted && <p className="text-danger">{errors.petitioner}</p>}
                </div>

                {/* Respondents */}
                <div className="form-group mb-3">
                    <label><strong>Respondents</strong></label>
                    <textarea
                        className="form-control"
                        name="respondents"
                        value={formData.respondents}
                        onChange={handleChange}
                        placeholder="Enter Respondent's Details"
                        rows="5"
                    ></textarea>
                    {errors.respondents && isSubmitted && <p className="text-danger">{errors.respondents}</p>}
                </div>

                {/* FIR Number */}
                <div className="form-group mb-3">
                    <label><strong>FIR Number</strong></label>
                    <input
                        type="text"
                        className="form-control"
                        name="firNumber"
                        value={formData.firNumber}
                        onChange={handleChange}
                        placeholder="Enter FIR Number"
                    />
                    {errors.firNumber && isSubmitted && <p className="text-danger">{errors.firNumber}</p>}
                </div>

                {/* FIR Date */}
                <div className="form-group mb-3">
                    <label><strong>FIR Date</strong></label>
                    <input
                        type="date"
                        className="form-control"
                        name="firDate"
                        value={formData.firDate}
                        onChange={handleChange}
                    />
                    {errors.firDate && isSubmitted && <p className="text-danger">{errors.firDate}</p>}
                </div>

                {/* Offense Section */}
                <div className="form-group mb-3">
                    <label><strong>Offense Section</strong></label>
                    <input
                        type="text"
                        className="form-control"
                        name="offenseSection"
                        value={formData.offenseSection}
                        onChange={handleChange}
                        placeholder="Enter Offense Under Section"
                    />
                    {errors.offenseSection && isSubmitted && <p className="text-danger">{errors.offenseSection}</p>}
                </div>

                {/* Police Station */}
                <div className="form-group mb-3">
                    <label><strong>Police Station</strong></label>
                    <input
                        type="text"
                        className="form-control"
                        name="policeStation"
                        value={formData.policeStation}
                        onChange={handleChange}
                        placeholder="Enter Police Station"
                    />
                    {errors.policeStation && isSubmitted && <p className="text-danger">{errors.policeStation}</p>}
                </div>

                {/* FIR Description */}
                <div className="form-group mb-3">
                    <label><strong>FIR Description</strong></label>
                    <textarea
                        className="form-control"
                        name="firDescription"
                        value={formData.firDescription}
                        onChange={handleChange}
                        placeholder="Enter FIR Description"
                        rows="5"
                    ></textarea>
                    {errors.firDescription && isSubmitted && <p className="text-danger">{errors.firDescription}</p>}
                </div>

                {/* Petitioner's Arguments */}
                <div className="form-group mb-3">
                    <label><strong>Petitioner's Arguments</strong></label>
                    <textarea
                        className="form-control"
                        name="petitionerArguments"
                        value={formData.petitionerArguments}
                        onChange={handleChange}
                        placeholder="Enter Petitioner's Arguments"
                        rows="5"
                    ></textarea>
                    {errors.petitionerArguments && isSubmitted && <p className="text-danger">{errors.petitionerArguments}</p>}
                </div>

                {/* Advocate Name */}
                <div className="form-group mb-3">
                    <label><strong>Advocate Name</strong></label>
                    <input
                        type="text"
                        className="form-control"
                        name="advocateName"
                        value={formData.advocateName}
                        onChange={handleChange}
                        placeholder="Enter Advocate's Name"
                    />
                    {errors.advocateName && isSubmitted && <p className="text-danger">{errors.advocateName}</p>}
                </div>

                {/* License Number */}
                <div className="form-group mb-3">
                    <label><strong>License Number</strong></label>
                    <input
                        type="text"
                        className="form-control"
                        name="licenseNumber"
                        value={formData.licenseNumber}
                        onChange={handleChange}
                        placeholder="Enter License Number"
                    />
                    {errors.licenseNumber && isSubmitted && <p className="text-danger">{errors.licenseNumber}</p>}
                </div>

                {/* <button type="submit" className="btn btn-primary mb-2">Next</button> */}
                <button
                    type="submit"
                    className="btn mb-2"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>

                {/* Conditional error message */}
                {Object.keys(errors).length > 0 && isSubmitted && (
                    <div className="alert alert-danger" role="alert">
                        Please fix the errors before proceeding.
                    </div>
                )}
            </form>
        </div>

    );
}

export default BailApplicationForm;
