import React, { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import * as pdfjsLib from 'pdfjs-dist';
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

function RecoverySuitForm({ formData, setFormData, onSubmit }) {
    const [errors, setErrors] = useState({});
    const [textEntered, setTextEntered] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);

    // const handleInputChange = (section, field, value) => {
    //     setFormData(prev => ({
    //         ...prev,
    //         [section]: {
    //             ...prev[section],
    //             [field]: value,
    //         },
    //     }));
    // };
    const handleInputChange = (section, field, value) => {
        // If the field is 'defendantResponse' and section is 'legalNotices'
        if (section === 'legalNotices' && field === 'defendantResponse') {
            if (value.length > 0) {
                setTextEntered(true);
                setFileUploaded(false);
            } else {
                setTextEntered(false);
            }
        }

        setFormData(prev => ({
            ...prev,
            [section]: {
                ...prev[section],
                [field]: value,
            },
        }));
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type === 'application/pdf') {
                try {
                    const text = await extractTextFromPDF(file);
                    handleInputChange('legalNotices', 'defendantResponse', text);
                    setFileUploaded(true);
                    setTextEntered(false);
                } catch (error) {
                    console.error('Error extracting text from PDF:', error);
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        defendantResponse: 'Failed to extract text from PDF.',
                    }));
                }
            } else if (file.type === 'text/plain') {
                // Handle txt files
                const reader = new FileReader();
                reader.onload = function (e) {
                    const text = e.target.result;
                    handleInputChange('legalNotices', 'defendantResponse', text);
                    setFileUploaded(true);
                    setTextEntered(false);
                };
                reader.readAsText(file);
            } else {
                alert('Unsupported file type. Please upload a .txt or .pdf file.');
            }
        }
    };

    const extractTextFromPDF = async (file) => {
        const fileReader = new FileReader();
        return new Promise((resolve, reject) => {
            fileReader.onload = function () {
                const typedarray = new Uint8Array(this.result);
                pdfjsLib
                    .getDocument({ data: typedarray })
                    .promise.then(async (pdf) => {
                        let pdfText = '';
                        for (let i = 1; i <= pdf.numPages; i++) {
                            const page = await pdf.getPage(i);
                            const textContent = await page.getTextContent();
                            const pageText = textContent.items
                                .map((item) => item.str)
                                .join(' ');
                            pdfText += pageText + '\n';
                        }
                        resolve(pdfText);
                    })
                    .catch((err) => {
                        console.error('Error parsing PDF:', err);
                        reject(err);
                    });
            };
            fileReader.onerror = reject;
            fileReader.readAsArrayBuffer(file);
        });
    };
    

    const validateForm = () => {
        let formErrors = {};

        // Validation logic
        // Basic Information
        if (!formData.basicInfo.courtName) formErrors.courtName = "Court name is required";
        if (!formData.basicInfo.filingDate) formErrors.filingDate = "Filing date is required";
        // if (!formData.basicInfo.caseTitle) formErrors.caseTitle = "Case Title is required";

        // Plaintiff Information
        if (!formData.plaintiffInfo.name) formErrors.plaintiffName = "Plaintiff name is required";
        if (!formData.plaintiffInfo.address) formErrors.plaintiffAddress = "Plaintiff address is required";

        // Defendant Information
        if (!formData.defendantInfo.name) formErrors.defendantName = "Defendant name is required";
        if (!formData.defendantInfo.address) formErrors.defendantAddress = "Defendant address is required";

        // Debt and Transaction Details
        if (!formData.debtDetails.natureOfLoan) formErrors.natureOfLoan = "Nature of business relationship is required";
        if (!formData.debtDetails.outstandingAmount) formErrors.outstandingAmount = "Outstanding amount is required";
        if (!formData.debtDetails.defaultSince) formErrors.defaultSince = "Dates of transactions are required";

        // Cause of Action
        if (!formData.causeOfAction.contractualObligation) formErrors.contractualObligation = "Contractual obligation or breach details are required";

        // Relief Sought
        if (!formData.reliefSought.totalPrincipalAmount) formErrors.totalPrincipalAmount = "Total principal amount is required";

        // Jurisdiction
        if (!formData.jurisdiction.pecuniaryJurisdiction) formErrors.pecuniaryJurisdiction = "Pecuniary jurisdiction confirmation is required";
        if (!formData.jurisdiction.territorialJurisdiction) formErrors.territorialJurisdiction = "Territorial jurisdiction confirmation is required";

        // Lawyer's Details
        if (!formData.lawyerDetails.advocateName) formErrors.advocateName = "Plaintiff’s advocate name is required";
        if (!formData.lawyerDetails.licenseNumber) formErrors.licenseNumber = "Advocate's license number is required";
        if (!formData.lawyerDetails.contactInformation) formErrors.advocateContactInformation = "Advocate contact information is required";

        // Prayer
        if (!formData.prayer.prayerForDecree) formErrors.prayerForDecree = "Prayer for decree is required";

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // console.log("Form data to submit:", formData);
            // setFormData(formData);
            onSubmit();
        }
    };

    return (
        <div className="mb-2">
            <h2 className="text-center">Debt Recovery Suit Form</h2>
            <form onSubmit={handleSubmit}>
                {/* A. Basic Information */}
                <h4 className="mt-4">1. Basic Information</h4>
                <div className="form-group">
                    <label>Court Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.courtName ? 'is-invalid' : ''}`}
                        value={formData.basicInfo.courtName}
                        onChange={(e) => handleInputChange('basicInfo', 'courtName', e.target.value)}
                        placeholder="Enter Court Name"
                    />
                    {errors.courtName && <div className="invalid-feedback">{errors.courtName}</div>}
                </div>
                {/* <div className="form-group">
                    <label>Case Title:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.caseTitle ? 'is-invalid' : ''}`}
                        value={formData.basicInfo.caseTitle}
                        onChange={(e) => handleInputChange('basicInfo', 'caseTitle', e.target.value)}
                        placeholder="Enter Case Title"
                    />
                    {errors.caseTitle && <div className="invalid-feedback">{errors.caseTitle}</div>}
                </div> */}
                {/* Suit Number is assigned by court */}
                <div className="form-group">
                    <label>Filing Date:</label>
                    <input
                        type="date"
                        className={`form-control ${errors.filingDate ? 'is-invalid' : ''}`}
                        value={formData.basicInfo.filingDate}
                        onChange={(e) => handleInputChange('basicInfo', 'filingDate', e.target.value)}
                    />
                    {errors.filingDate && <div className="invalid-feedback">{errors.filingDate}</div>}
                </div>

                {/* B. Plaintiff’s Information */}
                <h4 className="mt-4">2. Plaintiff’s Information</h4>
                <div className="form-group">
                    <label>Plaintiff Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.plaintiffName ? 'is-invalid' : ''}`}
                        value={formData.plaintiffInfo.name}
                        onChange={(e) => handleInputChange('plaintiffInfo', 'name', e.target.value)}
                        placeholder="Enter Plaintiff's Full Name or Company Name"
                    />
                    {errors.plaintiffName && <div className="invalid-feedback">{errors.plaintiffName}</div>}
                </div>
                <div className="form-group">
                    <label>Plaintiff's Position:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.plaintiffInfo.position}
                        onChange={(e) => handleInputChange('plaintiffInfo', 'position', e.target.value)}
                        placeholder="If applicable, e.g., Director"
                    />
                </div>
                <div className="form-group">
                    <label>Plaintiff Address:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.plaintiffAddress ? 'is-invalid' : ''}`}
                        value={formData.plaintiffInfo.address}
                        onChange={(e) => handleInputChange('plaintiffInfo', 'address', e.target.value)}
                        placeholder="Enter Plaintiff's Address"
                    />
                    {errors.plaintiffAddress && <div className="invalid-feedback">{errors.plaintiffAddress}</div>}
                </div>
                <div className="form-group">
                    <label>Plaintiff’s Legal Representative:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.plaintiffInfo.legalRepresentative}
                        onChange={(e) => handleInputChange('plaintiffInfo', 'legalRepresentative', e.target.value)}
                        placeholder="Enter Lawyer's Name and Details"
                    />
                </div>
                <div className="form-group mb-3">
                    <label>Company Nomination Attorney:</label>
                    <textarea
                        className="form-control"
                        value={formData.plaintiffInfo.companyNominationAttorney || ""}
                        onChange={(e) => handleInputChange('plaintiffInfo', 'companyNominationAttorney', e.target.value)}
                        placeholder="Enter the authorized attorney's details"
                    ></textarea>
                </div>

                {/* C. Defendant’s Information */}
                <h4 className="mt-4">3. Defendant’s Information</h4>
                <div className="form-group">
                    <label>Defendant Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.defendantName ? 'is-invalid' : ''}`}
                        value={formData.defendantInfo.name}
                        onChange={(e) => handleInputChange('defendantInfo', 'name', e.target.value)}
                        placeholder="Enter Defendant's Full Name or Business Name"
                    />
                    {errors.defendantName && <div className="invalid-feedback">{errors.defendantName}</div>}
                </div>
                <div className="form-group">
                    <label>Defendant's Business Information:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.defendantInfo.businessInformation}
                        onChange={(e) => handleInputChange('defendantInfo', 'businessInformation', e.target.value)}
                        placeholder="Business Information"
                    />
                </div>
                <div className="form-group">
                    <label>Defendant Address:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.defendantAddress ? 'is-invalid' : ''}`}
                        value={formData.defendantInfo.address}
                        onChange={(e) => handleInputChange('defendantInfo', 'address', e.target.value)}
                        placeholder="Enter Defendant's Address"
                    />
                    {errors.defendantAddress && <div className="invalid-feedback">{errors.defendantAddress}</div>}
                </div>
                <div className="form-group">
                    <label>Defendant’s Legal Representative:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.defendantInfo.legalRepresentative}
                        onChange={(e) => handleInputChange('defendantInfo', 'legalRepresentative', e.target.value)}
                        placeholder="If known, enter details"
                    />
                </div>

                {/* D. Debt and Transaction Details */}
                <h4 className="mt-4">4. Debt and Transaction Details</h4>
                <div className="form-group mb-3">
                    <label>Nature of Loan:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.natureOfLoan ? 'is-invalid' : ''}`}
                        value={formData.debtDetails.natureOfLoan || ""}
                        onChange={(e) => handleInputChange('debtDetails', 'natureOfLoan', e.target.value)}
                        placeholder="e.g., Personal Loan, Business Loan"
                    />
                    {errors.natureOfLoan && <div className="invalid-feedback">{errors.natureOfLoan}</div>}
                </div>
                {/* <div className="form-group">
                    <label></label>
                    <textarea
                        className="form-control"
                        value={formData.debtDetails.assetOfferedAsCollateral}
                        onChange={(e) => handleInputChange('debtDetails', 'assetOfferedAsCollateral', e.target.value)}
                        placeholder="Provide details of goods or services provided"
                    ></textarea>
                </div> */}
                <div className="form-group mb-3">
                    <label>Asset offered as Collateral:</label>
                    <select
                        className="form-control"
                        value={formData.debtDetails.assetOfferedAsCollateral || ""}
                        onChange={(e) => handleInputChange('debtDetails', 'assetOfferedAsCollateral', e.target.value)}
                    >
                        <option value="">Select Collateral Asset</option>
                        <option value="Mortgage Property">Mortgage Property</option>
                        <option value="Stocks">Stocks</option>
                        <option value="Shares">Shares</option>
                        {/* Add more options as needed, e.g., Equipment, Inventory, etc. */}
                    </select>
                </div>
                <div className="form-group">
                    <label>Outstanding Amount:</label>
                    <NumericFormat
                        className={`form-control ${errors.outstandingAmount ? 'is-invalid' : ''}`}
                        value={formData.debtDetails.outstandingAmount}
                        onValueChange={(values) => {
                            const { value } = values; // This gives the raw number value without formatting
                            handleInputChange('debtDetails', 'outstandingAmount', value);
                        }}
                        thousandSeparator=","
                        placeholder="Total Amount Owed"
                        isNumericString // Ensures the value remains a stringified number
                    />
                    {errors.outstandingAmount && (
                        <div className="invalid-feedback">{errors.outstandingAmount}</div>
                    )}
                </div>
                <div className="form-group mb-3">
                    <label>Default Since:</label>
                    <input
                        type="date"
                        className={`form-control ${errors.defaultSince ? 'is-invalid' : ''}`}
                        value={formData.debtDetails.defaultSince || ""}
                        onChange={(e) => handleInputChange('debtDetails', 'defaultSince', e.target.value)}
                        placeholder=""
                    />
                    {errors.defaultSince && <div className="invalid-feedback">{errors.defaultSince}</div>}
                </div>

                {/* E. Cause of Action */}
                <h4 className="mt-4">5. Cause of Action</h4>
                <div className="form-group">
                    <label>Contractual Obligation or Breach:</label>
                    <textarea
                        className={`form-control ${errors.contractualObligation ? 'is-invalid' : ''}`}
                        value={formData.causeOfAction.contractualObligation}
                        onChange={(e) => handleInputChange('causeOfAction', 'contractualObligation', e.target.value)}
                        placeholder="Details of Contract and Breach"
                    ></textarea>
                    {errors.contractualObligation && <div className="invalid-feedback">{errors.contractualObligation}</div>}
                </div>
                <div className="form-group">
                    <label>Reason for Default:</label>
                    <textarea
                        className="form-control"
                        value={formData.causeOfAction.reasonForDefault}
                        onChange={(e) => handleInputChange('causeOfAction', 'reasonForDefault', e.target.value)}
                        placeholder="E.g., Dishonored Cheques, Non-Payment"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Payment Follow-Up:</label>
                    <textarea
                        className="form-control"
                        value={formData.causeOfAction.paymentFollowUp}
                        onChange={(e) => handleInputChange('causeOfAction', 'paymentFollowUp', e.target.value)}
                        placeholder="Details of Communication and Reminders Sent"
                    ></textarea>
                </div>
                {/* <div className="form-group">
                    <label>Interest Clause:</label>
                    <textarea
                        className="form-control"
                        value={formData.causeOfAction.interestClause}
                        onChange={(e) => handleInputChange('causeOfAction', 'interestClause', e.target.value)}
                        placeholder="Interest Rate and Calculation"
                    ></textarea>
                </div> */}

                {/* F. Supporting Documents */}
                <h4 className="mt-4">6. Supporting Documents</h4>
                <div className="form-group">
                    <label>Contracts/Agreements:</label>
                    <textarea
                        className="form-control"
                        value={formData.supportingDocuments.contracts}
                        onChange={(e) => handleInputChange('supportingDocuments', 'contracts', e.target.value)}
                        placeholder="List Contracts/Agreements if Applicable"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Invoices or Bills:</label>
                    <textarea
                        className="form-control"
                        value={formData.supportingDocuments.invoices}
                        onChange={(e) => handleInputChange('supportingDocuments', 'invoices', e.target.value)}
                        placeholder="List Invoices or Bills Detail"
                    ></textarea>
                </div>
                <div className="form-group">
                    <label>Cheque Details:</label>
                    <textarea
                        className="form-control"
                        value={formData.supportingDocuments.chequeDetails}
                        onChange={(e) => handleInputChange('supportingDocuments', 'chequeDetails', e.target.value)}
                        placeholder="List Dishonored Cheques or Bank Notices Detail"
                    ></textarea>
                </div>
                {/* <div className="form-group">
                    <label>Demand Letters:</label>
                    <textarea
                        className="form-control"
                        value={formData.supportingDocuments.demandLetters}
                        onChange={(e) => handleInputChange('supportingDocuments', 'demandLetters', e.target.value)}
                        placeholder="List "
                    ></textarea>
                </div> */}
                <div className="form-group mb-3">
                    <label>Collateral Asset Detail:</label>
                    <textarea
                        className="form-control"
                        value={formData.supportingDocuments.collateralAssetDetail || ""}
                        onChange={(e) => handleInputChange('supportingDocuments', 'collateralAssetDetail', e.target.value)}
                        placeholder="Enter the type of loan (e.g., Mortgage Property, Stocks, Shares)"
                    ></textarea>
                </div>

                <div className="form-group">
                    <label>Any Other Information:</label>
                    <textarea
                        className="form-control"
                        value={formData.supportingDocuments.anyOtherInfo}
                        onChange={(e) => handleInputChange('supportingDocuments', 'anyOtherInfo', e.target.value)}
                        placeholder="List if Available"
                    ></textarea>
                </div>

                {/* G. Legal Notices */}
                <h4 className="mt-4">7. Legal Notices</h4>
                <div className="form-group">
                    <label>Date of Legal Notice Sent:</label>
                    <input
                        type="date"
                        className="form-control"
                        value={formData.legalNotices.noticeDate}
                        onChange={(e) => handleInputChange('legalNotices', 'noticeDate', e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Defendant’s Response to Notice:</label>
                    <textarea
                        className="form-control"
                        value={formData.legalNotices.defendantResponse}
                        onChange={(e) => handleInputChange('legalNotices', 'defendantResponse', e.target.value)}
                        placeholder="Provide Details if Any"
                        disabled={fileUploaded}
                    ></textarea>
                    <div className="mt-2">
                        <label><strong>OR</strong> Upload Response (TXT or PDF):</label>
                        <input
                            type="file"
                            accept=".txt, .pdf"
                            onChange={handleFileUpload}
                            className="form-control-file"
                            disabled={textEntered}
                        />
                    </div>
                </div>
                {/* <div className="form-group">
                    <label>Defendant’s Response to Notice:</label>
                    <textarea
                        className="form-control"
                        value={formData.legalNotices.defendantResponse}
                        onChange={(e) => handleInputChange('legalNotices', 'defendantResponse', e.target.value)}
                        placeholder="Provide Details if Any"
                    ></textarea>
                </div> */}

                {/* H. Relief Sought */}
                <h4 className="mt-4">8. Relief Sought</h4>
                <div className="form-group">
                    <label>Total Principal Amount:</label>
                    <NumericFormat
                        className={`form-control ${errors.totalPrincipalAmount ? 'is-invalid' : ''}`}
                        value={formData.reliefSought.totalPrincipalAmount}
                        onValueChange={(values) => {
                            const { value } = values; // Raw numeric value
                            handleInputChange('reliefSought', 'totalPrincipalAmount', value);
                        }}
                        thousandSeparator=","
                        placeholder="Enter Amount"
                        isNumericString
                    />
                    {errors.totalPrincipalAmount && (
                        <div className="invalid-feedback">{errors.totalPrincipalAmount}</div>
                    )}
                </div>

                <div className="form-group">
                    <label>Interest Calculation:</label>
                    <NumericFormat
                        className="form-control"
                        value={formData.reliefSought.interestCalculation}
                        onValueChange={(values) => {
                            const { value } = values; // Raw numeric value
                            handleInputChange('reliefSought', 'interestCalculation', value);
                        }}
                        thousandSeparator=","
                        placeholder="Enter Interest Amount and Rate"
                        isNumericString
                    />
                </div>

                <div className="form-group">
                    <label>Legal Costs:</label>
                    <NumericFormat
                        className="form-control"
                        value={formData.reliefSought.legalCosts}
                        onValueChange={(values) => {
                            const { value } = values; // Raw numeric value
                            handleInputChange('reliefSought', 'legalCosts', value);
                        }}
                        thousandSeparator=","
                        placeholder="Enter Legal Cost + Lawyer Fee"
                        isNumericString
                    />
                </div>

                <div className="form-group">
                    <label>Panel Charges:</label>
                    <NumericFormat
                        className="form-control"
                        value={formData.reliefSought.panelCharges}
                        onValueChange={(values) => {
                            const { value } = values; // Raw numeric value
                            handleInputChange('reliefSought', 'panelCharges', value);
                        }}
                        thousandSeparator=","
                        placeholder="Enter Panel Charges"
                        isNumericString
                    />
                </div>

                <div className="form-group mb-3">
                    <label>Penalties:</label>
                    <textarea
                        className="form-control"
                        value={formData.reliefSought.penalties || ""}
                        onChange={(e) => handleInputChange('reliefSought', 'penalties', e.target.value)}
                        placeholder="Describe any penalties applicable"
                    ></textarea>
                </div>

                {/* I. Jurisdiction */}
                <h4 className="mt-4">9. Jurisdiction</h4>
                <div className="form-group">
                    <label>Pecuniary Jurisdiction:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.pecuniaryJurisdiction ? 'is-invalid' : ''}`}
                        value={formData.jurisdiction.pecuniaryJurisdiction}
                        onChange={(e) => handleInputChange('jurisdiction', 'pecuniaryJurisdiction', e.target.value)}
                        placeholder="Confirm Based on Claim Amount"
                    />
                    {errors.pecuniaryJurisdiction && <div className="invalid-feedback">{errors.pecuniaryJurisdiction}</div>}
                </div>
                <div className="form-group">
                    <label>Territorial Jurisdiction:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.territorialJurisdiction ? 'is-invalid' : ''}`}
                        value={formData.jurisdiction.territorialJurisdiction}
                        onChange={(e) => handleInputChange('jurisdiction', 'territorialJurisdiction', e.target.value)}
                        placeholder="Confirm Based on Defendant's Location and Cause of Action"
                    />
                    {errors.territorialJurisdiction && <div className="invalid-feedback">{errors.territorialJurisdiction}</div>}
                </div>

                {/* J. Witness Details (if applicable) */}
                {/* <h4 className="mt-4">10. Witness Details (if applicable)</h4>
                <div className="form-group">
                    <label>Witness 1 Name:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.witnesses.witness1Name}
                        onChange={(e) => handleInputChange('witnesses', 'witness1Name', e.target.value)}
                        placeholder="Enter Name"
                    />
                </div>
                <div className="form-group">
                    <label>Witness 2 Name:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.witnesses.witness2Name}
                        onChange={(e) => handleInputChange('witnesses', 'witness2Name', e.target.value)}
                        placeholder="Enter Name"
                    />
                </div> */}

                {/* K. Lawyer’s Details */}
                <h4 className="mt-4">10. Lawyer’s Details</h4>
                <div className="form-group">
                    <label>Plaintiff’s Advocate Name:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.advocateName ? 'is-invalid' : ''}`}
                        value={formData.lawyerDetails.advocateName}
                        onChange={(e) => handleInputChange('lawyerDetails', 'advocateName', e.target.value)}
                        placeholder="Enter Advocate's Full Name"
                    />
                    {errors.advocateName && <div className="invalid-feedback">{errors.advocateName}</div>}
                </div>
                <div className="form-group">
                    <label>License Number:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.licenseNumber ? 'is-invalid' : ''}`}
                        value={formData.lawyerDetails.licenseNumber}
                        onChange={(e) => handleInputChange('lawyerDetails', 'licenseNumber', e.target.value)}
                        placeholder="Enter Advocate's License Number"
                    />
                    {errors.licenseNumber && <div className="invalid-feedback">{errors.licenseNumber}</div>}
                </div>
                <div className="form-group">
                    <label>Law Firm Name (if applicable):</label>
                    <input
                        type="text"
                        className="form-control"
                        value={formData.lawyerDetails.lawFirmName}
                        onChange={(e) => handleInputChange('lawyerDetails', 'lawFirmName', e.target.value)}
                        placeholder="Enter Law Firm Name"
                    />
                </div>
                <div className="form-group">
                    <label>Advocate Contact Information:</label>
                    <input
                        type="text"
                        className={`form-control ${errors.advocateContactInformation ? 'is-invalid' : ''}`}
                        value={formData.lawyerDetails.contactInformation}
                        onChange={(e) => handleInputChange('lawyerDetails', 'contactInformation', e.target.value)}
                        placeholder="Enter Email and Phone"
                    />
                    {errors.advocateContactInformation && <div className="invalid-feedback">{errors.advocateContactInformation}</div>}
                </div>

                {/* L. Prayer */}
                <h4 className="mt-4">11. Prayer</h4>
                <div className="form-group">
                    <label>Prayer for Decree:</label>
                    <textarea
                        className={`form-control ${errors.prayerForDecree ? 'is-invalid' : ''}`}
                        value={formData.prayer.prayerForDecree}
                        onChange={(e) => handleInputChange('prayer', 'prayerForDecree', e.target.value)}
                        placeholder="Enter Formal Prayer"
                    ></textarea>
                    {errors.prayerForDecree && <div className="invalid-feedback">{errors.prayerForDecree}</div>}
                </div>
                <div className="form-group">
                    <label>Additional Relief:</label>
                    <textarea
                        className="form-control"
                        value={formData.prayer.additionalRelief}
                        onChange={(e) => handleInputChange('prayer', 'additionalRelief', e.target.value)}
                        placeholder="Specify Any Other Relief Sought"
                    ></textarea>
                </div>

                {/* Submit */}
                {/* <button type="submit" className="btn btn-primary mt-4">Next</button> */}
                <button
                    type="submit"
                    className="btn mt-4"
                    style={{
                        backgroundColor: '#166432',
                        color: '#C59D55',
                        border: 'none',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.backgroundColor = '#092415';
                        e.target.style.color = '#fff';
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.backgroundColor = '#166432';
                        e.target.style.color = '#C59D55';
                    }}
                >
                    Next
                </button>
            </form>
        </div>
    );
}

export default RecoverySuitForm;
